import { css } from 'styled-components';

const fontUrl = 'https://media.tones.be/static/fonts';

export const tonesFontVariables = {
  fontFamily: {
    primary: '"Open Sans", sans-serif',
    secondary: '"Open Sans", sans-serif',
  },
};

export default css`
  // PT Sans
  @font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url(${fontUrl}/OpenSans-ExtraBold-webfont.woff2) format('woff2'),
      url(${fontUrl}/OpenSans-ExtraBold-webfont.woff) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    src: url(${fontUrl}/OpenSans-ExtraBoldItalic-webfont.woff2) format('woff2'),
      url(${fontUrl}/OpenSans-ExtraBoldItalic-webfont.woff) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url(${fontUrl}/OpenSans-Bold-webfont.woff2) format('woff2'),
      url(${fontUrl}/OpenSans-Bold-webfont.woff) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: url(${fontUrl}/OpenSans-BoldItalic-webfont.woff2) format('woff2'),
      url(${fontUrl}/OpenSans-BoldItalic-webfont.woff) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url(${fontUrl}/OpenSans-Semibold-webfont.woff2) format('woff2'),
      url(${fontUrl}/OpenSans-Semibold-webfont.woff) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: url(${fontUrl}/OpenSans-SemiboldItalic-webfont.woff2) format('woff2'),
      url(${fontUrl}/OpenSans-SemiboldItalic-webfont.woff) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url(${fontUrl}/OpenSans-Regular-webfont.woff2) format('woff2'),
      url(${fontUrl}/OpenSans-Regular-webfont.woff) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url(${fontUrl}/OpenSans-Italic-webfont.woff2) format('woff2'),
      url(${fontUrl}/OpenSans-Italic-webfont.woff) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url(${fontUrl}/OpenSans-Light-webfont.woff2) format('woff2'),
      url(${fontUrl}/OpenSans-Light-webfont.woff) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    src: url(${fontUrl}/OpenSans-LightItalic-webfont.woff2) format('woff2'),
      url(${fontUrl}/OpenSans-LightItalic-webfont.woff) format('woff');
  }
`;
