import { IconStyle } from './styling';

export const ICON_NAMES = {
  ANGLE_DOUBLE_RIGHT: 'angle-double-right',
  ANGLE_DOWN: 'angle-down',
  ANGLE_LEFT: 'angle-left',
  ANGLE_RIGHT: 'angle-right',
  ANGLE_UP: 'angle-up',
  APPLE: 'apple',
  ARROW_RIGHT: 'arrow-right',
  BAN: 'ban',
  BARS: 'bars',
  BICYCLE: 'bicycle',
  BOX_OPEN: 'box-open',
  BUG: 'bug',
  CALENDAR_ALT: 'calendar-alt',
  CART: 'shopping-cart',
  CART_PLUS: 'cart-plus',
  CHECK: 'check',
  CHECK_CIRCLE: 'check-circle',
  CHECK_CIRCLE_SOLID: 'check-circle-solid',
  CHECK_DOUBLE: 'check-double',
  CHEVRON: 'chevron',
  CIRCLE: 'circle',
  CIRCLE_NOTCH: 'circle-notch',
  CLICK_COLLECT: 'click-collect',
  CLOCK: 'clock',
  COMMENT_ALT_LINES: 'comment-alt-lines',
  CREDIT_CARD: 'credit-card',
  DATABASE: 'database',
  DOWNLOAD: 'download',
  EDIT: 'edit',
  ENVELOPE: 'envelope',
  EQUALS: 'equals',
  ERASER: 'eraser',
  EXCLAMATION_CIRCLE: 'exclamation-circle',
  EYE: 'eye',
  EYE_SLASH: 'eye-slash',
  FACEBOOK: 'facebook',
  FACEBOOK_F: 'facebook-f',
  FACEBOOK_MESSENGER: 'facebook-messenger',
  FILE_DOWNLOAD: 'file-download',
  FILE_INVOICE: 'file-invoice',
  FILTER: 'filter',
  GIFT: 'gift',
  HEART: 'heart',
  HEART_FULL: 'heart-full',
  HOME: 'home',
  HOME_LG: 'home-lg',
  INFO_CIRCLE: 'info-circle',
  LIST: 'list',
  LOCATION: 'location',
  LOCATION_ARROW: 'location-arrow',
  LOCATION_PIN_WITH_BOX: 'location-pin-with-box',
  LOGO: 'logo',
  MAP_MARKER_ALT: 'map-marker-alt',
  MENU_PRODUCT: 'menu-product',
  MINUS: 'minus',
  MINUS_CICLE: 'minus-cicle',
  MOBILE: 'mobile',
  NOT_EQUALS: 'not-equals',
  PHONE: 'phone',
  PHONE_ROTARY: 'phone-rotary',
  PIPE: 'pipe',
  PLAY_CIRCLE: 'play-circle',
  PLUS: 'plus',
  PLUS_CIRCLE: 'plus-circle',
  PRINT: 'print',
  QRCODE: 'qrcode',
  QUESTION_SQUARE: 'square-question',
  QUOTE_RIGHT: 'quote-right',
  RECEIPT: 'receipt',
  RECYCLE: 'recycle',
  REDO: 'redo',
  SEARCH: 'search',
  SHARE: 'share',
  SHIPPING_FAST: 'shipping-fast',
  SHOPPING_BAG: 'shopping-bag',
  SIGN_OUT: 'sign-out',
  SLIDERS_SIMPLE: 'sliders-simple',
  SLIDERS_V: 'sliders-v',
  SQUARE: 'square',
  SQUARE_CHECKED: 'square-checked',
  STAR: 'star',
  STAR_FULL: 'star-full',
  STORE: 'store',
  TELESCOPE: 'telescope',
  TIMES: 'times',
  TIMES_CIRCLE: 'times-circle',
  TOOLS: 'tools',
  TRASH: 'trash',
  TRUCK: 'truck',
  TRUCK_LOADING: 'truck-loading',
  TRUCK_THIN: 'truck-think',
  TWITTER: 'twitter',
  UPLOAD: 'upload',
  USER: 'user',
  USER_CIRCLE: 'user-circle',
  WASHER: 'washer',
  WHATSAPP: 'whatsapp',
  WRENCH: 'wrench',
  XMARK_CIRCLE_SOLID: 'xmark-circle-solid',
  YOUTUBE_PLAY: 'youtube-play',
} as const;
export type Icon = (typeof ICON_NAMES)[keyof typeof ICON_NAMES];

export const ICON_STYLE_DEFAULT = process.env.NEXT_PUBLIC_FONTAWESOME_STYLE as IconStyle;

export const ICON_STYLES = {
  BRAND: 'fab',
  DEFAULT: ICON_STYLE_DEFAULT,
  REGULAR: 'far',
  SOLID: 'fas',
} as const;
export type IconType = keyof typeof ICON_STYLES;
