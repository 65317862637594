// @ts-nocheck

/**
 * The Redux CMS module containing the related actions, action types and reducer.
 */

import { CMSContentPageModel } from 'types/CMS';
import config from '../../config';
import CMSHelper from '../../helpers/CMSHelper';

const { platform } = config;

/**
 * Action types
 */
export const GET_PAGE = `${platform}/cms/GET_PAGE`;
export const GET_PAGE_SUCCESS = `${platform}/cms/GET_PAGE_SUCCESS`;
export const GET_PAGE_FAIL = `${platform}/cms/GET_PAGE_FAIL`;
export const SET_PAGE = `${platform}/cms/SET_PAGE`;
export const GET_FOOTER = `${platform}/cms/GET_FOOTER`;
export const GET_FOOTER_SUCCESS = `${platform}/cms/GET_FOOTER_SUCCESS`;
export const GET_FOOTER_FAIL = `${platform}/cms/GET_FOOTER_FAIL`;
export const SET_CONTENTFUL_PAGE = `${platform}/cms/SET_CONTENTFUL_PAGE`;
export const SET_CONTENTFUL_QUALIFIER = `${platform}/cms/SET_CONTENTFUL_QUALIFIER`;

/**
 * Actions
 */

/**
 * Retrieve a cms page by name.
 *
 * @param {string} qualifier - The localize page qualifier.
 * @param {string} locale - The locale to fetch the page for.
 * @param {boolean=} isStatic - Flag to indicate if the CMS page qualifier is static
 * in the frontend.
 * @returns {{types: [*,*,*], promise: (function(*))}} The get cms page action.
 */
export function getCMSPage(qualifier, locale, isStatic?, updateQualifier = true) {
  let cmsPage;
  let mergedQualifier = qualifier;

  const localeString = `-${locale}`;

  if (isStatic) {
    mergedQualifier = `${qualifier}${localeString}`;
  } else if (!isStatic && mergedQualifier?.endsWith(localeString)) {
    const localeIndex = mergedQualifier?.lastIndexOf(localeString);
    cmsPage = mergedQualifier.substring(0, localeIndex);
  }

  if (!cmsPage) {
    cmsPage = mergedQualifier;
  }

  if (qualifier && qualifier.toLowerCase().includes('footer')) {
    return {
      footerQualifier: mergedQualifier,
      locale,
      promise: () => new CMSHelper().getCMSPage(cmsPage, locale),
      types: [GET_FOOTER, GET_FOOTER_SUCCESS, GET_FOOTER_FAIL],
    };
  }

  return {
    locale,
    promise: () => new CMSHelper().getCMSPage(cmsPage, locale),
    qualifier: mergedQualifier,
    types: [GET_PAGE, GET_PAGE_SUCCESS, GET_PAGE_FAIL],
    updateQualifier,
  };
}

/**
 * Sets the current page if the page was already loaded.
 *
 * @param {string} qualifier - The page qualifier to load.
 * @return {{type: string, qualifier: *}} The setCMSPage action.
 */
export function setCMSPage(qualifier) {
  return {
    qualifier,
    type: SET_PAGE,
  };
}

/**
 * Function which adds the contentful page date to the redux state
 * @param {object} contentfulPage - the contentful page object
 * @param {string} pageId - the contentful page id
 * @return {{type: string, contentfulPage: *}} the set contentful page action
 */
export function setContentfulPage(contentfulPage, pageId) {
  return {
    contentfulPage,
    pageId,
    type: SET_CONTENTFUL_PAGE,
  };
}

/**
 * Function which sets the contentful qualifier
 * @param {string} qualifier - the contentful qualifier
 * @return {{qualifier: *, type: string}} the set contentful qualifier action
 */
export function setContentfulQualifier(qualifier) {
  return {
    qualifier,
    type: SET_CONTENTFUL_QUALIFIER,
  };
}

/**
 * Retrieves the CMS page by qualifier from the current redux state.
 *
 * @param {object} state - The current redux state.
 * @param {string} qualifier - The CMS page qualifier.
 * @param {boolean} [isStatic=false] - Flag to indicate if the CMS page qualifier is statically assigned
 * in the frontend.
 * @param {string} [locale=''] - The locale.
 * @returns {object|undefined} The selected CMS page or undefined if it does not exist.
 */
export const getCmsPageFromState = (state, qualifier, isStatic?, locale?): CMSContentPageModel => {
  let mergedQualifier = qualifier;

  if (isStatic) {
    mergedQualifier = `${qualifier}-${locale}`;
  }

  return state.cms.pages[mergedQualifier];
};

/**
 * Reducer
 */
const initialState = {
  contentfulPage: {},
  contentfulProducts: {},
  footerQualifier: null,
  keepOldEntries: false,
  pages: {},
  qualifier: null,
};

/**
 * The cms reducer.
 *
 * @param {Object} state The default or current state.
 * @param {Object} action The dispatched action.
 * @returns {Object} The updated state.
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PAGE: {
      const qualifier = action?.updateQualifier ? action.qualifier : state.qualifier;

      return {
        ...state,
        qualifier,
      };
    }
    case GET_PAGE_SUCCESS: {
      const updatedPages = {
        ...state.pages,
      };
      updatedPages[action.qualifier] = action.result;

      const qualifier = action?.updateQualifier ? action.qualifier : state.qualifier;

      return {
        ...state,
        pages: updatedPages,
        qualifier,
      };
    }
    case SET_PAGE:
      return {
        ...state,
        qualifier: action.qualifier,
      };
    case GET_FOOTER:
      return {
        ...state,
        footerQualifier: action.footerQualifier,
      };
    case GET_FOOTER_SUCCESS: {
      const updatedPages = {
        ...state.pages,
      };
      updatedPages[action.footerQualifier] = action.result;

      return {
        ...state,
        footerQualifier: action.footerQualifier,
        pages: updatedPages,
      };
    }
    case SET_CONTENTFUL_PAGE: {
      return {
        ...state,
        contentfulPage: action.contentfulPage || state.contentfulPage,
        qualifier: action.pageId,
      };
    }

    case SET_CONTENTFUL_QUALIFIER: {
      return {
        ...state,
        qualifier: action?.qualifier,
      };
    }
    default:
      return state;
  }
}
