import { apiClient } from 'helpers/ApiClient';
import config from '../../config';

const { platform } = config;

export const GET_PRODUCT_COMPARE_GROUP = `${platform}/compare/GET_PRODUCT_COMPARE_GROUP`;
export const GET_PRODUCT_COMPARE_GROUP_SUCCESS = `${platform}/compare/GET_PRODUCT_COMPARE_GROUP_SUCCESS`;
export const GET_PRODUCT_COMPARE_GROUP_FAIL = `${platform}/compare/GET_PRODUCT_COMPARE_GROUP_FAIL`;
export const GET_COMPARE_PRODUCTS = `${platform}/compare/GET_COMPARE_PRODUCTS`;
export const GET_COMPARE_PRODUCTS_SUCCESS = `${platform}/compare/GET_COMPARE_PRODUCTS_SUCCESS`;
export const GET_COMPARE_PRODUCTS_FAIL = `${platform}/compare/GET_COMPARE_PRODUCTS_FAIL`;
export const UPDATE_PRODUCT_COMPARE = `${platform}/compare/UPDATE_PRODUCT_COMPARE`;
export const GET_COMPARE_DETAILS = `${platform}/compare/GET_COMPARE_DETAILS`;
export const GET_COMPARE_DETAILS_SUCCESS = `${platform}/compare/GET_COMPARE_DETAILS_SUCCESS`;
export const GET_COMPARE_DETAILS_FAIL = `${platform}/compare/GET_COMPARE_DETAILS_FAIL`;
export const SHOW_COMPARE_MENU = `${platform}/compare/SHOW_COMPARE_MENU`;
export const SET_CURRENT_COMPARE_LOCATION = `${platform}/compare/SET_CURRENT_COMPARE_LOCATION`;
export const TOGGLE_REMOVE_OVERLAY = `${platform}/compare/TOGGLE_REMOVE_OVERLAY`;

/**
 * Function which saves the current product compare object to the state
 * @param {array} comparedProductCodes - the products currently in compare
 * @param {unknown} comparedCategory - the currently compared category
 * @param {boolean} showCompareMenu - should we show the compare menu
 * @param {boolean} isOverLimit - are we over the limit of 4 products in compare
 * @return {{types: [*], promise: (function(*))}} the get compare details action
 */
export const updateProductCompare = (
  comparedProductCodes,
  comparedCategory,
  showCompareMenu = false,
  isOverLimit = false,
  extraProduct
) => ({
  comparedCategory,
  comparedProductCodes,
  extraProduct,
  isOverLimit,
  showCompareMenu,
  type: UPDATE_PRODUCT_COMPARE,
});

export const toggleRemoveProductOverlay = (isVisible) => ({
  isVisible,
  type: TOGGLE_REMOVE_OVERLAY,
});

/**
 * Function which gets the compare details object for the given products
 * @param {string[]} products - An array of compared products strings
 * @param {string} locale - The locale
 * @return {{types: [*,*,*], promise: (function(*))}} the get compare details action
 */
export const getCompareDetails = (products, locale) => ({
  promise: () =>
    apiClient({
      params: {
        fields: 'FULL',
        lang: locale,
        productCodes: products
      },
      url: `/compare/products`,
    }),
  types: [
    GET_COMPARE_DETAILS,
    GET_COMPARE_DETAILS_SUCCESS,
    GET_COMPARE_DETAILS_FAIL,
  ],
});

/**
 * Function which retrieves the compare products for active compare cookie
 * @param {Array} products - the products array
 * @return {function(*, *, *): (*|undefined)} The get compare products action
 */
export const getComparedProducts = (products) => (dispatch) => {
  if (products && Array.isArray(products)) {
    const queryParams = [];
    products.forEach((product) => {
      queryParams.push(`productCode=${product}`);
    });

    if (queryParams.length > 0) {
      return dispatch({
        promise: () => apiClient({ url: `/products/list?${queryParams.join('&')}` }),
        types: [
          GET_COMPARE_PRODUCTS,
          GET_COMPARE_PRODUCTS_SUCCESS,
          GET_COMPARE_PRODUCTS_FAIL,
        ],
      });
    }
  }
  return dispatch({
    promise: () => Promise.resolve(),
    types: [
      GET_COMPARE_PRODUCTS,
      GET_COMPARE_PRODUCTS_SUCCESS,
      GET_COMPARE_PRODUCTS_FAIL,
    ],
  });
};

/**
 * Function which retrieves the product compare group by code
 * @param {string} code - The compare group code
 * @return {{types: [string, string, string], promise: (function(*): *)}} -
 * The get product compare group action
 */
export const getProductCompareGroup = (code) => ({
  promise: () =>
    apiClient({
      params: {
        code,
        fields: 'FULL',
      },
      url: `/compare/group`,
    }),
  types: [
    GET_PRODUCT_COMPARE_GROUP,
    GET_PRODUCT_COMPARE_GROUP_SUCCESS,
    GET_PRODUCT_COMPARE_GROUP_FAIL,
  ],
});

/**
 * Function which shows/hides the compare menu
 * @param {boolean} isVisible - the isVisible flag
 * @returns {{type: string, isVisible: boolean}} the show compare menu action
 */
export const setCompareMenuVisibility = (isVisible = true) => ({
  isVisible,
  type: SHOW_COMPARE_MENU,
});

/**
 * Function which sets the current compare location
 * @param {string} pathName - the path name
 * @param {string} searchQuery - the search query
 * @returns {{type: *, pathName: *}} the set current compare location action
 */
export const setCurrentCompareLocation = (pathName, searchQuery) => ({
  pathName,
  searchQuery,
  type: SET_CURRENT_COMPARE_LOCATION,
});

const initialState = {
  compareOverview: null,
  comparedCategory: null,
  comparedProductCodes: [],
  extraProduct: null,
  isLoadingCompare: false,
  showCompareMenu: false,
  showRemoveItemOverlay: false,
};

/**
 * The product reducer.
 *
 * @param {Object} state The default or current state.
 * @param {Object} action The dispatched action.
 * @returns {Object} The updated state.
 */
const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_PRODUCT_COMPARE: {
      const {
        comparedCategory,
        comparedProductCodes,
        extraProduct,
        isOverLimit,
        showCompareMenu,
      } = action;

      return {
        ...state,
        comparedCategory,
        comparedProductCodes,
        extraProduct,
        showCompareMenu,
        showRemoveItemOverlay: isOverLimit,
      };
    }
    case SHOW_COMPARE_MENU: {
      return {
        ...state,
        showCompareMenu: action.isVisible,
      };
    }
    case TOGGLE_REMOVE_OVERLAY: {
      return {
        ...state,
        showRemoveItemOverlay: action.isVisible,
      };
    }
    case GET_COMPARE_DETAILS: {
      return {
        ...state,
        isLoadingCompare: true,
      };
    }
    case GET_COMPARE_DETAILS_SUCCESS:
      return {
        ...state,
        compareOverview:
          Object.keys(action.result.data).length > 0 &&
            action.result.data.constructor === Object
            ? action.result.data
            : null,
        isLoadingCompare: false,
        showCompareMenu: false,
      };
    case GET_COMPARE_DETAILS_FAIL: {
      return {
        ...state,
        isLoadingCompare: false,
      };
    }

    case SET_CURRENT_COMPARE_LOCATION: {
      return {
        ...state,
        prevCompareLocation: action.pathName,
        prevCompareQuery: action.searchQuery,
      };
    }
    case GET_PRODUCT_COMPARE_GROUP_SUCCESS: {
      return {
        ...state,
        compareGroup: action && action.result && action.result.data,
      };
    }
    case GET_COMPARE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        compareProducts: action?.result?.data?.products,
      };
    }
    default:
      return state;
  }
};

export default reducer;
