/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable typescript-sort-keys/interface */
/* eslint-disable sort-keys-fix/sort-keys-fix */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Notification } from 'types/Notification';

interface NotificationState {
  notifications: Notification[];
}

export interface NotificationStore extends NotificationState {
  actions: {
    queueNotification: (notification: Notification) => void;
    removeNotification: (uuid: string) => void;
    clearNotifications: () => void;
  };
}

const initialState: NotificationState = {
  notifications: [],
};

// TODO: Implement max notifications

export const useNotificationStore = create<NotificationStore>()(
  devtools(
    (set) => ({
      ...initialState,
      actions: {
        queueNotification: (notification: Notification) =>
          set((state) => ({ notifications: [...state.notifications, notification] })),
        removeNotification: (uuid: string) =>
          set((state) => ({ notifications: state.notifications.filter((notification) => notification.uuid !== uuid) })),
        clearNotifications: () => set(() => initialState),
      },
    }),
    { name: 'notificationStore' }
  )
);

export const useNotifications = () => useNotificationStore((state) => state.notifications);
export const useNotificationActions = () => useNotificationStore((state) => state.actions);
