/* eslint-disable sort-keys-fix/sort-keys-fix */
/*
 * Philosophy behind values/names
 * - Values are sorted from low to high
 * - HEADER is used as a basis for almost all values as all items should start with the thought of "should it be in front or behind the header"
 * - BACKDROP is default 100 higher than header and should be overwritten with BACKDROP_HEADER if it needs to be behind the header.
 *
 * Try and avoid creating new variables when necessary. With a few generic values, we should be able to cover all use-cases
 */

export const Z_INDICES = {
  NEGATIVE: -1,
  BASE: 1,
  BASE_OVERWRITE: () => Z_INDICES.BASE + 10,
  HEADER_UNDERWRITE: () => Z_INDICES.BACKDROP_HEADER() - 100,
  BACKDROP_HEADER: () => Z_INDICES.HEADER - 100,
  BACKDROP_HEADER_OVERWRITE: () => Z_INDICES.BACKDROP_HEADER() + 10,
  HEADER: 700, // temporarily lowered to 700 until the bootstrap modal issue is resolved
  HEADER_OVERWRITE: () => Z_INDICES.HEADER + 100,
  BACKDROP: () => Z_INDICES.HEADER_OVERWRITE() + 100,
  MODAL: () => Z_INDICES.BACKDROP() + 100,
  MODAL_OVERWRITE: () => Z_INDICES.MODAL() + 100,
};

const indices = {
  index: {
    negative: Z_INDICES.NEGATIVE,
    base: Z_INDICES.BASE,
    'base-overwrite': Z_INDICES.BASE_OVERWRITE,
    'header-underwrite': Z_INDICES.HEADER_UNDERWRITE,
    'backdrop-header': Z_INDICES.BACKDROP_HEADER,
    'backdrop-header-overwrite': Z_INDICES.BACKDROP_HEADER_OVERWRITE,
    header: Z_INDICES.HEADER,
    'header-overwrite': Z_INDICES.HEADER_OVERWRITE,
    backdrop: Z_INDICES.BACKDROP,
    modal: Z_INDICES.MODAL,
    'modal-overwrite': Z_INDICES.MODAL_OVERWRITE,
  },
};

export default indices;
