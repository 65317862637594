import { Margin } from 'constants/styling';
import { css } from 'styled-components';
import { spacer } from 'theme';
import { isAuto, isNumericValue } from 'utils/textUtil';

export const spacingValidation = (prop?: Margin) => {
  if (prop === undefined) return null;
  if (isAuto(prop + '')) return prop;

  if (isNumericValue(prop)) {
    if (prop === 0) {
      return '0px';
    }

    return spacer(prop);
  }

  return null;
};

export interface MarginProps {
  margin?: Margin;
  marginBottom?: Margin;
  marginLeft?: Margin;
  marginRight?: Margin;
  marginTop?: Margin;
}

export const marginMapper = css<MarginProps>`
  margin: ${({ margin }) => spacingValidation(margin)};
  margin-bottom: ${({ marginBottom }) => spacingValidation(marginBottom)};
  margin-left: ${({ marginLeft }) => spacingValidation(marginLeft)};
  margin-right: ${({ marginRight }) => spacingValidation(marginRight)};
  margin-top: ${({ marginTop }) => spacingValidation(marginTop)};
`;
