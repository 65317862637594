import { brandSelect } from '../../functions';
import { krefelColors } from '../colors/krefelColors';
import { sharedColors } from '../colors/sharedColor';

// Does not use color accessor since used in global.js which cannot
// Read the accessor theme functions
const krefel = {
  color: krefelColors['primary-400'],
  'hover-color': krefelColors['primary-300'],
  'color--rich-text': krefelColors['primary-300'],
  'hover-color--rich-text': krefelColors['primary-400'],
};

const hifi = {
  color: sharedColors['whisper-300'],
  'hover-color': sharedColors['whisper-400'],
};

const tones = {
  color: sharedColors['pearl-400'],
  'hover-color': sharedColors['pearl-500'],
};

const brands = {
  krefel,
  hifi,
  tones,
};

const linkSpecifics = {
  ...brandSelect(brands),
};

export default linkSpecifics;
