import { brandSelect } from '../../functions';
import hifiFontsFaces, { hifiFontVariables } from './hifiFonts';
import krefelFontsFaces, { krefelFontVariables } from './krefelFonts';
import tonesFontsFaces, { tonesFontVariables } from './tonesFonts';

export const fonts = brandSelect({
  hifi: hifiFontVariables,
  krefel: krefelFontVariables,
  tones: tonesFontVariables,
});

export default brandSelect({
  hifi: hifiFontsFaces,
  krefel: krefelFontsFaces,
  tones: tonesFontsFaces,
});
