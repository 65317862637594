/* eslint-disable sort-keys-fix/sort-keys-fix */
import { pathnames } from '../i18n/pathnames';

export const PAGE_TYPES = {
  SITE: 'all',
  CART: 'cart',
  CHECKOUT: 'checkoutLogin, deliveryForm, payment, orderOverview, checkoutConfirmation',
  CONTACT: 'contact',
  PDP: 'productDetail',
  PLP: 'search, categoryL3, categoryL4, brandProductPage, brandCategoryPage',
  CMS_SEARCH: 'CMSSearchPageModel',
};

export const STATIC_CMS_PAGES = [
  pathnames.PRICE_GUARANTEE,
  pathnames.FAQ,
  pathnames.SERVICE,
  pathnames.CASHBACKS,
  pathnames.WARRANTY,
  pathnames.ABOUT_US,
];

export const PAGE_LAYOUTS = {
  DEFAULT_LAYOUT: 'defaultLayout',
  CHECKOUT_LAYOUT: 'checkoutLayout',
};

export type PageLayoutType = (typeof PAGE_LAYOUTS)[keyof typeof PAGE_LAYOUTS];

export type PageTypeKey = keyof typeof PAGE_TYPES;
