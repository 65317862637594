import type {
  FetchProductClassificationsParams,
  FetchProductDeliveryModesParams,
  FetchProductParams,
  FetchProductReferencesParams,
  FetchProductsByQueryParams,
  FetchProductsParams,
  FetchProductVariantsParams,
  FetchRealtimeInfoParams,
  FetchRecentlyViewedProductsParams,
  FetchReviewsParams,
} from './connector';

export const productKeys = {
  classifications: (params: FetchProductClassificationsParams) => ['productClassifications', params],
  deliveryModes: (params: FetchProductDeliveryModesParams) => ['deliveryModes', params],
  detail: (params: FetchProductParams) => ['product', params],
  monthlyInstalment: (price: number, numberOfMonths: number) => ['monthlyInstalment', price, numberOfMonths],
  products: (params: FetchProductsParams) => ['products', params],
  query: (params: FetchProductsByQueryParams) => ['productQuery', params],
  realtimeInfo: (params: FetchRealtimeInfoParams) => ['productRealtimeInfo', params],
  recentlyViewedProducts: (params: FetchRecentlyViewedProductsParams) => ['recentlyViewedProducts', params],
  references: (params: FetchProductReferencesParams) => ['references', params],
  reviews: (params: FetchReviewsParams) => ['reviews', params],
  userCanWriteReview: (productCode?: string, anonymous?: boolean) => ['userCanWriteReview', productCode, anonymous],
  variants: (params: FetchProductVariantsParams) => ['variants', params],
};
