import { Raleway, Roboto } from 'next/font/google';

export const primary = Roboto({
  style: ['normal'],
  subsets: ['latin'],
  variable: '--font-primary',
  weight: ['400', '500', '700'],
});

export const secondary = Raleway({
  style: ['normal'],
  subsets: ['latin'],
  variable: '--font-secondary',
  weight: ['600'],
});
