import { css } from 'styled-components';
import { brandSelect, color } from '../../functions';

const defaultValues = {
  'active-brand-facet': css`
    ${color('white')};
  `,
};

const krefel = {};

const hifi = {};

const tones = {
  'active-brand-facet': css`
    ${color('whisper-400')};
  `,
};

const brands = {
  hifi,
  krefel,
  tones,
};

const brandSpecifics = {
  ...defaultValues,
  ...brandSelect(brands),
};

export default brandSpecifics;
