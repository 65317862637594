import { css } from 'styled-components';

const fontUrl = 'https://media.krefel.be/static/fonts';

export const krefelFontVariables = {
  fontFamily: {
    primary: '"Roboto", helvetica',
    secondary: '"Raleway", helvetica',
  },
};

export default css`
  @font-face {
    font-display: swap;
    font-family: 'Roboto';
    src: url(${fontUrl}/Roboto-Bold.woff2) format('woff2'), url(${fontUrl}/Roboto-Bold.woff) format('woff');
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-display: swap;
    font-family: 'Roboto';
    src: url(${fontUrl}/Roboto-Medium.woff2) format('woff2'), url(${fontUrl}/Roboto-Medium.woff) format('woff');
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-display: swap;
    font-family: 'Roboto';
    src: url(${fontUrl}/Roboto-Regular.woff2) format('woff2'), url(${fontUrl}/Roboto-Regular.woff) format('woff');
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-display: swap;
    font-family: 'Roboto';
    src: url(${fontUrl}/Roboto-Italic.woff2) format('woff2'), url(${fontUrl}/Roboto-Italic.woff) format('woff');
    font-style: italic;
    font-weight: 400;
  }

  @font-face {
    font-display: swap;
    font-family: 'Raleway';
    src: url(${fontUrl}/Raleway-Bold.woff2) format('woff2'), url(${fontUrl}/Raleway-Bold.woff) format('woff');
    font-style: bold;
    font-weight: 700;
  }

  @font-face {
    font-display: swap;
    font-family: 'Raleway';
    src: url(${fontUrl}/Raleway-Regular.woff2) format('woff2'), url(${fontUrl}/Raleway-Regular.woff) format('woff');
    font-style: normal;
    font-weight: 400;
  }
`;
