import { useAuthStore } from 'store/auth';
import config from '../config';
import { ANALYTIC_EVENTS } from '../constants/analytics';
import { addProductToCart, removeProduct } from '../redux/modules/cart';
import { getSwogoProductByCode } from '../redux/modules/product';
import { createProductInfoObject } from '../utils/analyticsProductDataUtil';
import { log } from '../utils/loggerUtil';

class SwogoHelper {
  /**
   * The SwogoHelper constructor
   * @param {object} store - the redux store object
   * @param {object} cookies - the cookies
   * @constructor
   */
  constructor(store, cookies) {
    this.store = store;
    this.cookies = cookies;
  }
  /**
   * Function which returns the product promise for a given product code
   * @param {string} productCode - the product code
   * @returns {Promise} the product promise
   */
  getProductById = (productCode) => {
    const { dispatch } = this.store;
    return new Promise((resolve, reject) => {
      dispatch(getSwogoProductByCode(productCode))
        .then((result) => resolve(result.data))
        .catch((error) => reject(error));
    });
  };
  /**
   * Function which adds a product to the cart
   * @param {Array} productCodes - the product codes array
   * @returns {undefined}
   */
  addProductsToCart = (productCodes = []) => {
    const products = productCodes;
    if (productCodes && productCodes.length > 0) {
      this.addProductToCart(productCodes[0])
        .then(() => {
          products.shift();
          this.addProductsToCart(products);
        })
        .catch((err) => {
          log(
            'CART',
            `Product with code ${productCodes[0]} is not added to cart`,
            err
          );
        });
    }
  };
  /**
   * Function which adds a product to the cart
   * @param {string} productCode - the product code
   * @return {Promise} The add product to cart promise
   */
  addProductToCart = (productCode) => {
    const { dispatch } = this.store;
    const {
      cart,
    } = this.store.getState();
    const anonymous = useAuthStore.getState().anonymous;
    return new Promise((resolve, reject) => {
      dispatch(getSwogoProductByCode(productCode))
        .then((result) => {
          const product = result.data;
          dispatch(addProductToCart(cart, product, anonymous))
            .then((res) => {
              this.pushCartAnalyticsEvent(product);
              resolve(res);
            })
            .catch((err) => {
              log(
                'CART',
                `Product with code ${productCode} is not added to cart`,
                err
              );
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
          log('CART', `Product with code ${productCode} is not found`, err);
        });
    });
  };
  /**
   * Function which removes a product from the cart
   * @param {string} productCode - the product code
   * @returns {undefined}
   */
  removeProductFromCart = (productCode) => {
    const { dispatch } = this.store;
    const {
      cart: { cart },
    } = this.store.getState();
    const anonymous = useAuthStore.getState().anonymous;
    let product;
    if (productCode && cart && cart.entries && cart.entries.length > 0) {
      cart.entries.forEach((entry) => {
        if (entry && entry.product && entry.product.code === productCode) {
          product = entry.product;
        }
      });
    }
    if (product) {
      dispatch(
        removeProduct(product, anonymous, this.cookies.get(config.cart.key))
      )
        .then(() => {
          this.pushCartAnalyticsEvent(product, false);
        })
        .catch((err) => {
          log(
            'CART',
            `Product with code ${productCode} is not deleted from cart`,
            err
          );
        });
    } else {
      log(
        'CART',
        `Product with code ${productCode} is not found on current cart`
      );
    }
  };
  /**
   * Private function which pushes the add/remove product to/from cart event
   * @param {object} product - the current product
   * @param {boolean} isAddToCartEvent - the is add to cart event flag
   * @returns {undefined}
   * @private
   */
  pushCartAnalyticsEvent = (product, isAddToCartEvent = true) => {
    const analyticsProduct = createProductInfoObject(
      product?.name,
      product?.code,
      product?.price,
      product?.brand,
      product?.datalayerCategoryPath,
      product?.atp,
      product?.carrier,
      undefined,
      undefined,
      false
    );
    const gtmData = {
      ecommerce: {
        add: {
          products: [analyticsProduct] || [],
        },
        currencyCode: 'EUR',
      },
      event: isAddToCartEvent
        ? ANALYTIC_EVENTS.ADD_TO_CART
        : ANALYTIC_EVENTS.REMOVE_FROM_CART,
    };
    global?.dataLayer?.push(gtmData);
  };
}
export default SwogoHelper;
