import { css } from 'styled-components';
import { brandSelect, color, specific } from '../../functions';

const defaultValues = {
  'banner-left-color': css`
    ${color('black')};
  `,
  'masonry-tile-foreground-color': specific('generic', 'body-color'),
};

const krefel = {
  'banner-left-background': css`
    ${color('whisper-100')};
  `,
  'masonry-button-color': css`
    ${color('secondary-400')}
  `,
  'masonry-tile-background-color': css`
    ${color('white')}
  `,
};

const hifi = {
  'banner-left-background': css`
    ${color('secondary-300')};
  `,
  'masonry-button-color': css`
    ${color('primary-300')}
  `,
  'masonry-tile-background-color': css`
    ${color('white')}
  `,
  'masonry-tile-foreground-color': css`
    ${color('secondary-300')}
  `,
};

const tones = {
  'banner-left-background': css`
    ${color('primary-100')};
  `,
  'masonry-button-color': css`
    ${color('secondary-300')}
  `,
};

const brandSpecifics = {
  hifi,
  krefel,
  tones,
};

const buttonSpecifics = {
  ...defaultValues,
  ...brandSelect(brandSpecifics),
};

export default buttonSpecifics;
