import createThemeMap from '../../../utils/themeUtil';

export const tonesColors = createThemeMap({
  primary: {
    100: '#fef5cd',
    200: '#feec9a',
    300: '#fdce07',
    400: '#caa502',

    transparent: {
      100: 'rgba(253, 206, 7, 0.25)',
      200: 'rgba(254, 236, 154, 0.1)',
      300: 'rgba(253, 206, 7, 0.5)',
    },
  },
  secondary: {
    200: '#9fb4c6',
    300: '#809cb3',
    400: '#4d6980',

    transparent: {
      300: 'rgba(128, 156, 179, 0.5)',
      300.1: 'rgba(128, 156, 179, 0.1)',
    },
  },
});
