import { path, pipe } from 'ramda';
import { px } from './helpers';

export const theme =
  (keys = []) =>
  (props) =>
    path(['theme', ...keys], props);

export const color = (key) => theme([key]);

export const variable = (key) => theme(['variable', key]);

export const spacer = (key) => theme(['spacer', key]);

export const fontSize = (key) => theme(['fontSize', key]);

export const fontFamily = (key) => theme(['fontFamily', key]);

export const zIndex = (key) => theme(['index', key]);

export const specific = (key, value) => theme([key, value]);

export const brandSelect = (object) =>
  object[process.env.NEXT_PUBLIC_PLATFORM] || 'krefel';

export const breakpoint = (key) => pipe(theme([key]), px);
