import { GET_PRODUCT_BY_CODE_SUCCESS } from './product';
import { GET_PAGE_SUCCESS } from './cms';

import config from '../../config';

const { platform } = config;

export const RESET_LOCALIZATION_URLS = `${platform}/localization/RESET_LOCALIZATION_URLS`;
export const SET_LOCALIZED_URLS = `${platform}/localization/SET_LOCALIZED_URLS`;

export const resetLocalization = () => ({
  type: RESET_LOCALIZATION_URLS,
});

export const setLocalizedUrls = (localizedUrls) => ({
  localizedUrls,
  type: SET_LOCALIZED_URLS,
});

const initialState = {
  localizedUrls: null,
};

/**
 * The localization reducer.
 *
 * @param {Object} state The default or current state.
 * @param {Object} action The dispatched action.
 * @returns {Object} The updated state.
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_LOCALIZATION_URLS: {
      return {
        ...state,
        localizedUrls: null,
      };
    }

    case GET_PRODUCT_BY_CODE_SUCCESS: {
      return {
        ...state,
        localizedUrls: action?.result?.data?.localizedUrls?.entry || null,
      };
    }
    case GET_PAGE_SUCCESS: {
      // Since most pages have CMS data, they might all return some sort of localizedUrls that can be empty,
      // so we explicitly check if it's not a contentPageModel.
      const isNonContentPage = ['ContentPageModel', "CMSEmptyContentPageModel"].includes(action?.result?.type);

      return {
        ...state,
        localizedUrls: isNonContentPage
          ? state?.localizedUrls
          : action?.result?.localizedUrls?.entry || state?.localizedUrls || null,
      };
    }
    case SET_LOCALIZED_URLS: {
      return {
        ...state,
        localizedUrls: action?.localizedUrls,
      };
    }

    default:
      return state;
  }
}
