import { PRODUCT_PARTNERS } from 'constants/product';
import { Dispatch } from 'redux';
import { Cart } from 'types/Cart';
import { getPartnerDeliveryMoments, setPartnerDeliveryDate, submitPartnerDeliveryDate } from '../redux/modules/partner';
import { CheckDateAvailable } from './dateUtil';
import { getActivePartners } from './orderUtil';

export const onPartnerDateSelect = (
  deliveryDate: Date | string,
  partner: string,
  cart: Cart,
  dispatch: Dispatch,
  anonymous: boolean,
) => {
  // @ts-ignore - Dispatch ts issue
  dispatch(setPartnerDeliveryDate(deliveryDate, partner));
  // @ts-ignore - Dispatch ts issue
  dispatch(submitPartnerDeliveryDate(partner, deliveryDate, cart.guid, anonymous));
};

const onPartnerDateReload = (
  cart: Cart,
  deliveryMoments: string[] = [],
  partner: string,
  dispatch: Dispatch,
  anonymous: boolean,
) => {
  if (!!cart?.partnerDeliveryDates?.length && !!deliveryMoments?.length) {
    cart.partnerDeliveryDates.forEach((partnerDelivery) => {
      if (partnerDelivery?.key === partner) {
        const isAvailable = CheckDateAvailable({
          availableDates: deliveryMoments,
          selectedDate: partnerDelivery.value,
        });
        onPartnerDateSelect(
          isAvailable ? partnerDelivery.value : deliveryMoments[0],
          partner,
          cart,
          dispatch,
          anonymous,
        );
      } else {
        onPartnerDateSelect(deliveryMoments[0], partner, cart, dispatch, anonymous);
      }
    });
  } else if (deliveryMoments && deliveryMoments.length > 0) {
    onPartnerDateSelect(deliveryMoments[0], partner, cart, dispatch, anonymous);
  }
};

export const loadPartnerDeliveryDates = async (
  cart: Cart,
  anonymous: boolean,
  dispatch: Dispatch,
  initalize: boolean = true,
) => {
  const partners = cart?.entries?.length ? getActivePartners(cart.entries) : [];

  if (partners?.length) {
    partners.forEach((partner) => {
      // @ts-ignore - Dispatch ts issue
      dispatch(getPartnerDeliveryMoments(partner, cart.guid, anonymous)).then(
        ({ data: { partnerDeliveryDates } }: { data: { partnerDeliveryDates: string[] } }) => {
          if (partnerDeliveryDates?.length > 0 && initalize) {
            onPartnerDateReload(cart, partnerDeliveryDates, partner, dispatch, anonymous);
          }
        },
      );
    });
  }
};

export const isDefaultPartner = (partnerCode: string) =>
  [PRODUCT_PARTNERS.TONES, PRODUCT_PARTNERS.KREFEL, PRODUCT_PARTNERS.HIFI].includes(partnerCode);
