import { brandSelect, color } from '../../functions';

const defaultValues = {
  'background-color': color('primary-300'),
  'text-color': color('whisper-100'),
  'border-width': '1px',
  'border-color': color('whisper-200'),
  'delivery-color': color('white'),
  height: '34px',
  'text-hover-color': color('white'),
};

const krefel = {
  'background-color': color('whisper-100'),
  'text-color': color('primary-400'),
  'delivery-color': color('primary-400'),
};

const hifi = {
  'border-color': color('primary-300'),
};

const tones = {
  'background-color': color('white'),
  'border-color': color('white'),
  'text-color': color('secondary-400'),
  'delivery-color': color('secondary-400'),
  'text-hover-color': color('secondary-400'),
};

const brands = {
  krefel,
  hifi,
  tones,
};

const topbarSpecifics = {
  ...defaultValues,
  ...brandSelect(brands),
};

export default topbarSpecifics;
