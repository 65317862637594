/**
 * The Redux category module containing the facet related actions, action types and reducer.
 */

import config from '../../config';
import { NUMBER_OF_PAGE_RESULTS } from '../../constants/search';
import { checkPageSize } from '../../utils/pageUtil';
import { GET_PRODUCT_BY_CODE } from './product';
import { SEARCH_SUCCESS } from './search';

const { platform } = config;

export const GET_NAVIGATION = `${platform}/category/GET_NAVIGATION`;
export const GET_NAVIGATION_SUCCESS = `${platform}/category/GET_NAVIGATION_SUCCESS`;
export const GET_NAVIGATION_FAIL = `${platform}/category/GET_NAVIGATION_FAIL`;
export const SEARCH_BY_CATEGORY = `${platform}/category/GET_FACET_BY_CATEGORY`;
export const SEARCH_BY_CATEGORY_SUCCESS = `${platform}/category/GET_FACET_BY_CATEGORY_SUCCESS`;
export const SEARCH_BY_CATEGORY_FAIL = `${platform}/category/GET_FACET_BY_CATEGORY_FAIL`;
export const SET_REMOVED_COMPARE_PRODUCT_CODE = `${platform}/category/SET_REMOVED_COMPARE_PRODUCT_CODE`;
export const RESET_REMOVED_COMPARE_PRODUCT_CODE = `${platform}/category/RESET_REMOVED_COMPARE_PRODUCT_CODE`;
export const GET_BREADCRUMB_CATEGORY = `${platform}/category/GET_BREADCRUMB_CATEGORY`;
export const GET_BREADCRUMB_CATEGORY_SUCCESS = `${platform}/category/GET_BREADCRUMB_CATEGORY_SUCCESS`;
export const GET_BREADCRUMB_CATEGORY_FAIL = `${platform}/category/GET_BREADCRUMB_CATEGORY_FAIL`;
export const ENABLE_CATEGORY_FACET_SEARCH = `${platform}/category/ENABLE_CATEGORY_FACET_SEARCH`;
export const SET_DELAY_FACET_QUERY = `${platform}/category/SET_DELAY_FACET_QUERY`;
export const GET_CATEGORY_CODE = `${platform}/category/GET_CATEGORY_CODE`;
export const GET_CATEGORY_CODE_SUCCESS = `${platform}/category/GET_CATEGORY_CODE_SUCCESS`;
export const GET_CATEGORY_CODE_FAIL = `${platform}/category/GET_CATEGORY_CODE_FAIL`;
export const SHOW_CATEGORY_FACET_FLY_OVER = `${platform}/category/SHOW_CATEGORY_FACET_FLY_OVER`;
export const SET_SELECTED_FACET = `${platform}/category/SET_SELECTED_FACET`;
export const SET_FACET_OPTION_STATE = `${platform}/category/SET_FACET_OPTION_STATE`;

/**
 * Retrieves facets by it's category
 * @param {string} categoryCode - the requested category code
 * @param {number} page - the requested page
 * @param {number} pageSize - the requested pageSize
 * @param {string} query - the requested query
 * @param {string|undefined} sort - the current sorting filter
 * @param {boolean=} withContentTiles - with contentTiles flag
 * @returns {{types: [*,*,*], promise: (function(*))}} returns facets by category action
 */
export function search(
  categoryCode,
  page = 0,
  pageSize = NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_1,
  query = '',
  sort = null,
  withContentTiles = false
) {
  const pageResults = checkPageSize(+pageSize, withContentTiles);

  const params = {
    currentPage: page,
    fields: 'FULL',
    pageSize: pageResults,
  };

  if (sort) {
    params.sort = sort;
  }

  if (query) {
    params.query = query;
  }

  return {
    promise: (apiClient) =>
      apiClient.get(
        `/categories/${categoryCode}/products`,
        {
          params,
        },
        { auth: false }
      ),
    types: [SEARCH_BY_CATEGORY, SEARCH_BY_CATEGORY_SUCCESS, SEARCH_BY_CATEGORY_FAIL],
  };
}
/**
 * Function which set the current removed compare product
 * @param {string} productCode - the product code
 * @returns {{type: string, code: *}} the set removed product code action
 */
export function setRemovedCompareProductCode(productCode) {
  return {
    productCode,
    type: SET_REMOVED_COMPARE_PRODUCT_CODE,
  };
}

/**
 * Function which resets the current removed compare product
 * @param {string} productCode - the product code
 * @returns {{type: string, code: *}} the reset removed product code action
 */
export function resetRemovedCompareProduct(productCode) {
  return {
    productCode,
    type: RESET_REMOVED_COMPARE_PRODUCT_CODE,
  };
}

/**
 * Retrieves a breadcrumb category by code.
 * @param {string} categoryCode - The category code.
 * @param {boolean} sorted - The sorted flag
 * @returns {{types: [*,*,*], promise: (function(*))}} The get breadcrumb category action.
 */
export function getBreadcrumbCategory(categoryCode, sorted = false) {
  return {
    promise: (apiClient) =>
      apiClient.get(`/categories/${categoryCode}?fields=FULL&sorted=${sorted}`, {}, { auth: false }),
    types: [GET_BREADCRUMB_CATEGORY, GET_BREADCRUMB_CATEGORY_SUCCESS, GET_BREADCRUMB_CATEGORY_FAIL],
  };
}

/**
 * Function which enables the facet search
 * @param {boolean} enabled - the enabled flag
 * @returns {{type: string, enabled: boolean}} the enabled facet search flag
 */
export function enableFacetSearch(enabled = false) {
  return {
    enabled,
    type: ENABLE_CATEGORY_FACET_SEARCH,
  };
}

/**
 * Function which sets the delay facet query
 * @param {string} facetQuery - the facet query
 * @returns {{clearQuery: boolean, type: string}} the set delay facet query action
 */
export function setDelayFacetQuery(facetQuery) {
  return {
    facetQuery,
    type: SET_DELAY_FACET_QUERY,
  };
}

/**
 * Function which shows/hides the category facet flyover
 * @param {object} feature - the selected feature
 * @return {{showFacetFlyOver: boolean, type: string, selectedFeature: *}}
 * the show category facet flyover action
 */
export const showCategoryFacetFLyOver = (feature) => ({
  selectedFeature: feature,
  type: SHOW_CATEGORY_FACET_FLY_OVER,
});

export const setSelectedFacet = (facetCode, facetValue, selected) => ({
  facetCode,
  facetValue,
  selected,
  type: SET_SELECTED_FACET,
});

export const setFacetOptionState = (code, open, showMore) => ({
  code,
  open,
  showMore,
  type: SET_FACET_OPTION_STATE,
});

/**
 * Reducer
 */
const initialState = {
  bCategory: null,
  breadcrumbCategory: null,
  category: null,
  categoryCode: null,
  delayFacetQuery: null,
  facetOptionStates: {},
  facets: null,
  isSearching: false,
  navigation: null,
  pageSize: NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_1,
  removedCompareCode: null,
  selectedFeature: null,
  sort: null,
};

export default function reducer(state = initialState, action = {}) {
  const { result = {}, type } = action;
  const { data = {} } = result;

  switch (type) {
    case SEARCH_BY_CATEGORY: {
      return {
        ...state,
        isSearching: true,
      };
    }

    case GET_NAVIGATION_SUCCESS:
      return {
        ...state,
        navigation: Object.keys(data).length > 0 && data.constructor === Object ? data : null,
      };
    case SEARCH_SUCCESS:
    case SEARCH_BY_CATEGORY_SUCCESS: {
      const pageSize = data?.pagination?.pageSize || NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_1;
      const sort = data?.sorts?.find((sortType) => sortType.selected);
      const facets = data?.facets || null;

      return {
        ...state,
        facets,
        isSearching: false,
        pageSize,
        searchResult: Object.keys(data)?.length > 0 && data.constructor === Object ? data : null,
        sort: sort?.code || null,
      };
    }
    case SEARCH_BY_CATEGORY_FAIL: {
      return {
        ...state,
        isSearching: false,
      };
    }
    case SET_REMOVED_COMPARE_PRODUCT_CODE: {
      const updatedSearchResult = {
        ...state.searchResult,
      };
      updatedSearchResult.removedProduct = action.productCode ? action.productCode : null;

      return {
        ...state,
        searchResult: updatedSearchResult,
      };
    }
    case RESET_REMOVED_COMPARE_PRODUCT_CODE: {
      const updatedSearchResult = {
        ...state.searchResult,
      };

      updatedSearchResult.removedProduct = null;

      return {
        ...state,
        searchResult: updatedSearchResult,
      };
    }
    case GET_PRODUCT_BY_CODE:
    case GET_BREADCRUMB_CATEGORY: {
      return {
        ...state,
        breadcrumbCategory: null,
      };
    }
    case GET_BREADCRUMB_CATEGORY_SUCCESS: {
      return {
        ...state,
        breadcrumbCategory: Object.keys(data).length > 0 && data.constructor === Object ? data : null,
      };
    }
    case GET_BREADCRUMB_CATEGORY_FAIL: {
      return {
        ...state,
        breadcrumbCategory: null,
      };
    }
    case ENABLE_CATEGORY_FACET_SEARCH: {
      return {
        ...state,
        isSearching: action?.enabled,
      };
    }
    case SET_DELAY_FACET_QUERY: {
      return {
        ...state,
        delayFacetQuery: action?.facetQuery,
      };
    }
    case GET_CATEGORY_CODE_SUCCESS: {
      return {
        ...state,
        categoryCode: data?.code,
      };
    }
    case SHOW_CATEGORY_FACET_FLY_OVER: {
      return {
        ...state,
        selectedFeature: action?.selectedFeature,
      };
    }
    case SET_SELECTED_FACET: {
      const { facetCode, facetValue, selected } = action;
      const { searchResult } = state;
      const { facets } = searchResult;

      const updatedFacets = facets?.map((facet) => {
        if (facet.code === facetCode) {
          return {
            ...facet,
            selected,
            values: facet.values.map((value) => {
              if (value.code === facetValue) {
                return { ...value, selected };
              }

              return value;
            }),
          };
        }

        return facet;
      });

      return {
        ...state,
        searchResult: {
          ...searchResult,
          facets: updatedFacets,
        },
      };
    }

    case SET_FACET_OPTION_STATE: {
      const { code, open, showMore } = action;

      return {
        ...state,
        facetOptionStates: {
          ...state.facetOptionStates,
          [code]: {
            open,
            showMore,
          },
        },
      };
    }
    default:
      return state;
  }
}
