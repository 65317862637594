import { CartEntry, CartEntryGroup, SplitEntryGroup } from 'types/Cart';
import { Order, OrderEntry } from 'types/Order';
import { PAYMENT_ERROR_CODES } from '../constants/order';
import { PRODUCT_PARTNERS } from '../constants/product';
import { isTones } from './brandUtil';
import { isDefaultPartner } from './partner';

/**
 * Groups the order entries by product.
 * @param {Array} entries - List of order entries.
 * @param {Array} configurations - List of the configuration entries.
 * @returns {Array} List of grouped order entries by product.
 */
export function groupEntries(entries?: CartEntry[]): CartEntryGroup[] {
  if (!entries) return [];

  const groupedEntries: CartEntryGroup[] = [];

  entries.forEach((entry) => {
    if (entry?.pcConfigurationCode) {
      return;
    }

    const key = entry.product?.code || '';
    const isGiftCardEntry = !!entry?.giftCardValue;
    const isEVoucher = !!entry?.evoucherValue;

    const baseEntry: CartEntryGroup = {
      basePrice: entry.basePrice || { currencyIso: '', value: 0 },
      entries: [{ ...entry, groupEntryNumber: 1 }],
      product: entry.product || { code: '', name: '' },
      quantity: 1,
      totalProductPrice: entry.totalProductPrice,
      totalProductStrikePrice: entry.totalProductStrikePrice,
    };

    if (!isGiftCardEntry && !isEVoucher) {
      const existingGroup = groupedEntries.find((group) => group.product?.code === key);
      if (!existingGroup) {
        groupedEntries.push(baseEntry);
      } else {
        existingGroup.entries.push({ ...entry, groupEntryNumber: existingGroup.entries.length + 1 });
        existingGroup.quantity = existingGroup.entries.length;
      }
    } else {
      const giftCardEntry: CartEntryGroup = {
        ...baseEntry,
        eVoucherDeliveryDate: entry.evoucherDeliveryDate,
        eVoucherMessage: entry.evoucherMessage,
        eVoucherRecipientMail: entry.evoucherRecipientMail,
        entries: [entry],
        isEVoucher,
        isGiftCard: isGiftCardEntry,
        totalProductPrice: entry.basePrice,
      };

      groupedEntries.push(giftCardEntry);
    }
  });

  return groupedEntries;
}

export const getPaymentErrorMessage = (errorCode = PAYMENT_ERROR_CODES.CODE_00) => {
  switch (errorCode) {
    case PAYMENT_ERROR_CODES.CODE_01: {
      return 'cart_payment_error_code_01';
    }
    case PAYMENT_ERROR_CODES.CODE_02: {
      return 'cart_payment_error_code_02';
    }
    case PAYMENT_ERROR_CODES.CODE_03: {
      return 'cart_payment_error_code_03';
    }
    case PAYMENT_ERROR_CODES.CODE_04: {
      return 'cart_payment_error_code_04';
    }
    case PAYMENT_ERROR_CODES.CODE_05: {
      return 'cart_payment_error_code_05';
    }
    case PAYMENT_ERROR_CODES.CODE_06: {
      return 'cart_payment_error_code_06';
    }
    case PAYMENT_ERROR_CODES.CODE_07: {
      return 'cart_payment_error_code_07';
    }
    case PAYMENT_ERROR_CODES.CODE_08: {
      return 'cart_payment_error_code_08';
    }
    case PAYMENT_ERROR_CODES.CODE_09: {
      return 'cart_payment_error_code_09';
    }
    case PAYMENT_ERROR_CODES.CODE_10: {
      return 'cart_payment_error_code_10';
    }
    case PAYMENT_ERROR_CODES.CODE_11: {
      return 'cart_payment_error_code_11';
    }
    case PAYMENT_ERROR_CODES.CODE_12: {
      return 'cart_payment_error_code_12';
    }
    case PAYMENT_ERROR_CODES.CODE_13: {
      return 'cart_payment_error_code_13';
    }
    case PAYMENT_ERROR_CODES.CODE_14: {
      return 'cart_payment_error_code_14';
    }
    case PAYMENT_ERROR_CODES.CODE_15: {
      return 'cart_payment_error_code_15';
    }
    case PAYMENT_ERROR_CODES.CODE_16: {
      return 'cart_payment_error_code_16';
    }
    default: {
      return 'cart_payment_error_code_00';
    }
  }
};

/**
 * Function which splits the current cart entries based on product partner
 * @param {Array} entries - the current cart entries
 * @returns {object} - the splitEntries object
 */
export const splitEntries = (entries: CartEntry[] | OrderEntry[]) => {
  if (!entries || entries.length === 0) {
    return null;
  }

  const partnerEntries: Record<string, SplitEntryGroup> = {};
  const defaultEntries: CartEntry[] | OrderEntry[] = [];
  const eVoucherEntries: CartEntry[] | OrderEntry[] = [];
  const pcConfigurations: Record<string, SplitEntryGroup> = {};

  entries.forEach((entry) => {
    const partnerCode = entry?.product?.partners?.[0]?.code;
    const isDefaultPartner = partnerCode?.toLowerCase() === PRODUCT_PARTNERS.DEFAULT.toLowerCase();

    if (entry?.pcConfigurationCode) {
      pcConfigurations[entry.pcConfigurationCode] = pcConfigurations[entry.pcConfigurationCode] || { products: [] };
      pcConfigurations[entry.pcConfigurationCode].products!.push(entry);
    } else if (entry?.product?.isTradeplaceProduct && partnerCode) {
      defaultEntries.push(entry);
    } else if (partnerCode && !isDefaultPartner && !entry.pcConfigurationCode) {
      partnerEntries[partnerCode] = partnerEntries[partnerCode] || { entries: [] };
      partnerEntries[partnerCode].entries!.push(entry);
    } else if (entry?.evoucherValue) {
      eVoucherEntries.push(entry);
    } else {
      defaultEntries.push(entry);
    }
  });

  const hasEntries =
    defaultEntries.length > 0 ||
    eVoucherEntries.length > 0 ||
    Object.keys(partnerEntries).length > 0 ||
    Object.keys(pcConfigurations).length > 0;

  if (hasEntries) {
    return {
      defaultEntries,
      eVoucherEntries,
      partnerEntries,
      pcConfigurations,
    };
  }

  return null;
};

/**
 * Function which returns the active cart partners
 * @param {Array} entries - the cart entries
 * @returns {Array|undefined} the active cart partners array
 */
export function getActivePartners(entries: CartEntry[] = []): string[] | undefined {
  if (entries.length === 0) {
    return undefined;
  }

  const partners = new Set<string>();

  entries.forEach((entry) => {
    if (!!entry?.product?.partners?.length && !entry.pcConfigurationCode) {
      const partnerCode = entry.product.partners[0].code;
      if (partnerCode && !isDefaultPartner(partnerCode)) {
        partners.add(partnerCode);
      }
    }
  });

  return partners.size > 0 ? Array.from(partners) : undefined;
}

/**
 * Function which returns the platform specific order code
 * @param {object} order - the order object
 * @returns {string} the default order code or the tones order code
 */
export const getOrderCode = (order?: Order | null) => (isTones ? order?.tonesOrderCode : order?.code);
