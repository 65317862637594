import CommandersActScript from 'components/CommandersAct/CommandersActScript';
import GoogleTagManager from 'components/GoogleTagManager/GoogleTagManager';
import SalesForceScript from 'components/SalesForce/SalesForceScript/SalesForceScript';
import SalesForceSetupScript from 'components/SalesForce/SalesForceScript/SalesForceSetupScript';
import ABTastySetupScript from './ABTastySetupScript';

// Collection of scripts that should be loaded on every page

const GlobalScripts = () => (
  <>
    <CommandersActScript />
    <GoogleTagManager />
    <SalesForceSetupScript />
    <SalesForceScript />
    <ABTastySetupScript />
  </>
);
export default GlobalScripts;
