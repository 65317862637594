import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_NOTIFICATION_OBJECTS } from '../constants/notifications';
import { NotificationType, Notification } from 'types/Notification';

type NewNotification = Omit<Notification, 'uuid'>;

interface ParamsWithNotification {
  notification: NewNotification;
  type?: undefined;
}

interface ParamsWithType {
  notification?: undefined;
  type: NotificationType;
}

interface ParamsWithNotificationAndType {
  notification: NewNotification;
  type: NotificationType;
}

type CreateNotificationParams = ParamsWithNotification | ParamsWithType | ParamsWithNotificationAndType;

export const createNotification = ({ notification, type }: CreateNotificationParams) => {
  const defaultNotification = (type && DEFAULT_NOTIFICATION_OBJECTS[type]) || {};

  return {
    uuid: uuidv4(),
    ...defaultNotification,
    ...notification,
  };
};
