import { ICON_NAMES } from './icon';

export const ORDER_STATUS = {
  AVAILABLE_STORE: 'AVAILABLE_STORE',
  CANCELLED: 'CANCELLED',
  CLOSED: 'CLOSED',
  COMPLETED: 'COMPLETED',
  CREATED: 'CREATED',
  DELIVERED: 'DELIVERED',
  IN_PROGRESS: 'IN_PROGRESS',
  NO_MATCH: 'NO MATCH',
  PART_AVAILABLE_STORE: 'PART_AVAILABLE_STORE',
  PART_IN_PROGRESS: 'PART_IN_PROGRESS',
  PART_PICKUP_STORE: 'PART_PICKUP_STORE',
  PART_REFUND: 'PART_REFUND',
  PICKEDUP_STORE: 'PICKEDUP_STORE',
  REFUND: 'REFUND',
};

export const DELIVERY_STATUS = {
  CANCELLED: 'CANCELLED',
  CONFIRMED: 'CONFIRMED',
  DELIVERED: 'DELIVERED',
  IN_PROGRESS: 'IN_PROGRESS',
  NOT_SHIPPED: 'NOTSHIPPED',
  PART_CONFIRMED: 'PART_CONFIRMED',
  PART_DELIVERED: 'PART_DELIVERED',
  PART_IN_PROGRESS: 'PART_IN_PROGRESS',
  PART_REFUND: 'PART_REFUND',
  PART_SHIPPED: 'PARTSHIPPED',
  REFUND: 'REFUND',
  SHIPPED: 'SHIPPED',
  STOCK: 'STOCK',
  UNCONFIRMED: 'UNCONFIRMED',
};

export const PAYMENT_ERROR_CODES = {
  CODE_00: 'Code00',
  CODE_01: 'Code01',
  CODE_02: 'Code02',
  CODE_03: 'Code03',
  CODE_04: 'Code04',
  CODE_05: 'Code05',
  CODE_06: 'Code06',
  CODE_07: 'Code07',
  CODE_08: 'Code08',
  CODE_09: 'Code09',
  CODE_10: 'Code10',
  CODE_11: 'Code11',
  CODE_12: 'Code12',
  CODE_13: 'Code13',
  CODE_14: 'Code14',
  CODE_15: 'Code15',
  CODE_16: 'Code16',
};

export const onOrderTypes = {
  ON_ORDER: 'onorder',
  PRE_ORDER: 'preorder',
};

export const OFFLINE_FORM_FIELDS = {
  ACCESS_KEY: 'ACCESS_KEY',
  ORDER_CODE: 'ORDER_CODE',
};

const ORDER_COMMERCIAL_DOCUMENT_TYPES = {
  CREDIT_NOTE: 'CreditNoteModel',
  INVOICE: 'InvoiceModel',
  RECEIPT: 'ReceiptModel',
};

export const ORDER_COMMERCIAL_DOCUMENT_KEYS = {
  [ORDER_COMMERCIAL_DOCUMENT_TYPES.INVOICE]: {
    icon: ICON_NAMES.DOWNLOAD,
    key: 'order_invoice_download',
  },
  [ORDER_COMMERCIAL_DOCUMENT_TYPES.CREDIT_NOTE]: {
    icon: ICON_NAMES.DOWNLOAD,
    key: 'order_creditnota_download',
  },
  [ORDER_COMMERCIAL_DOCUMENT_TYPES.RECEIPT]: {
    icon: ICON_NAMES.DOWNLOAD,
    key: 'order_receipt_download',
  },
};

export const ORDER_TYPES = {
  OFFLINE: 'Offline',
  ONLINE: 'Online',
};

export const CLOSING_DELIVERY_STATUSES = {
  [ORDER_STATUS.CLOSED]: {
    icon: ICON_NAMES.TIMES_CIRCLE,
    key: 'order_status_closed',
  },
  [ORDER_STATUS.CANCELLED]: {
    icon: ICON_NAMES.TIMES_CIRCLE,
    key: 'order_status_cancelled',
  },
  [ORDER_STATUS.NO_MATCH]: {
    icon: ICON_NAMES.TIMES_CIRCLE,
    key: 'order_status_not_found',
  },
};

export const DEFAULT_DELIVERY_STATUSES = {
  [ORDER_STATUS.CREATED]: {
    [DELIVERY_STATUS.NOT_SHIPPED]: {
      icon: ICON_NAMES.BOX_OPEN,
      key: 'order_status_new_created',
    },
  },
  [ORDER_STATUS.IN_PROGRESS]: {
    [DELIVERY_STATUS.PART_SHIPPED]: {
      icon: ICON_NAMES.SHIPPING_FAST,
      key: 'order_status_part_shipped',
    },
    [DELIVERY_STATUS.PART_DELIVERED]: {
      icon: ICON_NAMES.SHIPPING_FAST,
      key: 'order_status_part_delivered',
    },
    [DELIVERY_STATUS.SHIPPED]: {
      icon: ICON_NAMES.SHIPPING_FAST,
      key: 'order_status_shipped',
    },
    [DELIVERY_STATUS.NOT_SHIPPED]: {
      icon: ICON_NAMES.SHIPPING_FAST,
      key: 'order_status_not_shipped',
    },
  },
  [ORDER_STATUS.COMPLETED]: {
    [DELIVERY_STATUS.DELIVERED]: {
      icon: ICON_NAMES.CHECK_CIRCLE,
      key: 'order_status_delivered',
    },
  },
  [ORDER_STATUS.DELIVERED]: {
    [DELIVERY_STATUS.DELIVERED]: {
      icon: ICON_NAMES.CHECK_CIRCLE,
      key: 'order_status_delivered',
    },
  },
};

export const COLLECTION_POINT_DELIVERY_STATUSES = {
  [ORDER_STATUS.CREATED]: {
    [DELIVERY_STATUS.NOT_SHIPPED]: {
      icon: ICON_NAMES.BOX_OPEN,
      key: 'order_status_Bpost_collection_point_created',
    },
  },
  [ORDER_STATUS.IN_PROGRESS]: {
    [DELIVERY_STATUS.PART_SHIPPED]: {
      icon: ICON_NAMES.SHIPPING_FAST,
      key: 'order_status_Bpost_collection_point_part_shipped',
    },
    [DELIVERY_STATUS.PART_DELIVERED]: {
      icon: ICON_NAMES.SHIPPING_FAST,
      key: 'order_status_Bpost_collection_part_delivered',
    },
    [DELIVERY_STATUS.SHIPPED]: {
      icon: ICON_NAMES.SHIPPING_FAST,
      key: 'order_status_Bpost_collection_point_shipped',
    },
  },
  [ORDER_STATUS.COMPLETED]: {
    [DELIVERY_STATUS.DELIVERED]: {
      icon: ICON_NAMES.CHECK_CIRCLE,
      key: 'order_status_Bpost_collection_delivered',
    },
  },
  [ORDER_STATUS.DELIVERED]: {
    [DELIVERY_STATUS.DELIVERED]: {
      icon: ICON_NAMES.CHECK_CIRCLE,
      key: 'order_status_Bpost_collection_delivered',
    },
  },
};

export const PICKUP_DELIVERY_STATUSES = {
  [ORDER_STATUS.CREATED]: {
    [DELIVERY_STATUS.UNCONFIRMED]: {
      icon: ICON_NAMES.BOX_OPEN,
      key: 'order_status_pickup_in_store_created',
    },
  },
  [ORDER_STATUS.AVAILABLE_STORE]: {
    [DELIVERY_STATUS.CONFIRMED]: {
      icon: ICON_NAMES.STORE,
      key: 'order_status_pickup_in_store_available',
    },
  },
  [ORDER_STATUS.PART_AVAILABLE_STORE]: {
    [DELIVERY_STATUS.PART_CONFIRMED]: {
      icon: ICON_NAMES.STORE,
      key: 'order_status_pickup_in_store_part_available',
    },
  },
  [ORDER_STATUS.PART_IN_PROGRESS]: {
    [DELIVERY_STATUS.PART_IN_PROGRESS]: {
      icon: ICON_NAMES.STORE,
      key: 'order_status_pickup_in_store_part_in_progress',
    },
  },
  [ORDER_STATUS.PART_PICKUP_STORE]: {
    [DELIVERY_STATUS.PART_DELIVERED]: {
      icon: ICON_NAMES.STORE,
      key: 'order_status_pickup_in_store_part_pickedup_store',
    },
  },
  [ORDER_STATUS.PICKEDUP_STORE]: {
    [DELIVERY_STATUS.DELIVERED]: {
      icon: ICON_NAMES.STORE,
      key: 'order_status_pickup_in_store_pickedup',
    },
  },
  [ORDER_STATUS.IN_PROGRESS]: {
    [DELIVERY_STATUS.UNCONFIRMED]: {
      icon: ICON_NAMES.BOX_OPEN,
      key: 'order_status_pickup_in_store_available',
    },
    [DELIVERY_STATUS.IN_PROGRESS]: {
      icon: ICON_NAMES.SHIPPING_FAST,
      key: 'order_status_pickup_in_store_in_progress',
    },
    [DELIVERY_STATUS.PART_CONFIRMED]: {
      icon: ICON_NAMES.SHIPPING_FAST,
      key: 'order_status_pickup_in_store_part_confirmed',
    },
    [DELIVERY_STATUS.PART_DELIVERED]: {
      icon: ICON_NAMES.SHIPPING_FAST,
      key: 'order_status_pickup_in_store_part_delivered',
    },
    [DELIVERY_STATUS.CONFIRMED]: {
      icon: ICON_NAMES.SHIPPING_FAST,
      key: 'order_status_pickup_in_store_confirmed',
    },
  },
  [ORDER_STATUS.CANCELLED]: {
    [DELIVERY_STATUS.UNCONFIRMED]: {
      icon: ICON_NAMES.TIMES_CIRCLE,
      key: 'order_status_pickup_in_store_not_available',
    },
    [DELIVERY_STATUS.CANCELLED]: {
      icon: ICON_NAMES.TIMES_CIRCLE,
      key: 'order_status_pickup_in_store_cancelled',
    },
  },
  [ORDER_STATUS.COMPLETED]: {
    [DELIVERY_STATUS.DELIVERED]: {
      icon: ICON_NAMES.CHECK_CIRCLE,
      key: 'order_status_pickup_in_store_delivered',
    },
  },
  [ORDER_STATUS.DELIVERED]: {
    [DELIVERY_STATUS.DELIVERED]: {
      icon: ICON_NAMES.CHECK_CIRCLE,
      key: 'order_status_pickup_in_store_delivered',
    },
  },
  [ORDER_STATUS.REFUND]: {
    [DELIVERY_STATUS.REFUND]: {
      icon: ICON_NAMES.CHECK_CIRCLE,
      key: 'order_status_pickup_in_store_refund',
    },
  },
};
