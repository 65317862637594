import { NextRouter } from 'hooks/useRouter';
import { Announcement } from 'types/Announcement';
import config from '../config';
import { PAGE_TYPES, PageTypeKey } from '../constants/pageType';
import { NUMBER_OF_PAGE_RESULTS } from '../constants/search';
import { getPageNameFormUrl } from './urlUtil';

/**
 * Function which checks the given page size
 * @param {number} pageResults - the given page results
 * @param {boolean} withContentTiles - the with content title flag
 * @returns {number} the correct page size
 */
export function checkPageSize(pageResults: number, withContentTiles = false) {
  if (pageResults) {
    switch (pageResults) {
      case NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_1:
        if (withContentTiles) {
          return NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_1 - 1;
        }
        return NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_1;
      case NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_2:
        if (withContentTiles) {
          return NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_2 - 1;
        }
        return NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_2;
      case NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_3:
        if (withContentTiles) {
          return NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_3 - 1;
        }
        return NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_3;
      default:
        if (withContentTiles) {
          return NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_1 - 1;
        }
        return NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_1;
    }
  }

  if (withContentTiles) {
    return NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_1 - 1;
  }
  return NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_1;
}

/**
 * Function which checks if the announcement should be visible for a certain page
 * @param {ReturnType<typeof useRouter>} router - the router object
 * @param {array} pageTypes - the pageTypes array
 * @returns {boolean} True if the announcement should be visible. False if not.
 */
export function showAnnouncement(router: NextRouter, pageTypes: PageTypeKey[]): boolean {
  const pageName = getPageNameFormUrl(router?.pathname);

  if (pageTypes.length > 0 && pageName) {
    const pages = pageTypes.map((pageType) => PAGE_TYPES[pageType]).join(', ');

    return pages.includes(pageName) || pages.includes(PAGE_TYPES.SITE);
  }

  return false;
}

/**
 * Function which checks if the announcements are visible
 * based on the cookies and current announcements
 * @param {object} cookies - the cookie object
 * @param {array} currentAnnouncements - the current announcements
 * @param {object} location - the location object
 * @return {boolean} True if the announcement should be visible. False if not.
 */
export const hasAnnouncements = (
  cookies: {
    [x: string]: unknown;
  },
  currentAnnouncements: Announcement[] = [],
  router: NextRouter,
) => {
  const announcements = [];
  if (cookies && !!currentAnnouncements?.length) {
    currentAnnouncements.forEach((currentAnnouncement) => {
      if (currentAnnouncement?.code) {
        const announcementCookie = cookies[config.announcements.key];
        const isVisible = showAnnouncement(router, currentAnnouncement.pageTypes);
        if (
          !(
            announcementCookie &&
            typeof announcementCookie === 'string' &&
            announcementCookie.includes(currentAnnouncement.code)
          ) &&
          isVisible
        ) {
          announcements.push(currentAnnouncement);
        }
      }
    });
  }
  return announcements.length > 0;
};
