import { useAnonymous, useAuthLoaded } from 'store/auth';
import { useUser } from 'store/user';

const useAuthResolver = () => {
  const user = useUser();
  const anonymous = useAnonymous();
  const authLoaded = useAuthLoaded();
  const authResolved = authLoaded && !!(anonymous || user);

  return { anonymous, authLoaded, authResolved, user };
};

export default useAuthResolver;
