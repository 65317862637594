import { brandSelect, color } from '../../functions';

const defaultValues = {};

const krefel = {
  'text-color': color('primary-400'),
  'text-hover-color': color('primary-300'),
};

const hifi = {
  'text-color': color('secondary-300'),
  'text-hover-color': color('secondary-200'),
};

const tones = {
  'text-color': color('secondary-400'),
  'text-hover-color': color('secondary-300'),
};

const brandSpecifics = {
  krefel,
  hifi,
  tones,
};

const buttonSpecifics = {
  ...defaultValues,
  ...brandSelect(brandSpecifics),
};

export default buttonSpecifics;
