/**
 * The Redux store module containing the store related action, action types and reducer.
 */

import { apiClient } from 'helpers/ApiClient';
import config from '../../config';

const { platform } = config;

/**
 * Action types
 */
export const GET_STORE_BY_LOCATION = `${platform}/store/GET_STORE_BY_LOCATION`;
export const GET_STORE_BY_LOCATION_SUCCESS = `${platform}/store/GET_STORE_BY_LOCATION_SUCCESS`;
export const GET_STORE_BY_LOCATION_FAIL = `${platform}/store/GET_STORE_BY_LOCATION_FAIL`;
export const ENABLE_GOOGLE_MAPS = `${platform}/store/ENABLE_GOOGLE_MAPS`;

/**
 * Actions
 */

export const getStoresByLocation = ({ country = 'BE', fields = 'FULL', location, pageSize = 5, postalCode }) => ({
  promise: () =>
    apiClient({
      params: {
        country,
        fields,
        location,
        pageSize,
        postalCode,
      },
      url: `/stores/location`,
    }),
  types: [GET_STORE_BY_LOCATION, GET_STORE_BY_LOCATION_SUCCESS, GET_STORE_BY_LOCATION_FAIL],
});

/**
 * Function which enables google maps
 * @param {boolean} enabled - the enabled flag
 * @return {{type: string, enabled: boolean}} the enable google maps action
 */
export const enableGoogleMaps = (enabled = false) => ({
  enabled,
  type: ENABLE_GOOGLE_MAPS,
});

/**
 * Reducer
 */
const initialState = {
  enableMaps: false,
  mapCenter: null,
  pickupStores: [],
};

/**
 * The store reducer.
 *
 * @param {object} state - The current state.
 * @param {object} action - The dispatched action.
 * @returns {object} The updated state.
 */
const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_STORE_BY_LOCATION: {
      return {
        ...state,
        loadingStores: true,
        mapCenter: null,
        pickupStores: [],
      };
    }
    case GET_STORE_BY_LOCATION_SUCCESS:
      return {
        ...state,
        loadingStores: false,
        mapCenter: action?.result?.data?.stores?.length > 0 &&
          action.result.data.stores[0].geoPoint || null,
        pickupStores:
          action?.result?.data?.stores || [],
      };
    case GET_STORE_BY_LOCATION_FAIL:
      return {
        ...state,
        loadingStores: false,
        mapCenter: null,
        pickupStores: [],
      };
    case ENABLE_GOOGLE_MAPS: {
      return {
        ...state,
        enableMaps: action?.enabled,
      };
    }
    default:
      return state;
  }
};

export default reducer;
