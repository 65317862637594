import { BRAND_VALUES, CURRENT_BRAND } from './brand';

export const GROUPS = {
  ADDRESS: 'address',
  ATP: 'atp',
  BASKET_OVERVIEW: 'basket_overview',
  BRANDS: 'brands',
  BUDGET_CARD: 'budget_card',
  CART: 'cart',
  CART_DELIVERY_FORM: 'cart_delivery_form',
  CART_LOGIN: 'cart_login',
  CASHBACK: 'cashback',
  CATEGORY: 'category',
  CHECKOUT: 'checkout',
  CMS: 'cms',
  CONTACT: 'contact',
  CONTENTFUL: 'contentful',
  COOKIE_POLICY: 'cookie_policy',
  DELIVERY: 'delivery',
  DELIVERY_SERVICE: 'delivery_service',
  DEMO_DAYS: 'demo_days',
  ERRORS: 'errors',
  FOOTER: 'footer',
  FORM: 'form',
  GENERAL: 'general',
  GIFT_CARD: 'gift_card',
  HEADER: 'header',
  HERO_HEADER: 'hero_header',
  HOME: 'home',
  INFOFICHES: 'infofiches',
  LANGUAGE_POPUP: 'language_popup',
  LOGIN: 'login',
  MANUALS: 'manuals',
  MY_ACCOUNT: 'my_account',
  NOTIFICATION: 'notification',
  ONORDER: 'onorder',
  ORDER: 'order',
  ORDER_STATUS: 'order_status',
  PART_SERVICE: 'part_service',
  PASSWORD_FORGOTTEN: 'password_forgotten',
  PAYMENT_DETAILS: 'payment_details',
  PC_CONFIGURATOR: 'configurator',
  PRIVACY: 'privacy',
  PRODUCT_COMPARE: 'product_compare',
  PRODUCT_DETAIL: 'product_detail',
  PRODUCT_INSTALMENT: 'product_instalment',
  REGISTRATION: 'registration',
  REPAIR_SERVICE: 'repair_service',
  RETURNS: 'returns',
  REVIEWS: 'reviews',
  SAFER_PAY: 'safer_pay',
  SEARCH: 'search',
  SERVICES: 'services',
  STATUS_PAGES: 'status_pages',
  STORE_FINDER: 'store_finder',
  SUPPLIERS: 'suppliers',
  TONES: 'tones',
  WISH_LIST: 'wish_list',
} as const;
export type TranslationGroup = (typeof GROUPS)[keyof typeof GROUPS];

export const DEFAULT_GROUPS: TranslationGroup[] = [
  GROUPS.NOTIFICATION,
  GROUPS.GENERAL,
  GROUPS.HEADER,
  GROUPS.FOOTER,
  GROUPS.SEARCH,
  GROUPS.FORM,
  GROUPS.LOGIN,
  GROUPS.REGISTRATION,
  GROUPS.MY_ACCOUNT,
  GROUPS.ATP,
  GROUPS.COOKIE_POLICY,
  GROUPS.LANGUAGE_POPUP,
  GROUPS.STATUS_PAGES,
  GROUPS.PRODUCT_COMPARE,
  GROUPS.CART_LOGIN,
  GROUPS.WISH_LIST,
];

export const DEFAULT_LOCALE = 'default';

export const locales = {
  [BRAND_VALUES.KREFEL.toLowerCase()]: {
    defaultLocale: 'nl',
    locales: ['nl', 'fr'],
  },
  [BRAND_VALUES.HIFI.toLowerCase()]: {
    defaultLocale: 'fr',
    locales: ['fr', 'de', 'en'],
  },
  [BRAND_VALUES.TONES.toLowerCase()]: {
    defaultLocale: 'nl',
    locales: ['nl', 'fr'],
  },
};

export const BRAND_SPECIFIC_DEFAULT_LOCALE = locales[CURRENT_BRAND].defaultLocale;

export const ISO_LOCALES: Record<string, Record<string, string>> = {
  [BRAND_VALUES.KREFEL.toLowerCase()]: {
    fr: 'fr-be',
    nl: 'nl-be',
  },
  [BRAND_VALUES.HIFI.toLowerCase()]: {
    de: 'de-lu',
    en: 'en-lu',
    fr: 'fr-lu',
  },
  [BRAND_VALUES.TONES.toLowerCase()]: {
    fr: 'fr-be',
    nl: 'nl-be',
  },
};

export const BRAND_SPECIFIC_ISO_LOCALES = ISO_LOCALES[CURRENT_BRAND];
