import config from 'config';
import { useCookies } from 'react-cookie';

const useRecentProductsFunctions = () => {
  const [cookies, setCookies] = useCookies();

  const cookieKey = config.recently_viewed_products.key;
  const maxProducts = config.recently_viewed_products.max;

  const recentlyViewedProductsCookie: string[] = cookies?.[cookieKey] ?? [];

  const clearRecentlyViewedProducts = () => {
    setCookies(cookieKey, []);
  };

  const addRecentlyViewedProduct = (productCode: string) => {
    const productCodes = recentlyViewedProductsCookie;

    if (!productCodes.includes(productCode)) {
      productCodes.unshift(productCode);
    }

    const recentlyViewedProducts =
      productCodes.length > maxProducts ? productCodes.slice(0, maxProducts) : productCodes;

    setCookies(cookieKey, recentlyViewedProducts);
  };

  return { addRecentlyViewedProduct, clearRecentlyViewedProducts, recentlyViewedProductsCookie };
};

export default useRecentProductsFunctions;
