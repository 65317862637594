/* eslint-disable sort-keys-fix/sort-keys-fix */
/**
 * The Redux checkout module containing the related actions, action types and reducer.
 */

import { apiClient } from 'helpers/ApiClient';
import { setSalesForceUser } from 'utils/authUtil';
import { setDataFromReduxAction } from 'utils/customerFeedbackUtil';
import config from '../../config';
import { DELIVERY_METHODS } from '../../constants/checkout';
import { convertToIso } from '../../utils/dateUtil';
import { formatPostalCode } from '../../utils/postalCodeUtil';
import { getErrorMessageFromAction } from "../../utils/reduxUtil";
import { registerUserEmail } from '../../utils/salesforceUtil';
import { ADD_PRODUCT_SUCCESS, CLEAR_CART, DELETE_ENTRY_SUCCESS, DELETE_PRODUCT_SUCCESS } from './cart';

const { defaultCountry, platform } = config;

/**
 * Action types
 */
export const SET_DELIVERY_METHOD = `${platform}/checkout/SET_DELIVERY_METHOD`;
export const SET_DELIVERY_METHOD_OPTIONS = `${platform}/checkout/SET_DELIVERY_METHOD_OPTIONS`;
export const GET_DELIVERY_METHODS = `${platform}/checkout/GET_DELIVERY_METHODS`;
export const GET_DELIVERY_METHODS_SUCCESS = `${platform}/checkout/GET_DELIVERY_METHODS_SUCCESS`;
export const GET_DELIVERY_METHODS_FAIL = `${platform}/checkout/GET_DELIVERY_METHODS_FAIL`;
export const SUBMIT_DELIVERY_METHOD = `${platform}/checkout/SUBMIT_DELIVERY_METHOD`;
export const SUBMIT_DELIVERY_METHOD_SUCCESS = `${platform}/checkout/SUBMIT_DELIVERY_METHOD_SUCCESS`;
export const SUBMIT_DELIVERY_METHOD_FAIL = `${platform}/checkout/SUBMIT_DELIVERY_METHOD_FAIL`;
export const GET_BPOST_PICKUP_POINT = `${platform}/checkout/GET_BPOST_PICKUP_POINT`;
export const GET_BPOST_PICKUP_POINT_SUCCESS = `${platform}/checkout/GET_BPOST_PICKUP_POINT_SUCCESS`;
export const GET_BPOST_PICKUP_POINT_FAIL = `${platform}/checkout/GET_BPOST_PICKUP_POINT_FAIL`;
export const GET_CARGOVELO_DELIVERY_SLOTS = `${platform}/checkout/GET_CARGOVELO_DELIVERY_SLOTS`;
export const GET_CARGOVELO_DELIVERY_SLOTS_SUCCESS = `${platform}/checkout/GET_CARGOVELO_DELIVERY_SLOTS_SUCCESS`;
export const GET_CARGOVELO_DELIVERY_SLOTS_FAIL = `${platform}/checkout/GET_CARGOVELO_DELIVERY_SLOTS_FAIL`;
export const SUBMIT_GUEST_LOGIN_EMAIL = `${platform}/checkout/SUBMIT_GUEST_LOGIN_EMAIL`;
export const SUBMIT_GUEST_LOGIN_EMAIL_SUCCESS = `${platform}/checkout/SUBMIT_GUEST_LOGIN_EMAIL_SUCCESS`;
export const SUBMIT_GUEST_LOGIN_EMAIL_FAIL = `${platform}/checkout/SUBMIT_GUEST_LOGIN_EMAIL_FAIL`;
export const GET_PAYMENT_METHODS = `${platform}/checkout/GET_PAYMENT_METHODS`;
export const GET_PAYMENT_METHODS_SUCCESS = `${platform}/checkout/GET_PAYMENT_METHODS_SUCCESS`;
export const GET_PAYMENT_METHODS_FAIL = `${platform}/checkout/GET_PAYMENT_METHODS_FAIL`;
export const SET_PAYMENT_METHOD = `${platform}/checkout/SET_PAYMENT_METHOD`;
export const SUBMIT_PAYMENT_METHOD = `${platform}/checkout/SUBMIT_PAYMENT_METHOD`;
export const SUBMIT_PAYMENT_METHOD_SUCCESS = `${platform}/checkout/SUBMIT_PAYMENT_METHOD_SUCCESS`;
export const SUBMIT_PAYMENT_METHOD_FAIL = `${platform}/checkout/SUBMIT_PAYMENT_METHOD_FAIL`;
export const SUBMIT_DELIVERY_ADDRESS = `${platform}/checkout/SUBMIT_DELIVERY_ADDRESS`;
export const SUBMIT_DELIVERY_ADDRESS_SUCCESS = `${platform}/checkout/SUBMIT_DELIVERY_ADDRESS_SUCCESS`;
export const SUBMIT_DELIVERY_ADDRESS_FAIL = `${platform}/checkout/SUBMIT_DELIVERY_ADDRESS_FAIL`;
export const EDIT_DELIVERY_ADDRESS = `${platform}/checkout/EDIT_DELIVERY_ADDRESS`;
export const EDIT_DELIVERY_ADDRESS_SUCCESS = `${platform}/checkout/EDIT_DELIVERY_ADDRESS_SUCCESS`;
export const EDIT_DELIVERY_ADDRESS_FAIL = `${platform}/checkout/EDIT_DELIVERY_ADDRESS_FAIL`;
export const SUBMIT_ORDER = `${platform}/checkout/SUBMIT_ORDER`;
export const SUBMIT_ORDER_SUCCESS = `${platform}/checkout/SUBMIT_ORDER_SUCCESS`;
export const SUBMIT_ORDER_FAIL = `${platform}/checkout/SUBMIT_ORDER_FAIL`;
export const GET_CONFIRMED_ORDER = `${platform}/checkout/GET_CONFIRMED_ORDER`;
export const GET_CONFIRMED_ORDER_SUCCESS = `${platform}/checkout/GET_CONFIRMED_ORDER_SUCCESS`;
export const GET_CONFIRMED_ORDER_FAIL = `${platform}/checkout/GET_CONFIRMED_ORDER_FAIL`;
export const CLEAR_DELIVERY_SUBMIT_ERROR = `${platform}/checkout/CLEAR_DELIVERY_SUBMIT_ERROR`;
export const REMOVE_PAYMENT_METHOD = `${platform}/checkout/REMOVE_PAYMENT_METHOD`;
export const REMOVE_PAYMENT_METHOD_SUCCESS = `${platform}/checkout/REMOVE_PAYMENT_METHOD_SUCCESS`;
export const REMOVE_PAYMENT_METHOD_FAIL = `${platform}/checkout/REMOVE_PAYMENT_METHOD_FAIL`;
export const REMOVE_PAYMENT_ADDRESS = `${platform}/checkout/REMOVE_PAYMENT_ADDRESS`;
export const REMOVE_PAYMENT_ADDRESS_SUCCESS = `${platform}/checkout/REMOVE_PAYMENT_ADDRESS_SUCCESS`;
export const REMOVE_PAYMENT_ADDRESS_FAIL = `${platform}/checkout/REMOVE_PAYMENT_ADDRESS_FAIL`;
export const DELETE_DELIVERY_METHOD = `${platform}/checkout/DELETE_DELIVERY_METHOD`;
export const DELETE_DELIVERY_METHOD_SUCCESS = `${platform}/checkout/DELETE_DELIVERY_METHOD_SUCCESS`;
export const DELETE_DELIVERY_METHOD_FAIL = `${platform}/checkout/DELETE_DELIVERY_METHOD_FAIL`;
export const SET_SELECTED_STORE_ID = `${platform}/checkout/SET_SELECTED_STORE_ID`;
export const GET_NEWRELIC_ORDER = `${platform}/checkout/GET_NEWRELIC_ORDER`;
export const GET_NEWRELIC_ORDER_SUCCESS = `${platform}/checkout/GET_NEWRELIC_ORDER_SUCCESS`;
export const GET_NEWRELIC_ORDER_FAIL = `${platform}/checkout/GET_NEWRELIC_ORDER_FAIL`;
export const GET_HQ_INFO = `${platform}/checkout/GET_HQ_INFO`;
export const GET_HQ_INFO_SUCCESS = `${platform}/checkout/GET_HQ_INFO_SUCCESS`;
export const GET_HQ_INFO_FAIL = `${platform}/checkout/GET_HQ_INFO_FAIL`;
export const SET_PAYCONIQ_PAYMENT_INFO = `${platform}/checkout/SET_PAYCONIQ_PAYMENT_INFO`;
export const GET_PAYCONIQ_PAYMENT_STATUS = `${platform}/checkout/GET_PAYCONIQ_PAYMENT_STATUS`;
export const GET_PAYCONIQ_PAYMENT_STATUS_SUCCESS = `${platform}/checkout/GET_PAYCONIQ_PAYMENT_STATUS_SUCCESS`;
export const GET_PAYCONIQ_PAYMENT_STATUS_FAIL = `${platform}/checkout/GET_PAYCONIQ_PAYMENT_STATUS_FAIL`;
export const CANCEL_PAYCONIQ_PAYMENT = `${platform}/checkout/CANCEL_PAYCONIQ_PAYMENT`;
export const CANCEL_PAYCONIQ_PAYMENT_SUCCESS = `${platform}/checkout/CANCEL_PAYCONIQ_PAYMENT_SUCCESS`;
export const CANCEL_PAYCONIQ_PAYMENT_FAIL = `${platform}/checkout/CANCEL_PAYCONIQ_PAYMENT_FAIL`;
export const SET_PAYMENT_ALIAS = `${platform}/checkout/SET_PAYMENT_ALIAS`;
export const SUBMIT_BLOCKED_DELIVERY_EVENT = `${platform}/checkout/SUBMIT_BLOCKED_DELIVERY_EVENT`;
export const SUBMIT_BLOCKED_DELIVERY_EVENT_SUCCESS = `${platform}/checkout/SUBMIT_BLOCKED_DELIVERY_EVENT_SUCCESS`;
export const SUBMIT_BLOCKED_DELIVERY_EVENT_FAIL = `${platform}/checkout/SUBMIT_BLOCKED_DELIVERY_EVENT_FAIL`;
export const SUBMIT_NEWRELIC_CHECKOUT_ERROR = `${platform}/checkout/SSUBMIT_NEWRELIC_CHECKOUT_ERROR`;
export const SUBMIT_NEWRELIC_CHECKOUT_ERROR_SUCCESS = `${platform}/checkout/SUBMIT_NEWRELIC_CHECKOUT_ERROR_SUCCESS`;
export const SUBMIT_NEWRELIC_CHECKOUT_ERROR_FAIL = `${platform}/checkout/SUBMIT_NEWRELIC_CHECKOUT_ERROR_FAIL`;
export const GET_DELIVERY_SLOTS = `${platform}/checkout/GET_DELIVERY_SLOTS`;
export const GET_DELIVERY_SLOTS_FAIL = `${platform}/checkout/GET_DELIVERY_SLOTS_FAIL`;
export const GET_DELIVERY_SLOTS_SUCCESS = `${platform}/checkout/GET_DELIVERY_SLOTS_SUCCESS`;
export const GET_CITY_INFO = `${platform}/postalCode/GET_CITY_INFO`;
export const GET_CITY_INFO_SUCCESS = `${platform}/postalCode/GET_CITY_INFO_SUCCESS`;
export const GET_CITY_INFO_FAIL = `${platform}/postalCode/GET_CITY_INFO_FAIL`;
export const SET_SELECTED_ADDRESS = `${platform}/checkout/SET_SELECTED_ADDRESS`;
export const GET_SANTANDER_CONFIGURATION = `${platform}/checkout/GET_SANTANDER_CONFIGURATION`;
export const GET_SANTANDER_CONFIGURATION_SUCCESS = `${platform}/checkout/GET_SANTANDER_CONFIGURATION_SUCCESS`;
export const GET_SANTANDER_CONFIGURATION_FAIL = `${platform}/checkout/GET_SANTANDER_CONFIGURATION_FAIL`;
export const SET_FULL_PAID_GIFTCARD = `${platform}/checkout/SET_FULL_PAID_GIFTCARD`;
export const SUBMIT_INFORM_BY_SMS = `${platform}/checkout/SUBMIT_INFORM_BY_SMS`;
export const SUBMIT_INFORM_BY_SMS_SUCCESS = `${platform}/checkout/SUBMIT_INFORM_BY_SMS_SUCCESS`;
export const SUBMIT_INFORM_BY_SMS_FAIL = `${platform}/checkout/SUBMIT_INFORM_BY_SMS_FAIL`;
export const SUBMIT_DELIVERY_METHOD_PICK_UP = `${platform}/checkout/SUBMIT_DELIVERY_METHOD_PICK_UP`;
export const SUBMIT_DELIVERY_METHOD_PICK_UP_SUCCESS = `${platform}/checkout/SUBMIT_DELIVERY_METHOD_PICK_UP_SUCCESS`;
export const SUBMIT_DELIVERY_METHOD_PICK_UP_FAIL = `${platform}/checkout/SUBMIT_DELIVERY_METHOD_PICK_UP_FAIL`;
export const SET_INFORMATION_FORM = `${platform}/checkoutSET_INFORMATION_FORM`;
export const SET_SHOW_PAYMENT_DETAILS = `${platform}/checkout/SET_SHOW_PAYMENT_DETAILS`;
export const SET_USER_INFO_ADDRESS = `${platform}/checkout/SET_USER_INFO_ADDRESS`;
export const CLEAR_NEW_CHECKOUT_INFO = `${platform}/checkout/CLEAR_NEW_CHECKOUT_INFO`;

/**
 * Sets the delivery method.
 *
 * @param {object|null} deliveryMethod - the selected delivery method.
 * @return {{type: string, deliveryMethod: *}} returns the selected delivery method.
 */
export const setDeliveryMethod = (deliveryMethod) => ({
  type: SET_DELIVERY_METHOD,
  deliveryMethod,
});
/**
 * Sets the delivery method.
 *
 * @param {object|null} deliveryMethod - the selected delivery method.
 * @return {{type: string, deliveryMethod: *}} returns the selected delivery method.
 */
export const setSelectedAddress = (address) => ({
  type: SET_SELECTED_ADDRESS,
  address,
});

/**
 * Sets the delivery method options.
 *
 * @param {object|undefined} options - the options for the selected delivery method.
 * @returns {{type: string, options: *}} Returns the set delivery method options action.
 *
 */
export const setDeliveryMethodOptions = (options) => ({
  type: SET_DELIVERY_METHOD_OPTIONS,
  options,
});

/**
 * Sets the isFullPaidGiftcard to given state when the order is fully paid with giftcards.
 *
 * @param {boolean} value - the state for the isFullPaidGiftcard
 * @returns {{type: string, options: *}} Returns the set full paid gifrcard action.
 *
 */
export const setFullPaidGiftcard = (value) => ({
  type: SET_FULL_PAID_GIFTCARD,
  value,
});

export const setInformationForm = (informationFormValues) => ({
  informationForm: informationFormValues,
  type: SET_INFORMATION_FORM,
});

export const setShowPaymentDetails = (booleanValue) => ({
  showPaymentDetails: booleanValue,
  type: SET_SHOW_PAYMENT_DETAILS,
});

export const setUserInfoAddress = (address) => ({
  type: SET_USER_INFO_ADDRESS,
  userInfoAddress: address,
});

export const clearNewCheckoutInfo = () => ({
  type: CLEAR_NEW_CHECKOUT_INFO,
});

/**
 * Gets the user's city info based on the given latitute and longitude
 * @param {string} latitude - The latitude of the current navigator.
 * @param {string} longitude - The longitude of the current navigator.
 */
export const getCityByLatLong = (latitude, longitude) => ({
  types: [GET_CITY_INFO, GET_CITY_INFO_SUCCESS, GET_CITY_INFO_FAIL],
  promise: () =>
    apiClient({
      url: `/postalCode/location`,
      params: {
        latitude,
        longitude,
        fields: 'FULL',
      },
    }),
});

/**
 * Gets the current carts santander zerointerest configuration
 * @param {boolean} isAnonymous - If the user is anonymous or not.
 * @param {string} anonymousCartId - The anonymous cart id.
 */
export const getSantanderConfiguration = (isAnonymous, anonymousCartId) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  return {
    types: [GET_SANTANDER_CONFIGURATION, GET_SANTANDER_CONFIGURATION_SUCCESS, GET_SANTANDER_CONFIGURATION_FAIL],
    promise: () =>
      apiClient({
        url: `/users/${userId}/carts/${cartId}/zerointerest`,
        params: {
          fields: 'FULL',
        },
      }),
  };
};

export const getDeliveryMethods = (postalCode, location, isAnonymous, anonymousCartId, country = defaultCountry) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';
  let currentLocation;

  if (postalCode && location) {
    currentLocation = `${postalCode} - ${location}`;
  }
  return {
    types: [GET_DELIVERY_METHODS, GET_DELIVERY_METHODS_SUCCESS, GET_DELIVERY_METHODS_FAIL],
    promise: () =>
      apiClient({
        url: `/users/${userId}/carts/${cartId}/deliverymodes`,
        params: {
          postalCode,
          location,
          country,
          fields: 'FULL',
        },
      }),
    postalCode: currentLocation,
    selectedPostalCode: { postalCode, name: location, country },
  };
};

export const submitDeliveryMethodWithOptions = (
  deliveryMethod,
  options,
  isAnonymous,
  anonymousCartId,
  passOptionsAsBody = false
) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';
  const optionsQueryArray = [];

  if (!passOptionsAsBody && options) {
    Object?.keys(options)?.forEach((optionKey) => {
      const optionValue = options[optionKey];

      if (optionValue || optionValue >= 0) {
        if (optionKey === 'date') {
          const formattedDate = new Date(optionValue);
          formattedDate.setHours(0, 0, 0, 0);
          optionsQueryArray.push(`${optionKey}=${convertToIso(formattedDate)}`);
        } else {
          optionsQueryArray.push(`${optionKey}=${optionValue}`);
        }
      }
    });
  }

  return {
    types: [SUBMIT_DELIVERY_METHOD, SUBMIT_DELIVERY_METHOD_SUCCESS, SUBMIT_DELIVERY_METHOD_FAIL],
    promise: () =>
      apiClient({
        method: 'POST',
        url: `/users/${userId}/carts/${cartId}/deliverymethods/${deliveryMethod}?fields=FULL${optionsQueryArray ? `&${optionsQueryArray.join('&')}` : ''
          }`,
        data: passOptionsAsBody ? options : null,
      }),
  };
};


export const deleteDeliveryMethod = (anonymous, anonymousCartId, cartCode) => {
  const userId = anonymous ? 'anonymous' : 'current';
  const cartId = anonymous ? anonymousCartId : cartCode || 'current';

  return {
    types: [DELETE_DELIVERY_METHOD, DELETE_DELIVERY_METHOD_SUCCESS, DELETE_DELIVERY_METHOD_FAIL],
    promise: () => apiClient({ method: 'DELETE', url: `/users/${userId}/carts/${cartId}/deliverymode` }),
  };
};

export const getDeliverySlots = ({
  anonymousCartId,
  country = defaultCountry,
  deliveryMethodCode,
  isAnonymous = false,
  level,
  location,
  postalCode,
  street,
  streetNumber,
}) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';
  const pickup = deliveryMethodCode === DELIVERY_METHODS.PICK_UP_IN_STORE;

  return {
    types: [GET_DELIVERY_SLOTS, GET_DELIVERY_SLOTS_SUCCESS, GET_DELIVERY_SLOTS_FAIL],
    promise: () =>
      apiClient({
        url: `/users/${userId}/carts/${cartId}/deliverymethods/${deliveryMethodCode}`,
        params: {
          postalCode,
          country,
          location,
          fields: pickup ? 'PICKUP' : 'FULL',
          level,
          street: street ?? null, // Required for BE
          number: streetNumber ?? null, // Required for BE
        },
      }),
  };
};

/**
 * Retrieves the cargoVelo delivery slots of a given delivery method and post code
 *
 * @param {string} postalCode - the postcode
 * @param {boolean} isAnonymous - Flag if the current user is anonymous or not.
 * @param {string} anonymousCartId - The anonymous cart id from the cookie.
 * @param {string=} country - The country code.
 * @return {{types: [*,*,*], promise: (function(*))}} returns the deliverySlots
 */
export const getCargoVeloDeliverySlots = (
  postalCode,
  isAnonymous = false,
  anonymousCartId,
  country = defaultCountry
) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';
  return {
    types: [GET_CARGOVELO_DELIVERY_SLOTS, GET_CARGOVELO_DELIVERY_SLOTS_SUCCESS, GET_CARGOVELO_DELIVERY_SLOTS_FAIL],
    promise: () =>
      apiClient({
        url: `/users/${userId}/carts/${cartId}/deliverymethods/${DELIVERY_METHODS.CARGOVELO_DELIVERY}`,
        params: {
          postalCode,
          country,
        },
      }),
  };
};

/**
 * Retrieves the individual BPost pickup point details.
 *
 * @param {boolean} isAnonymous - Flag if the current user is anonymous or not.
 * @param {string} anonymousCartId - The anonymous cart id from the cookie.
 * @param {string} id - The BPost pickup point id.
 * @param {string} type - The BPost pickup point type (1, 2 or 3).
 * @returns {{types: [*,*,*], promise: (function(*))}} The get stores for pickup in store action.
 */
export const getBPostPickupPoint = (isAnonymous = false, anonymousCartId, id, type) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  return {
    types: [GET_BPOST_PICKUP_POINT, GET_BPOST_PICKUP_POINT_SUCCESS, GET_BPOST_PICKUP_POINT_FAIL],
    promise: () =>
      apiClient({
        url: `/users/${userId}/carts/${cartId}/deliverymethods/${DELIVERY_METHODS.BPOST_PICK_UP}/${id}/${type}`,
      }),
  };
};

export const getPaymentMethods = (isAnonymous, anonymousCartId, cartCode, isCalledByApp) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  // If cart code is given, pass cart code for the logged in user to get the right cart
  const cartId = isAnonymous ? anonymousCartId : cartCode || 'current';

  return {
    types: [GET_PAYMENT_METHODS, GET_PAYMENT_METHODS_SUCCESS, GET_PAYMENT_METHODS_FAIL],
    promise: () => apiClient({ url: `/users/${userId}/carts/${cartId}/paymentmodes`, params: { isCalledByApp } }),
  };
};

/**
 * Retrieves the confirmed order by cart id/guid.
 *
 * @param {string} orderId - The order id.
 * @return {{types: [*,*,*], promise: (function(*))}} the submitOrder action
 */
export const getConfirmedOrder = (orderId) => ({
  types: [GET_CONFIRMED_ORDER, GET_CONFIRMED_ORDER_SUCCESS, GET_CONFIRMED_ORDER_FAIL],
  promise: () =>
    apiClient({
      url: `/orders/${orderId}`,
      params: {
        fields: 'FULL',
      },
    }),
});

/**
 * Sets the delivery method.
 *
 * @param {object|null} paymentMethod - the selected payment method.
 * @returns {{type: *, paymentMethod: *}} Returns the set payment method action.
 */
export const setPaymentMethod = (paymentMethod) => ({
  type: SET_PAYMENT_METHOD,
  paymentMethod,
});

/**
 * Sets the payconiq payment data
 * @param {object} payconiqData - the payconiq payment data
 * @return {{type: string, payconiqData: *}} The set payconiq payment data action
 */
export const setPayconiqPaymentData = (payconiqData) => ({
  type: SET_PAYCONIQ_PAYMENT_INFO,
  payconiqData,
});

/**
 * Function which gets the payconiq payment status
 * @param {string} cartCode - the current cart code
 * @return {{types: string[], promise: (function(*): *)}} The get payconiq payment status action
 */
export const getPayconiqPaymentStatus = (cartCode) => ({
  types: [GET_PAYCONIQ_PAYMENT_STATUS, GET_PAYCONIQ_PAYMENT_STATUS_SUCCESS, GET_PAYCONIQ_PAYMENT_STATUS_FAIL],
  promise: () =>
    apiClient({
      url: `/payconiq/${platform}/transaction`,
      params: {
        cartCode,
        fields: 'FULL',
      },
      useBaseUrl: true,
    }),
});

/**
 * Function which cancels the payconiq payment
 * @param {string} paymentId - the payconiq payment id
 * @returns {{types: string[], promise: (function(*): *)}} the cancel payconiq payment action
 */
export const cancelPayconiqPayment = (paymentId) => ({
  types: [CANCEL_PAYCONIQ_PAYMENT, CANCEL_PAYCONIQ_PAYMENT_SUCCESS, CANCEL_PAYCONIQ_PAYMENT_FAIL],
  promise: () =>
    apiClient({
      url: `/payconiq/${platform}/cancel`,
      params: {
        paymentId,
      },
      useBaseUrl: true,
    }),
});

/**
 * Function which resets the delivery submit error
 * @returns {{type: string, deliveryError: boolean}} the clear delivery submit error action
 */
export const clearDeliverySubmitError = () => ({
  type: CLEAR_DELIVERY_SUBMIT_ERROR,
  deliveryError: false,
});


export const submitPaymentMethod = (isAnonymous = false, anonymousCartId, paymentMethod, aliasId, cartCode) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : cartCode || 'current';

  let params = {
    code: paymentMethod.code,
    fields: 'FULL',
  };

  if (aliasId) {
    params = { ...params, aliasId };
  }

  return {
    types: [SUBMIT_PAYMENT_METHOD, SUBMIT_PAYMENT_METHOD_SUCCESS, SUBMIT_PAYMENT_METHOD_FAIL],
    promise: () =>
      apiClient({
        url: `/users/${userId}/carts/${cartId}/paymentmodes`,
        method: 'POST',
        params,
      }),
  };
};

/**
 * Adds an guest users email to a cart
 * @param {string} email - the users email
 * @param {boolean} isAnonymous - checks if the user is an anonymous user
 * @param {string} anonymousCartId - the id of the current cart
 * @return {{types: [*,*,*], promise: (function(*))}} the submitGuestLoginEmail action
 */
export const submitGuestLoginEmail = (email, isAnonymous = true, anonymousCartId) => {
  const userId = isAnonymous ? 'anonymous' : null;
  const cartId = isAnonymous ? anonymousCartId : null;
  const formattedEmail = encodeURIComponent(email);

  registerUserEmail(email);

  if (userId !== null && cartId !== null) {
    setSalesForceUser(email);

    return {
      types: [SUBMIT_GUEST_LOGIN_EMAIL, SUBMIT_GUEST_LOGIN_EMAIL_SUCCESS, SUBMIT_GUEST_LOGIN_EMAIL_FAIL],
      promise: () =>
        apiClient({ method: 'PUT', url: `/users/${userId}/carts/${cartId}/email?email=${formattedEmail}&fields=FULL` }),
    };
  }
  return null;
};

/**
 * adds a delivery address to a cart
 * @param {object|undefined} address - the address which has to be added to the cart
 * @param {boolean} isAnonymous - checks if the user is an anonymous user
 * @param {string} anonymousCartId - the id of the current cart
 * @param {boolean} wantsInvoice - checks if the user wants an invoice
 * @param {boolean} minimal - checks if the submit came from the pickup form
 * @param {boolean} withSms - checks if the user wants an sms
 * @param {boolean} reducedMobility - checks is the user wants a reduced mobility zone delivery
 * @returns {{types: [*,*,*], promise: (function(*): (*|Request))}}
 * the submitDeliveryAddressData action
 */
export const submitDeliveryAddressData = (
  address,
  isAnonymous = false,
  anonymousCartId,
  wantsInvoice = false,
  minimal = false,
  withSms = false,
  reducedMobility = false
) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  const invoice = wantsInvoice ? `&withInvoice=${wantsInvoice}` : '';
  const isMinimal = minimal ? `&minimal=${minimal}` : '';
  const sms = withSms ? `&informBySMS=${withSms}` : '';
  const reducedMobilityZone = `&reducedMobilityZone=${reducedMobility}`;

  return {
    types: [SUBMIT_DELIVERY_ADDRESS, SUBMIT_DELIVERY_ADDRESS_SUCCESS, SUBMIT_DELIVERY_ADDRESS_FAIL],
    promise: () =>
      apiClient({
        method: 'POST',
        url: `/users/${userId}/carts/${cartId}/addresses/delivery?fields=FULL${invoice}${isMinimal}${sms}${reducedMobilityZone}`,
        data: address,
      }),
  };
};

export const editDeliveryAddressData = (isAnonymous = false, anonymousCartId, addressId) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  return {
    types: [EDIT_DELIVERY_ADDRESS, EDIT_DELIVERY_ADDRESS_SUCCESS, EDIT_DELIVERY_ADDRESS_FAIL],
    promise: () =>
      apiClient({ method: 'PUT', url: `/users/${userId}/carts/${cartId}/addresses/delivery?addressId=${addressId}` }),
  };
};

/**
 * Function which deletes the current payment address
 * @param {boolean} isAnonymous - the anonymous user flag
 * @param {string} anonymousCartId - the anonymous cart id
 * @returns {{types: [*,*,*], promise: (function(*))}} the delete payment address action
 */
export const clearPaymentAddress = (isAnonymous, anonymousCartId) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  return {
    types: [REMOVE_PAYMENT_ADDRESS, REMOVE_PAYMENT_ADDRESS_SUCCESS, REMOVE_PAYMENT_ADDRESS_FAIL],
    promise: () =>
      apiClient({ method: 'DELETE', url: `/users/${userId}/carts/${cartId}/addresses/payment?fields=FULL` }),
  };
};

/**
 * Submits the order.
 *
 * @param {boolean} isAnonymous - Flag if the user is anonymous.
 * @param {object} cart - The cart object
 * @param {string} device - The device
 * @return {{types: [*,*,*], promise: (function(*))}} the submitOrder action
 */
export const submitOrder = (isAnonymous, cart, device, isWebview = false) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? cart.guid : cart.code;

  return {
    types: [SUBMIT_ORDER, SUBMIT_ORDER_SUCCESS, SUBMIT_ORDER_FAIL],
    promise: () =>
      apiClient({ method: 'POST', url: `/users/${userId}/orders/?cartId=${cartId}&device=${device}&app=${isWebview}` }),
  };
};

/**
 * Function which removes the current payment mode(s) from the cart
 * @param {boolean=} anonymous - the anonymous flag
 * @param {string=} anonymousCartId - the anonymous cart id
 * @param {boolean=} giftCard - the gift card flag
 * @param {string=} cartCode - the cart code
 * @returns {{types: [*,*,*], promise: (function(*): (Request|*))}} the clear gift card action
 */
export const clearPaymentMethod = (anonymous, anonymousCartId, giftCard = false, cartCode) => {
  const userId = anonymous ? 'anonymous' : 'current';
  const cartId = anonymous ? anonymousCartId : cartCode || 'current';

  return {
    types: [REMOVE_PAYMENT_METHOD, REMOVE_PAYMENT_METHOD_SUCCESS, REMOVE_PAYMENT_METHOD_FAIL],
    promise: () =>
      apiClient({
        method: 'POST',
        url: `/users/${userId}/carts/${cartId}/paymentmodes/clear`,
        params: {
          giftCard,
          fields: 'FULL',
        },
      }),
  };
};

/**
 * Function which sets the selected store id
 * @param {string} id - the store id
 * @returns {{type: string, id: *}} the set selected store id action
 */
export const setSelectedStoreId = (id) => ({
  type: SET_SELECTED_STORE_ID,
  id,
});

/**
 * Function which gets the newrelic order by id
 * @param {string} orderId - the order id
 * @return {{types: string[], promise: (function(*): *)}} the get newrelic order action
 */
export const getNewRelicOrder = (orderId) => ({
  types: [GET_NEWRELIC_ORDER, GET_NEWRELIC_ORDER_SUCCESS, GET_NEWRELIC_ORDER_FAIL],
  promise: () => apiClient({ url: `/newrelic/order/${orderId}?fields=FULL` }),
});

/**
 * Function with retrieves the krefel HQ info
 * @param {boolean} isAnonymous - the is anonymous flag
 * @param {string} anonymousCartId - the anonymous cart id
 * @return {{types: string[], promise: (function(*): *)}} the get krefel HQ info action
 */
export const getKrefelHQInfo = (isAnonymous = false, anonymousCartId) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  return {
    types: [GET_HQ_INFO, GET_HQ_INFO_SUCCESS, GET_HQ_INFO_FAIL],
    promise: () =>
      apiClient({ url: `/users/${userId}/carts/${cartId}/deliverymethods/${DELIVERY_METHODS.HQ_PICK_UP}` }),
  };
};

/**
 * Function which sets the payment alias
 * @param {object} alias - the payment alias object
 * @returns {{alias: *, type: string}} the set payment alias action
 */
export const setPaymentAlias = (alias) => ({
  type: SET_PAYMENT_ALIAS,
  alias,
});

/**
 * Function which gets the payconiq payment status
 * @param {string} cartCode - the current cart code
 * @return {{types: string[], promise: (function(*): *)}} The get payconiq payment status action
 */
export const submitBlockedDeliveryEvent = (cartCode) => ({
  types: [SUBMIT_BLOCKED_DELIVERY_EVENT, SUBMIT_BLOCKED_DELIVERY_EVENT_SUCCESS, SUBMIT_BLOCKED_DELIVERY_EVENT_FAIL],
  promise: () =>
    apiClient({
      url: `/newrelic/blockedDelivery/${cartCode}`,
      method: 'POST',
    }),
});

/**
 * Function which gets the payconiq payment status
 * @param {string} cartCode - the current cart code
 * @param {string} errorCode - the current detected error code
 * @return {{types: string[], promise: (function(*): *)}} The get payconiq payment status action
 */
export const submitNewRelicCheckoutError = (errorCode, cartCode) => ({
  types: [SUBMIT_NEWRELIC_CHECKOUT_ERROR, SUBMIT_NEWRELIC_CHECKOUT_ERROR_SUCCESS, SUBMIT_NEWRELIC_CHECKOUT_ERROR_FAIL],
  promise: () => apiClient({ url: `/newrelic/error?cartCode=${cartCode}&errorCode=${errorCode}`, method: 'POST' }),
});

/**
 * Function which submits inform by SMS
 * @param {boolean} anonymous - the anonymous flag
 * @param {string} anonymousCartId - the anonymous cart id
 * @param {boolean} shouldInformCustomerBySMS - the boolean value if customer should informed
 * @return {{types: string[], promise: (function(*): *)}} The get payconiq payment status action
 */
export const submitInformBySMS = (isAnonymous, anonymousCartId, shouldInformCustomerBySMS) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  return {
    types: [SUBMIT_INFORM_BY_SMS, SUBMIT_INFORM_BY_SMS_SUCCESS, SUBMIT_INFORM_BY_SMS_FAIL],
    promise: () =>
      apiClient({
        method: 'POST',
        url: `/users/${userId}/carts/${cartId}/sms`,
        params: {
          informBySMS: shouldInformCustomerBySMS,
          fields: 'FULL',
        },
      }),
  };
};

/**
 * Function which submits inform by SMS
 * @param {boolean} anonymous - the anonymous flag
 * @param {string} anonymousCartId - the anonymous cart id
 * @param {string} deliveryMethodCode - the selected delivery method code to submit
 * @param {object} options - the options to pass
 * @return {{types: string[], promise: (function(*): *)}} The get payconiq payment status action
 */
export const submitDeliveryMethodPickup = (isAnonymous, anonymousCartId, deliveryMethodCode, options) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';
  const optionsQueryArray = [];

  Object?.keys(options)?.forEach((optionKey) => {
    const optionValue = options[optionKey];

    if (optionValue || optionValue >= 0) {
      if (optionKey === 'date') {
        const formattedDate = new Date(optionValue);
        formattedDate.setHours(0, 0, 0, 0);
        optionsQueryArray.push(`${optionKey}=${convertToIso(formattedDate)}`);
      } else {
        optionsQueryArray.push(`${optionKey}=${optionValue}`);
      }
    }
  });

  return {
    types: [
      SUBMIT_DELIVERY_METHOD_PICK_UP,
      SUBMIT_DELIVERY_METHOD_PICK_UP_SUCCESS,
      SUBMIT_DELIVERY_METHOD_PICK_UP_FAIL,
    ],
    promise: () =>
      apiClient({
        url: `/users/${userId}/carts/${cartId}/deliverymethods/${deliveryMethodCode}?${optionsQueryArray ? `&${optionsQueryArray.join('&')}` : ''
          }`,
        method: 'POST',
      }),
  };
};

/**
 * Reducer
 */
const initialState = {
  activeStoreId: null,
  bpostPickupPoints: [],
  cargoVeloDeliverySlots: [],
  deliveryDetailLoading: false,
  deliveryMethods: [],
  deliverySlots: [],
  deliverySubmitErrorMessage: null,
  fetchingPaymentMethods: false,
  hasDeliverySlots: true,
  holidayDescriptions: [],
  isFullPaidGiftcard: false,
  isSubmittingDelivery: false,
  krefelHQInfo: null,
  loadingDeliveryMethods: false,
  mapCenter: null,
  newRelicOrder: null,
  order: null,
  payconiqPaymentData: null,
  paymentMethods: [],
  postalCode: null,
  postLuPickupPoints: [],
  santanderZeroInterestConfiguration: null,
  selectedAddress: null,
  selectedAlias: null,
  selectedDeliveryMethodOptions: null,
  selectedDeliveryMethod: null,
  selectedPaymentMethod: null,
  skipPaymentStep: false,
  storePickupPoints: [],
  submitDeliveryAddressError: false,
  submittingOrder: false,
  dpdPickupPoints: [],
  fedexPickupPoints: [],
  informationForm: null,
  showPaymentDetails: false,
  userInfoAddress: null,
};

/**
 * The checkout reducer.
 *
 * @param {Object} state The default or current state.
 * @param {Object} action The dispatched action.
 * @returns {Object} The updated state.
 */
const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SELECTED_ADDRESS: {
      return {
        ...state,
        selectedAddress: action.address,
      };
    }
    case GET_CITY_INFO_SUCCESS: {
      const { name: geoName, postalCode: geoPostalCode } = action?.result?.data;
      return {
        ...state,
        postalCode: state.postalCode || formatPostalCode(geoName, geoPostalCode),
      };
    }
    case SUBMIT_DELIVERY_METHOD: {
      return {
        ...state,
        isSubmittingDelivery: true,
      };
    }
    case SUBMIT_DELIVERY_METHOD_SUCCESS:
    case SUBMIT_DELIVERY_METHOD_FAIL: {
      if(action.type === SUBMIT_DELIVERY_METHOD_FAIL) {
        setDataFromReduxAction(getErrorMessageFromAction(action));
      }

      return {
        ...state,
        isSubmittingDelivery: false,
      };
    }

    // Reducer for fetching delivery slots
    case GET_DELIVERY_SLOTS: {
      return {
        ...state,
        deliveryDetailLoading: true,
        hasDeliverySlots: true,
      };
    }
    case GET_DELIVERY_SLOTS_FAIL: {
      return {
        ...state,
        deliveryDetailLoading: false,
        hasDeliverySlots: false,
      };
    }
    case GET_DELIVERY_SLOTS_SUCCESS: {
      const { data } = action.result;

      return {
        ...state,
        deliveryDetailLoading: false,
        deliverySlots: data.slots || [],
        hasDeliverySlots: !!data.slots?.length,
        holidayDescriptions: data.holidayDescriptions || [],
        storePickupPoints: data.pointOfServices || [],
        bpostPickupPoints: data.poiList || [],
        postLuPickupPoints: data.points || [],
        mapCenter: data.geoPoint || null,
        hqPickupPoint: (data?.pointOfService && data) || null,
        deliveryMethodOptions: data.liftDeliveryConfiguration || null,
        dpdPickupPoints: data?.parcelShop || [],
        fedexPickupPoints: data?.fedexLocations || [],
      };
    }

    case GET_SANTANDER_CONFIGURATION_SUCCESS:
      return {
        ...state,
        santanderZeroInterestConfiguration: action.result.data,
      };
    case SET_DELIVERY_METHOD:
      return {
        ...state,
        selectedDeliveryMethod: action.deliveryMethod,
        selectedDeliveryMethodOptions: null,
      };
    case SET_DELIVERY_METHOD_OPTIONS:
      return {
        ...state,
        selectedDeliveryMethodOptions: action.options,
      };
    case GET_DELIVERY_METHODS:
      return {
        ...state,
        postalCode: action.postalCode,
        selectedDeliveryMethod: null,
        selectedDeliveryMethodOptions: null,
        loadingDeliveryMethods: true,
      };
    case GET_DELIVERY_METHODS_SUCCESS:
      return {
        ...state,
        deliveryMethods: (action.result.data && action.result.data.deliveryModes) || [],
        loadingDeliveryMethods: false,
      };
    case GET_DELIVERY_METHODS_FAIL:
      setDataFromReduxAction(getErrorMessageFromAction(action));

      return {
        ...state,
        loadingDeliveryMethods: false,
      };
    case GET_BPOST_PICKUP_POINT_SUCCESS: {
      if (action.result.data.record) {
        const updatedBpostPickupPoints = [...state.bpostPickupPoints];
        const foundIndex = updatedBpostPickupPoints.findIndex(
          (wrapper) => wrapper.record.id === action.result.data.record.id
        );

        if (foundIndex !== null && foundIndex !== undefined) {
          updatedBpostPickupPoints[foundIndex] = {
            ...updatedBpostPickupPoints[foundIndex],
            ...action.result.data,
          };

          return {
            ...state,
            bpostPickupPoints: updatedBpostPickupPoints,
          };
        }
      }
      return state;
    }
    case GET_CARGOVELO_DELIVERY_SLOTS_SUCCESS:
      return {
        ...state,
        cargoVeloDeliverySlots: action.result.data.slots || [],
        deliveryDetailLoading: false,
      };

    case GET_PAYMENT_METHODS: {
      return {
        ...state,
        fetchingPaymentMethods: true,
      };
    }
    case GET_PAYMENT_METHODS_FAIL: {
      setDataFromReduxAction(getErrorMessageFromAction(action));

      return {
        ...state,
        fetchingPaymentMethods: false,
        skipPaymentStep: false,
      };
    }
    case GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        fetchingPaymentMethods: false,
        paymentMethods: action.result.data.paymentModes || [],
        skipPaymentStep: action.result.data.skipPaymentStep || false,
      };
    case SET_PAYMENT_METHOD: {
      return {
        ...state,
        selectedPaymentMethod: action.paymentMethod,
      };
    }
    case CLEAR_CART: {
      return {
        ...initialState,
        order: state.order ? { ...state.order } : null,
      };
    }
    case GET_CONFIRMED_ORDER_SUCCESS:
      return {
        ...state,
        order: action?.result?.data,
      };
    case ADD_PRODUCT_SUCCESS:
    case DELETE_ENTRY_SUCCESS:
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...initialState,
        postalCode: state.postalCode,
      };
    case SUBMIT_DELIVERY_ADDRESS:
    case EDIT_DELIVERY_ADDRESS:
    case REMOVE_PAYMENT_ADDRESS:
      return {
        ...state,
        submitDeliveryAddressError: false,
      };
    case SUBMIT_DELIVERY_ADDRESS_SUCCESS:
    case EDIT_DELIVERY_ADDRESS_SUCCESS:
    case REMOVE_PAYMENT_ADDRESS_SUCCESS:
      return {
        ...state,
        submitDeliveryAddressError: false,
      };
    case SUBMIT_DELIVERY_ADDRESS_FAIL:
    case EDIT_DELIVERY_ADDRESS_FAIL:
    case REMOVE_PAYMENT_ADDRESS_FAIL:
      if (action.type === SUBMIT_DELIVERY_ADDRESS_FAIL || action.type === REMOVE_PAYMENT_ADDRESS_FAIL) {
          setDataFromReduxAction(getErrorMessageFromAction(action));
      }

      return {
        ...state,
        submitDeliveryAddressError: true,
        deliverySubmitErrorMessage: action.error?.response?.data,
      };
    case CLEAR_DELIVERY_SUBMIT_ERROR:
      return {
        ...state,
        submitDeliveryAddressError: action.deliveryError,
        deliverySubmitErrorMessage: null,
      };
    case SUBMIT_ORDER: {
      return {
        ...state,
        submittingOrder: true,
      };
    }
    case SUBMIT_ORDER_SUCCESS: {
      return {
        ...state,
        submittingOrder: false,
      };
    }
    case SUBMIT_ORDER_FAIL: {
      setDataFromReduxAction(getErrorMessageFromAction(action));
      return {
        ...state,
        submittingOrder: false,
      };
    }
    case SET_SELECTED_STORE_ID: {
      return {
        ...state,
        activeStoreId: action && action.id,
      };
    }
    case GET_NEWRELIC_ORDER_SUCCESS: {
      return {
        ...state,
        newRelicOrder: action && action.result && action.result.data,
      };
    }
    case GET_HQ_INFO_SUCCESS: {
      return {
        ...state,
        krefelHQInfo: action && action.result && action.result.data,
        deliveryDetailLoading: false,
      };
    }
    case SET_PAYCONIQ_PAYMENT_INFO: {
      return {
        ...state,
        payconiqPaymentData: action && action.payconiqData,
      };
    }
    case SET_PAYMENT_ALIAS: {
      return {
        ...state,
        selectedAlias: (action && action.alias) || null,
      };
    }
    case SET_FULL_PAID_GIFTCARD: {
      return {
        ...state,
        isFullPaidGiftcard: action?.value,
      };
    }
    case SET_INFORMATION_FORM: {
      return {
        ...state,
        informationForm: action.informationForm,
      };
    }
    case SET_SHOW_PAYMENT_DETAILS: {
      return {
        ...state,
        showPaymentDetails: action.showPaymentDetails,
      };
    }
    case SET_USER_INFO_ADDRESS: {
      return {
        ...state,
        userInfoAddress: action.userInfoAddress,
      };
    }
    case CLEAR_NEW_CHECKOUT_INFO: {
      return {
        ...initialState,
      };
    }
    case DELETE_DELIVERY_METHOD_FAIL:
    case GET_CONFIRMED_ORDER_FAIL:
    case REMOVE_PAYMENT_METHOD_FAIL:
    case SUBMIT_GUEST_LOGIN_EMAIL_FAIL:
    case SUBMIT_PAYMENT_METHOD_FAIL: {
      setDataFromReduxAction(getErrorMessageFromAction(action));
      break;
    }
    default:
      return state;
  }
};

export default reducer;
