type Environment = 'prd' | 'stg' | 'dev' | 'local';

const CURRENT_ENVIRONMENT = (process.env.NEXT_PUBLIC_ENVIRONMENT || 'prd') as Environment;
export const isVercel = process.env.NEXT_PUBLIC_IS_VERCEL || '';

export const isWebview = process.env.NEXT_PUBLIC_IS_WEBVIEW?.toLowerCase() === 'true';

export const isProductionEnv = CURRENT_ENVIRONMENT === 'prd';
export const isStagingEnv = CURRENT_ENVIRONMENT === 'stg';
export const isDevelopEnv = CURRENT_ENVIRONMENT === 'dev';
export const isLocalEnv = CURRENT_ENVIRONMENT === 'local';

const SKIP_BUILD_STATIC_GENERATION = process.env.SKIP_BUILD_STATIC_GENERATION === 'true';

export const shouldPrerenderAllPaths = !SKIP_BUILD_STATIC_GENERATION && (isProductionEnv || isStagingEnv);
