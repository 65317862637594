/**
 * The number of page results types.
 * @type {{TOTAL_RESULTS_1: number, TOTAL_RESULTS_2: number, TOTAL_RESULTS_3: number}}
 */
export const NUMBER_OF_PAGE_RESULTS = {
  TOTAL_RESULTS_1: 24,
  TOTAL_RESULTS_2: 48,
  TOTAL_RESULTS_3: 96,
};

export const ALL_NUMBER_OF_PAGE_RESULTS = Object.values(NUMBER_OF_PAGE_RESULTS);

export const DEFAULT_QUERY = ':relevance';

export const FACET_CODE = {
  CATEGORY: 'category',
  GROUPED_FACET: 'groupedProperty',
  PRICE: 'genericpricerange',
  REVIEW: 'averageReviewRating',
  STORES: 'storeAvailability',
};

export const FACET_FILTER_CODE = {
  RANGE: 'priceValue',
};

export const FACET_FILTER_DESCRIPTOR = {
  COLOR: 'COLOR',
  GROUPED: 'GROUPED',
  INPUT: 'INPUT',
  PRICE: 'PRICE',
  REVIEW: 'REVIEW',
};

export const SEARCH_LIMIT = 100;
