/**
 * File that contains the localized routes.
 * TODO (01/09/2021): Remove when possible
 * ALL NEW PATHS GO TO -> i18n/pathnames.js where you can map new localized routing
 * which is more in line with the wokring of next/router
 */

import { LocalizedSlugs } from './pathnames';

const routes: Record<string, LocalizedSlugs> = {
  abandonedCart: {
    fr: 'abandonedcart',
    nl: 'abandonedcart',
  },
  brandCategoryPage: {
    fr: 'marques/:code/:qualifier',
    nl: 'merken/:code/:qualifier',
  },
  brandDetail: {
    fr: 'marques/:code',
    nl: 'merken/:code',
  },
  brandProductPage: {
    fr: 'marques/:code/tous-produits',
    nl: 'merken/:code/alle-producten',
  },
  brandsOverview: {
    fr: 'marques',
    nl: 'merken',
  },
  budgetCard: {
    fr: 'budget-card',
    nl: 'budget-card',
  },
  budgetCardStatus: {
    fr: 'santander/:status',
    nl: 'santander/:status',
  },
  cart: {
    fr: 'panier',
    nl: 'winkelmandje',
  },
  categoryCodeL2: {
    fr: 'c/:categoryCodes/:l1Slug/:l2Slug',
    nl: 'c/:categoryCodes/:l1Slug/:l2Slug',
  },
  categoryCodeL3: {
    fr: 'c/:categoryCodes/:l1Slug/:l2Slug/:l3Slug',
    nl: 'c/:categoryCodes/:l1Slug/:l2Slug/:l3Slug',
  },
  categoryCodeL4: {
    fr: 'c/:categoryCodes/:l1Slug/:l2Slug/:l3Slug/:l4Slug',
    nl: 'c/:categoryCodes/:l1Slug/:l2Slug/:l3Slug/:l4Slug',
  },
  categoryL2: {
    fr: 'c/:l1Slug/:l2Slug',
    nl: 'c/:l1Slug/:l2Slug',
  },
  categoryL3: {
    fr: 'c/:l1Slug/:l2Slug/:l3Slug',
    nl: 'c/:l1Slug/:l2Slug/:l3Slug',
  },
  categoryL4: {
    fr: 'c/:l1Slug/:l2Slug/:l3Slug/:l4Slug',
    nl: 'c/:l1Slug/:l2Slug/:l3Slug/:l4Slug',
  },
  checkoutConfirmation: {
    fr: 'commander/confirmation',
    nl: 'bestellen/bevestiging',
  },
  checkoutLogin: {
    fr: 'commander/login',
    nl: 'bestellen/login',
  },
  cmsPage: {
    fr: ':slug',
    nl: ':slug',
  },
  contact: {
    fr: 'contact',
    nl: 'contact',
  },
  contentful: {
    fr: 'contentful/:pageId',
    nl: 'contentful/:pageId',
  },
  contentfulPreview: {
    fr: 'contentful-preview/:slug',
    nl: 'contentful-preview/:slug',
  },
  deliveryForm: {
    fr: 'commander/vos-coordonnees',
    nl: 'bestellen/gegevens',
  },
  deliveryService: {
    fr: 'livraisons',
    nl: 'leveringen',
  },
  demoDays: {
    fr: 'demodays',
    nl: 'demodays',
  },
  eVoucher: {
    fr: 'evoucher',
    nl: 'evoucher',
  },
  giftCard: {
    fr: 'giftcard',
    nl: 'giftcard',
  },
  giftCardActivation: {
    fr: 'activation',
    nl: 'activatie',
  },
  inStoreProductCompare: {
    fr: 'instore/comparer',
    nl: 'instore/vergelijken',
  },
  inStoreProductDetail: {
    fr: 'instore/p/:slug',
    nl: 'instore/p/:slug',
  },
  index: {
    fr: '',
    nl: '',
  },
  login: {
    fr: 'login',
    nl: 'login',
  },
  offlineDeliveryService: {
    fr: 'livraisons-magazins',
    nl: 'leveringen-winkels',
  },
  onOrderConfigurationConfirmation: {
    fr: 'configurator/confirmation/:code',
    nl: 'configurator/bevestiging/:code',
  },
  onOrderConfigurationDelivery: {
    fr: 'configurator/:code',
    nl: 'configurator/:code',
  },
  onOrderConfigurationDeliveryForm: {
    fr: 'configurator/vos-coordonnees/:code',
    nl: 'configurator/gegevens/:code',
  },
  onOrderConfigurationOverview: {
    fr: 'configurator/controle/:code',
    nl: 'configurator/controle/:code',
  },
  onOrderConfirmation: {
    fr: 'onorder/confirmation/:code',
    nl: 'onorder/bevestiging/:code',
  },
  onOrderDelivery: {
    fr: 'onorder/:code',
    nl: 'onorder/:code',
  },
  onOrderOverview: {
    fr: 'onorder/controle/:code',
    nl: 'onorder/controle/:code',
  },
  onlineDeliveryService: {
    fr: 'livraisons-online',
    nl: 'leveringen-online',
  },
  orderOverview: {
    fr: 'commander/controle',
    nl: 'bestellen/controle',
  },
  partService: {
    fr: 'pieces-de-detachees',
    nl: 'wisselstukken',
  },
  passwordForgotten: {
    fr: 'password-forgotten/:token',
    nl: 'password-forgotten/:token',
  },
  payment: {
    fr: 'commander/paiement',
    nl: 'bestellen/betaling',
  },
  pcConfigurator: {
    fr: 'configurateur',
    nl: 'configurator',
  },
  preOrderConfirmation: {
    fr: 'preorder/confirmation/:code',
    nl: 'preorder/bevestiging/:code',
  },
  preOrderDelivery: {
    fr: 'preorder/:code',
    nl: 'preorder/:code',
  },
  preOrderOverview: {
    fr: 'preorder/controle/:code',
    nl: 'preorder/controle/:code',
  },
  productCompare: {
    fr: 'comparer',
    nl: 'vergelijken',
  },
  productDetail: {
    fr: 'p/:slug',
    nl: 'p/:slug',
  },
  repairService: {
    fr: 'reparations',
    nl: 'reparaties',
  },
  returnProductOverview: {
    fr: 'devis-reparation/:ticketNumber/:postalCode',
    nl: 'herstel-offerte/:ticketNumber/:postalCode',
  },
  returnProductRequest: {
    fr: 'devis-reparation',
    nl: 'herstel-offerte',
  },
  saferPayPayment: {
    fr: 'saferpay/krefel/payment/:status/:code/:locale',
    nl: 'saferpay/krefel/payment/:status/:code/:locale',
  },
  saferpayAliasReturnPage: {
    fr: 'saferpay/krefel/alias/:status/:locale/:aliasId',
    nl: 'saferpay/krefel/alias/:status/:locale/:aliasId',
  },
  search: {
    fr: 'search',
    nl: 'search',
  },
  sharedWishList: {
    fr: 'sharedlist',
    nl: 'sharedlist',
  },
  storeDetail: {
    fr: 'magasins/:storeId',
    nl: 'winkels/:storeId',
  },
  storeFinder: {
    fr: 'magasins',
    nl: 'winkels',
  },
  suppliers: {
    fr: 'fournisseurs',
    nl: 'leveranciers',
  },
  suppliersForm: {
    fr: 'fournisseurs/billet-de-caisse',
    nl: 'leveranciers/kassaticket',
  },
  wishList: {
    fr: 'listes/:id',
    nl: 'lijstjes/:id',
  },
  wishLists: {
    fr: 'listes',
    nl: 'lijstjes',
  },
};

export default routes;
