export const removeWhiteSpaces = (copy: string): string => copy?.replace(/\s+/g, ' ')?.trim();

export const uppercaseFirstLetter = (copy: string): string => {
  if (!copy) {
    return '';
  }

  return `${copy?.charAt(0).toUpperCase()}${copy?.slice(1)}`;
};

export const replaceDashes = (copy: string, value: string = ' '): string => copy?.replace(/-/g, value);

export const replaceSlashes = (copy: string, value: string = ' '): string => copy?.replace(/\//g, value);

export const normalizePath = (copy: string): string =>
  copy ? uppercaseFirstLetter(removeWhiteSpaces(replaceDashes(replaceSlashes(copy)))) : '';

export const createTitle = (copy: string): string => (copy ? uppercaseFirstLetter(copy?.replace('-', ' ')) : '');

export const hasValue = (copy: string | number): boolean => !!copy;

export const isNumericValue = (copy: string | number): boolean => hasValue(copy) || copy === 0 || copy === '0';

export const isAuto = (copy: string): boolean => copy === 'auto';

export const handleStylingValue = (val: number | string): string => {
  if (typeof val === 'number') {
    return `${val}px`;
  }

  return val;
};

export const removeTrailingSlash = (value: string): string => (value?.endsWith('/') ? value.slice(0, -1) : value);

export const isBooleanStringValue = (copy: string): boolean => copy === 'false' || copy === 'true';

export const suffixMatchesEnd = (value: string, suffix: string): boolean =>
  value?.indexOf(suffix, value?.length - suffix?.length) !== -1;

export const removeSuffixFromValue = (value: string, suffix: string): string => value?.slice(0, suffix?.length * -1);

export const addZero = (number: number, amount: number = 2, resetOnZero: boolean = false): string => {
  if (number === 0 && resetOnZero) return '0';

  return String(number).padStart(amount, '0');
};

export const onlySpaces = (str: string): boolean => str.trim().length === 0;
