import { Platform } from 'types/Platform';

export const BRAND_VALUES: { [key: string]: Platform } = {
  HIFI: 'hifi',
  KREFEL: 'krefel',
  TONES: 'tones',
} as const;

export const CURRENT_BRAND = (process.env.NEXT_PUBLIC_PLATFORM || BRAND_VALUES.KREFEL) as Platform;

export const IS_KREFEL = CURRENT_BRAND === BRAND_VALUES.KREFEL;
export const IS_HIFI = CURRENT_BRAND === BRAND_VALUES.HIFI;
export const IS_TONES = CURRENT_BRAND === BRAND_VALUES.TONES;
