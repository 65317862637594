import { css } from 'styled-components';
import { brandSelect, color } from '../../functions';

const defaultValues = {
  'category-slogan-color': css`
    ${color('primary-300')};
  `,
};

const krefel = {};

const hifi = {
  'category-slogan-color': css`
    ${color('secondary-300')};
  `,
};

const tones = {};

const brandSpecifics = {
  krefel,
  hifi,
  tones,
};

const categorySpecifics = {
  ...defaultValues,
  ...brandSelect(brandSpecifics),
};

export default categorySpecifics;
