import { keepPreviousData, useQuery } from '@tanstack/react-query';
import useRouter from 'hooks/useRouter';
import { configurationKeys } from '.';
import { fetchWebConfig } from './connector';

const useWebConfig = (enabled = true) => {
  const { locale } = useRouter();
  return useQuery({
    enabled: !!enabled,
    placeholderData: keepPreviousData,
    queryFn: () => fetchWebConfig(locale),
    queryKey: configurationKeys.webConfig(locale),
  });
};

export { useWebConfig };
