/* eslint-disable sort-keys-fix/sort-keys-fix */
import brand from './brandSpecifics';
import button from './buttonSpecifics';
import cart from './cartSpecifics';
import category from './categorySpecifics';
import cms from './cmsSpecifics';
import compare from './compareSpecifics';
import contentful from './contentfulSpecifics';
import cookiePolicy from './cookiePolicySpecifics';
import dropdown from './dropdownSpecifics';
import facet from './facetSpecifics';
import generic from './genericSpecifics';
import header from './headerSpecifics';
import icon from './iconSpecifics';
import link from './linkSpecifics';
import mainbar from './mainbarSpecifics';
import menu from './menuSpecifics';
import product from './productSpecifics';
import slider from './sliderSpecifics';
import topbar from './topbarSpecifics';

const specifics = {
  link,
  button,
  cms,
  contentful,
  cookiePolicy,
  header,
  icon,
  product,
  compare,
  generic,
  cart,
  brand,
  facet,
  dropdown,
  category,
  mainbar,
  topbar,
  menu,
  slider,
};

export default specifics;
