import { Pathname, pathnames } from 'i18n/pathnames';

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const ANALYTIC_EVENTS = {
  ADD_TO_WISHLIST: 'addToWishlist',
  ADD_TO_CART: 'add-to-cart-click',
  ADD_TO_CART_PLP: 'add-to-cart-click-plp',
  REMOVE_FROM_CART: 'remove-from-cart-click',
  PRODUCT_CLICK: 'product-click',
  CHECKOUT_CLICK: 'checkout-click',
  CHECKOUT_CART_CLICK: 'checkout-cart-clickclick',
  PURCHASE: 'purchase',
  CHECKOUT: 'checkout',
  VIRTUAL_PAGE_VIEW: 'virtual_page_view',
  PRODUCT_IMPRESSION: 'product-impression',
  CRTO_LISTINGPAGE: 'crto_listingpage',
  CRTO_BASKETPAGE: 'crto_basketpage',
  CRTO_PRODUCTPAGE: 'crto_productpage',
  CHECKOUT_CART_IMPRESSION: 'checkout-cart-impression',
  PRODUCT_DETAIL_IMPRESSION: 'product-detail-impression',
  PURCHASE_IMPRESSION: 'purchase-impression',
  PAGE_VIEW: 'page_view',
} as const;

export type AnalyticEvent = (typeof ANALYTIC_EVENTS)[keyof typeof ANALYTIC_EVENTS];

export const ANALYTIC_CODES = {
  GIFTCARD: 'GIFTCARD',
};

export const ANALYTIC_CATEGORIES = {
  GIFTCARD: 'Gift cards',
  ONLINE_STORE: 'Online Store',
};

export const ANALYTIC_CURRENCIES = {
  EUR: 'EUR',
};

export const ANALYTICS_PAGE_TYPES = {
  HOME: 'homePage',
  PDP: 'productPage',
  CONTENTFUL: 'contentfulPage',
  CONTENTFUL_PREVIEW: 'contentfulPreviewPage',
  CMS: 'cmsPage',
  CMS_SEARCH: 'cmsSearchPage',
  CATEGORY: 'categoryPage',
} as const;
export type AnalyticsPageType = (typeof ANALYTICS_PAGE_TYPES)[keyof typeof ANALYTICS_PAGE_TYPES];

export const ANALYTICS_PAGE_TYPES_FROM_PATHNAMES: Partial<Record<Pathname, AnalyticsPageType>> = {
  [pathnames.INDEX]: ANALYTICS_PAGE_TYPES.HOME,
  [pathnames.PRODUCT]: ANALYTICS_PAGE_TYPES.PDP,
  [pathnames.CONTENTFUL_PREVIEW]: ANALYTICS_PAGE_TYPES.CONTENTFUL_PREVIEW,
  [pathnames.CMSPAGE]: ANALYTICS_PAGE_TYPES.CMS,
  [pathnames.SEARCH]: ANALYTICS_PAGE_TYPES.CMS_SEARCH,
  [pathnames.CATEGORY]: ANALYTICS_PAGE_TYPES.CATEGORY,
};

export const PIANO_PAGE_TYPES = {
  HOME: 'homepage',
  PLP: 'product_list_page',
  MENU: 'menu_page',
  PDP: 'product_page',
  SEARCH: 'search_page',
  CART: 'basket',
  CHECKOUT: 'checkout',
  ORDER_CONFIRMATION: 'order_confirmation',
  LOGIN: 'login',
  REGISTER: 'account_creation',
  MY_ACCOUNT: 'my_account',
  STORE_LOCATOR: 'store_locator',
  ERROR: 'error',
  CMS: 'content',
} as const;

export type PianoPageType = (typeof PIANO_PAGE_TYPES)[keyof typeof PIANO_PAGE_TYPES];

export const PIANO_PAGE_TYPES_FROM_PATHNAMES: Partial<Record<Pathname, PianoPageType>> = {
  [pathnames.INDEX]: PIANO_PAGE_TYPES.HOME,
  [pathnames.PRODUCT]: PIANO_PAGE_TYPES.PDP,
  [pathnames.CONTENTFUL_PREVIEW]: PIANO_PAGE_TYPES.CMS,
  [pathnames.CMSPAGE]: PIANO_PAGE_TYPES.CMS,
  [pathnames.SEARCH]: PIANO_PAGE_TYPES.SEARCH,
  [pathnames.CATEGORY]: PIANO_PAGE_TYPES.PLP,
  [pathnames.STOREFINDER]: PIANO_PAGE_TYPES.STORE_LOCATOR,
  [pathnames.STOREFINDER_DETAIL]: PIANO_PAGE_TYPES.STORE_LOCATOR,
  [pathnames.CHECKOUT_DELIVERY]: PIANO_PAGE_TYPES.CHECKOUT,
  [pathnames.CHECKOUT_SHIPPING]: PIANO_PAGE_TYPES.CHECKOUT,
  [pathnames.CHECKOUT_PAYMENT]: PIANO_PAGE_TYPES.CHECKOUT,
  [pathnames.CHECKOUT_PAYMENT_REMAINING]: PIANO_PAGE_TYPES.CHECKOUT,
  [pathnames.CHECKOUT_OVERVIEW]: PIANO_PAGE_TYPES.CHECKOUT,
  [pathnames.CHECKOUT_CONFIRMATION]: PIANO_PAGE_TYPES.ORDER_CONFIRMATION,
  [pathnames.CHECKOUT_LOGIN]: PIANO_PAGE_TYPES.LOGIN,
  [pathnames.CHECKOUT_REGISTER]: PIANO_PAGE_TYPES.REGISTER,
  [pathnames.CART]: PIANO_PAGE_TYPES.CART,
  [pathnames.ERROR404]: PIANO_PAGE_TYPES.ERROR,
  [pathnames.ERROR500]: PIANO_PAGE_TYPES.ERROR,
  [pathnames.LOGIN]: PIANO_PAGE_TYPES.LOGIN,
  [pathnames.REGISTER]: PIANO_PAGE_TYPES.REGISTER,
  [pathnames.MY_ACCOUNT]: PIANO_PAGE_TYPES.MY_ACCOUNT,
};

export const PIANO_PAGE_NAMES = {
  HOME: 'homepage',
  MENU: 'menu_page',
  PLP: 'list_page',
  SEARCH: 'search_result',
  PDP: 'product_page',
  CART: 'basket',
  CHECKOUT: 'checkout',
  CHECKOUT_LOGIN: 'login_checkout',
  CHECKOUT_ADDRESS: 'adress_checkout',
  CHECKOUT_DELIVERY: 'delivery_page',
  CHECKOUT_PAYMENT: 'payment_page',
  CHECKOUT_OVERVIEW: 'overview_page',
  CHECKOUT_REGISTER: 'account_creation_checkout',
  ORDER_CONFIRMATION: 'order_confirmation',
  LOGIN: 'login',
  REGISTER: 'account_creation',
  MY_ACCOUNT: 'my_account',
  STORE_LOCATOR_HOME: 'store_locator_homepage',
  STORE_LOCATOR: 'store_locator',
  ERROR: 'error',
  CMS: 'content',
} as const;

export type PianoPageName = (typeof PIANO_PAGE_NAMES)[keyof typeof PIANO_PAGE_NAMES];

export const PIANO_PAGE_NAMES_FROM_PATHNAMES: Partial<Record<Pathname, PianoPageName>> = {
  [pathnames.INDEX]: PIANO_PAGE_NAMES.HOME,
  [pathnames.PRODUCT]: PIANO_PAGE_NAMES.PDP,
  [pathnames.CONTENTFUL_PREVIEW]: PIANO_PAGE_NAMES.CMS,
  [pathnames.CMSPAGE]: PIANO_PAGE_NAMES.CMS,
  [pathnames.SEARCH]: PIANO_PAGE_NAMES.SEARCH,
  [pathnames.CATEGORY]: PIANO_PAGE_NAMES.PLP,
  [pathnames.STOREFINDER]: PIANO_PAGE_NAMES.STORE_LOCATOR_HOME,
  [pathnames.STOREFINDER_DETAIL]: PIANO_PAGE_NAMES.STORE_LOCATOR,
  [pathnames.CHECKOUT_DELIVERY]: PIANO_PAGE_NAMES.CHECKOUT_DELIVERY,
  [pathnames.CHECKOUT_SHIPPING]: PIANO_PAGE_NAMES.CHECKOUT_ADDRESS,
  [pathnames.CHECKOUT_OVERVIEW]: PIANO_PAGE_NAMES.CHECKOUT_OVERVIEW,
  [pathnames.CHECKOUT_PAYMENT]: PIANO_PAGE_NAMES.CHECKOUT_PAYMENT,
  [pathnames.CHECKOUT_PAYMENT_REMAINING]: PIANO_PAGE_NAMES.CHECKOUT_PAYMENT,
  [pathnames.CHECKOUT_CONFIRMATION]: PIANO_PAGE_NAMES.ORDER_CONFIRMATION,
  [pathnames.CHECKOUT_LOGIN]: PIANO_PAGE_NAMES.CHECKOUT_LOGIN,
  [pathnames.CHECKOUT_REGISTER]: PIANO_PAGE_NAMES.CHECKOUT_REGISTER,
  [pathnames.CART]: PIANO_PAGE_NAMES.CART,
  [pathnames.ERROR404]: PIANO_PAGE_NAMES.ERROR,
  [pathnames.ERROR500]: PIANO_PAGE_NAMES.ERROR,
  [pathnames.LOGIN]: PIANO_PAGE_NAMES.LOGIN,
  [pathnames.REGISTER]: PIANO_PAGE_NAMES.REGISTER,
  [pathnames.MY_ACCOUNT]: PIANO_PAGE_NAMES.MY_ACCOUNT,
};

export const ADVANCED_PAGEVIEW_PAGES = [
  pathnames.PRODUCT,
  pathnames.CATEGORY,
  pathnames.STOREFINDER,
  pathnames.STOREFINDER_DETAIL,
  pathnames.CMSPAGE,
  pathnames.REGISTER,
];
