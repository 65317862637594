export const APP_EVENT_MESSAGES = {
  AUTHENTICATE: 'AUTHENTICATE',
  CART_UPDATED: 'CART_UPDATED',
  DELIVERY: 'DELIVERY',
  GO_TO_CONTACT: 'GO_TO_CONTACT',
  GO_TO_HOME: 'GO_TO_HOME',
  GO_TO_PASSWORD_FORGOTTEN: 'GO_TO_PASSWORD_FORGOTTEN',
  GO_TO_SEARCH: 'GO_TO_SEARCH',
  GO_TO_SHOP: 'GO_TO_SHOP',
  OPEN_CATEGORY: 'OPEN_CATEGORY',
  OPEN_ORDER_DETAIL: 'OPEN_ORDER_DETAIL',
  OPEN_ORDERS: 'OPEN_ORDERS',
  OPEN_PRODUCT: 'OPEN_PRODUCT',
  ORDER_DONE: 'ORDER_DONE',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  REGISTER: 'REGISTER',
  REQUEST_MISSING_USER_TOKEN: 'REQUEST_MISSING_USER_TOKEN',
  REQUEST_TOKEN: 'REQUEST_TOKEN',
  START: 'START',
} as const;
