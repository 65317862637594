import { Position } from 'constants/styling';
import { css } from 'styled-components';
import { handleStylingValue } from '../../utils/textUtil';

export interface PositionProps {
  bottom?: string | number;
  left?: string | number;
  position?: Position;
  right?: string | number;
  top?: string | number;
}

export const positionMapper = css<PositionProps>`
  position: ${({ position }) => position};
  top: ${({ top }) => top && handleStylingValue(top)};
  right: ${({ right }) => right && handleStylingValue(right)};
  bottom: ${({ bottom }) => bottom && handleStylingValue(bottom)};
  left: ${({ left }) => left && handleStylingValue(left)};
`;
