import config from 'config';
import { apiClient } from 'helpers/ApiClient';
import { TranslationKeys } from 'types/I18n';
import { logErrorResponse } from 'utils/loggerUtil';

const { platform } = config;

export const fetchTranslations = async (groups: string[], locale: string) => {
  try {
    const { data } = await apiClient<TranslationKeys>({
      params: {
        groups,
        lang: locale,
      },
      url: `translations/${platform}/by-group`,
      useBaseUrl: true,
    });

    return data;
  } catch (error) {
    logErrorResponse('fetchTranslations', error, 'Error fetching translations');
    throw error;
  }
};
