const getFieldId = (formId: string, fieldName: string) => `${formId}_${fieldName}`;

export const LAYOUT_STATES = {
  DETAIL: 'detail',
  NO_RESULTS: 'no results',
  SEARCH: 'search',
  SEARCH_RESULT: 'searchResult',
} as const;
export type LayoutState = (typeof LAYOUT_STATES)[keyof typeof LAYOUT_STATES];

export const COUNTRY_CODES = {
  BE: 'BE',
  DE: 'DE',
  FR: 'FR',
  LU: 'LU',
  NL: 'NL',
} as const;

export type CountryCode = (typeof COUNTRY_CODES)[keyof typeof COUNTRY_CODES];

export const WEEK_DAYS: Record<string, string> = {
  FRIDAY: 'general_days_friday',
  MONDAY: 'general_days_monday',
  SATURDAY: 'general_days_saturday',
  SUNDAY: 'general_days_sunday',
  THURSDAY: 'general_days_thursday',
  TUESDAY: 'general_days_tuesday',
  WEDNESDAY: 'general_days_wednesday',
};

export const OPENING_TEXT_SCHEDULE_TYPES = {
  DEFAULT: 'default',
  SPECIAL_OPENING: 'specialopening',
  STORE_FINDER: 'storefinder',
} as const;

export type OpeningTextScheduleType = (typeof OPENING_TEXT_SCHEDULE_TYPES)[keyof typeof OPENING_TEXT_SCHEDULE_TYPES];

export const STORE_NO_RESULTS_CHECKLIST_LABELS = [
  'store_finder_search_no_results_tip_1',
  'store_finder_search_no_results_tip_2',
  'store_finder_search_no_results_tip_3',
  'store_finder_search_no_results_tip_4',
];

export const STORE_FINDER_SEARCH_FORM_ID = 'finder-form';

export const STORE_FINDER_SEARCH_FORM_FIELDS = {
  LOCATION: { id: getFieldId(STORE_FINDER_SEARCH_FORM_ID, 'location'), name: 'location' },
  OPEN: { id: getFieldId(STORE_FINDER_SEARCH_FORM_ID, 'open'), label: 'store_finder_search_open_now', name: 'open' },
  OPEN_SUNDAY: {
    id: getFieldId(STORE_FINDER_SEARCH_FORM_ID, 'openSunday'),
    label: 'store_finder_search_open_sunday',
    name: 'openSunday',
  },
};
