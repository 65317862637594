import { CONSENT_GROUPS } from 'constants/cact';
import { ConsentResult, WebviewConsentResult } from 'types/Cact';

export const mapWebviewConsent = (webviewConsent?: WebviewConsentResult): ConsentResult => {
  let status: ConsentResult['consent']['status'] = 'all-on';
  let onCount = 0;
  const categories = Object.values(CONSENT_GROUPS).reduce(
    (acc, key) => {
      const categoryStatus = webviewConsent?.categories.includes(key) ? 'on' : 'off';
      if (categoryStatus === 'off') {
        status = 'mixed';
      }
      if (categoryStatus === 'on') {
        onCount += 1;
      }
      acc[key] = {
        status: categoryStatus,
      };
      return acc;
    },
    {} as ConsentResult['consent']['categories'],
  );

  if (!webviewConsent) {
    status = 'unset';
  }

  if (onCount === 0) {
    status = 'all-off';
  }

  return {
    consent: {
      categories,
      status: status,
    },
  };
};
