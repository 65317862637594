const typeScaleDefault = 1;

const fontSize = {
  fontSize: {
    75: `${typeScaleDefault * 0.75}rem`,
    88: `${typeScaleDefault * 0.88}rem`,
    100: `${typeScaleDefault * 1}rem`,
    125: `${typeScaleDefault * 1.25}rem`,
    150: `${typeScaleDefault * 1.5}rem`,
    175: `${typeScaleDefault * 1.75}rem`,
    200: `${typeScaleDefault * 2}rem`,
    225: `${typeScaleDefault * 2.25}rem`,
    250: `${typeScaleDefault * 2.5}rem`,
    300: `${typeScaleDefault * 3}rem`,
    400: `${typeScaleDefault * 4}rem`,
  },
};

export default fontSize;
