import config from 'config';
import { SALESFORCE_SETUP_SCRIPT_ID } from 'constants/salesforce';
import Script from 'next/script';

const SalesForceSetupScript = () => {
  return (
    <Script
      id={SALESFORCE_SETUP_SCRIPT_ID}
      async
      src={config.salesForce.trackingScript}
      type="text/javascript"
    />
  );
};
export default SalesForceSetupScript;
