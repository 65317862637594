import config from 'config';
import { isWebview } from 'constants/environment';
import { GRANT_TYPES, SOCIAL_TYPES, SocialLoginType } from 'constants/oauth';
import Qs from 'qs';
import { Credentials } from 'types/Auth';
import { OAuthToken } from 'types/next-auth';
import Cookies from 'universal-cookie';
import { log } from 'utils/loggerUtil';
import { apiClient } from './ApiClient';

interface WhoamiResponse {
  displayName: string;
  uid: string;
}

export const checkValidityOfAuthToken = async (accessToken?: string) => {
  try {
    const { data } = await apiClient<WhoamiResponse>({
      headers: {
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      url: 'authorizationserver/oauth/whoami',
      useBaseUrl: true,
    });

    return data;
  } catch (error) {
    log('checkValidityOfAuthToken', 'error', error);
    throw error;
  }
};

export const getOAuthCookie = () => new Cookies().get(config.oauth.key);

export const setOAuthCookie = (cookie: OAuthToken) => {
  log('OAuth2Helper', 'setting OAuth cookie');
  new Cookies().set(config.oauth.key, cookie, {
    ...config.cookie,
    maxAge: config.oauth.expirationTime,
    secure: config.oauth.secure,
  });
};

export const removeOAuthCookie = () => {
  log('OAuth2Helper', 'removing OAuth cookie');
  new Cookies().remove(config.oauth.key);
};

type GetNewAccessTokenParams = {
  credentials?: Credentials;
  socialLoginType?: SocialLoginType;
};

export const getNewAccessToken = async ({ credentials, socialLoginType }: GetNewAccessTokenParams = {}) => {
  const isAnonymous = !credentials;
  const authData = createOAuthData(credentials, isAnonymous, socialLoginType);

  try {
    const { data: token } = await apiClient({
      data: Qs.stringify(authData),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
      url: 'authorizationserver/oauth/token',
      useBaseUrl: true,
    });
    return {
      anonymous: isAnonymous,
      token,
    };
  } catch (error) {
    log('getNewAccessToken', 'Something went wrong when fetching new access token', error);
    throw error;
  }
};

export const getAnonymousAccessToken = () => getNewAccessToken();

export const refreshAccessToken = async () => {
  const authCookie = getOAuthCookie();

  // Anonymous user
  if (!authCookie?.refresh_token) {
    log('refreshAccessToken', 'No refresh token found, fetching new anonymous token');
    return await getAnonymousAccessToken();
  }
  // Authenticated user
  try {
    log('refreshAccessToken', 'Using refresh token to fetch new access token');
    const oauthClient = isWebview ? config.oauth.app_client : config.oauth.client;
    const { data: token } = await apiClient({
      data: Qs.stringify({
        ...oauthClient,
        grant_type: GRANT_TYPES.REFRESH_TOKEN,
        refresh_token: authCookie.refresh_token,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      url: 'authorizationserver/oauth/token',
      useBaseUrl: true,
    });
    return {
      anonymous: false,
      token,
    };
    // If refresh fails, get new anonymous access token
  } catch (error) {
    log('refreshAccessToken', 'Failed to refresh access token for authenticated user', error);
    throw error;
  }
};

interface SocialLoginTokenParams {
  oauthData: ReturnType<typeof createOAuthData>;
  userInfo: {
    email?: string;
    firstName?: string;
    lastName?: string;
  };
}

export const socialLoginToken = async ({ oauthData, userInfo }: SocialLoginTokenParams) => {
  const params = { ...oauthData, ...userInfo };
  try {
    if (!userInfo.email || !userInfo.firstName || !userInfo.lastName) throw new Error('Missing user info');
    const { data: token } = await apiClient({
      method: 'POST',
      params,
      url: '/auth/login',
    });
    return token;
  } catch (error) {
    log('socialLoginToken', 'Something went wrong when using social login', error);
    throw error;
  }
};

export const logout = async () => {
  try {
    await apiClient({ method: 'POST', url: '/auth/logout' });
  } catch (error) {
    log('logout', `Failed to log the current user out.`, error);
    throw error;
  }
};

export const loadAuthData = () => {
  const authCookie = getOAuthCookie();

  if (!authCookie) throw new Error('No OAuth cookie found');

  log('loadAuthData', 'OAuth cookie found');
  return { anonymous: !authCookie?.refresh_token, token: authCookie };
};

export const createOAuthData = (
  credentials: Credentials = {},
  isAnonymous?: boolean,
  socialLoginType?: SocialLoginType,
) => {
  const data = {
    ...config.oauth.client,
  };

  if (socialLoginType === SOCIAL_TYPES.FACEBOOK) {
    return {
      ...data,
      fbId: credentials.id,
      fbToken: credentials.accessToken,
      grant_type: GRANT_TYPES.FACEBOOK,
      username: credentials.email,
    };
  }
  if (socialLoginType === SOCIAL_TYPES.GOOGLE) {
    return {
      ...data,
      googleId: credentials.id,
      googleToken: credentials.accessToken,
      grant_type: GRANT_TYPES.GOOGLE,
      username: credentials.email,
    };
  }
  if (isAnonymous) {
    return {
      ...data,
      grant_type: GRANT_TYPES.CLIENT_CREDENTIALS,
    };
  }
  return {
    ...data,
    grant_type: GRANT_TYPES.PASSWORD,
    password: credentials.password,
    username: credentials.email,
  };
};
