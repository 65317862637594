type ThemeValue<T = string> = T | NestedThemeValue<T>;

interface NestedThemeValue<T> {
  [key: string]: ThemeValue<T>;
}

const loopValues = (themeKey: string, themeValue: ThemeValue) => {
  const values: Record<string, string> = {};

  Object.entries(themeValue).forEach(([colorKey, colorValue]) => {
    if (typeof colorValue === 'object') {
      const nestedValues = loopValues(colorKey, colorValue);

      Object.entries(nestedValues).forEach(([nestedColorKey, nestedColorValue]) => {
        values[`${themeKey}-${nestedColorKey}`] = nestedColorValue;
      });
    } else if (typeof colorValue === 'string') {
      values[`${themeKey}-${colorKey}`] = colorValue;
    }
  });

  return values;
};

/**
 * Function that gets all theme variable values and puts them into a flat object
 * Ie: Input:
 * 'clr-primary': {
 *   100: '#cdd7e4',
 *   200: '#1abaff',
 *   300: '#00a1e6',
 *   400: '#3182ed',
 *   transparent: {
 *     300: '#a6cafc0d',
 *   },
 * },
 * Output: {
 *   "clr-primary-100": "#cdd7e4"
 *   "clr-primary-200": "#1abaff"
 *   "clr-primary-300": "#00a1e6"
 *   "clr-primary-400": "#3182ed"
 *   "clr-primary-transparent-100": "#a6cafc0d"
 * }
 */
const createThemeMap = (colors: Record<string, ThemeValue>) => {
  const colorMap: Record<string, string> = {};

  Object.entries(colors).forEach(([themeKey, themeValue]) => {
    if (typeof themeValue === 'object') {
      Object.assign(colorMap, loopValues(themeKey, themeValue));
    } else if (typeof themeValue === 'string') {
      colorMap[themeKey] = themeValue;
    }
  });

  return colorMap;
};

export default createThemeMap;
