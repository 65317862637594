import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isWebview } from 'constants/environment';
import { useEffect } from 'react';
import { ConsentResult, WebviewConsentResult } from 'types/Cact';
import { useReadLocalStorage } from 'usehooks-ts';
import { mapWebviewConsent } from 'utils/cactUtil';
import { cactKeys } from './index';

const useCactConsent = () => {
  const { data: consent } = useQuery<ConsentResult>({ queryKey: cactKeys.consent() });
  const webviewConsent = useCactWebviewConsent();

  if (isWebview) {
    return webviewConsent;
  }
  return consent;
};

const useCactWebviewConsent = () => {
  const webviewLocalStorage = useReadLocalStorage<WebviewConsentResult>('tc_privacy', { initializeWithValue: false });
  const webviewConsent = mapWebviewConsent(webviewLocalStorage ?? undefined);
  return webviewConsent;
};

const useCactConsentGlobal = () => {
  const queryClient = useQueryClient();
  useEffect(() => {
    if (!isWebview) {
      window.cact?.('consent.onReady', (result: ConsentResult) => {
        queryClient.setQueryData(cactKeys.consent(), result);
      });
      window.cact?.('consent.onUpdate', (result: ConsentResult) => {
        queryClient.setQueryData(cactKeys.consent(), result);
      });
    }
  }, []);
};

export { useCactConsent, useCactConsentGlobal };
