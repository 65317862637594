import { BREAKPOINTS } from '../../constants/breakpoints';

const breakpointValues = {
  [BREAKPOINTS.XS]: 0,
  [BREAKPOINTS.SM]: 576,
  [BREAKPOINTS.MD]: 768,
  [BREAKPOINTS.LG]: 992,
  [BREAKPOINTS.XL]: 1200,
  [BREAKPOINTS.XXL]: 1440,
} as const;

export default breakpointValues;
