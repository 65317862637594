import { submitCustomerFeedback } from 'features/customerFeedback/connector';
import { Feedback } from 'types/CustomerFeedback';
import { logErrorResponse } from 'utils/loggerUtil';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type CustomerFeedbackState = {
  error?: string;
  open: boolean;
  submitted: boolean;
};

export interface CustomerFeedbackStore extends CustomerFeedbackState {
  actions: {
    reset: () => void;
    setCustomerFeedbackOpen: (open: boolean) => void;
    submitCustomerFeedback: (feedbackData: Feedback) => Promise<void>;
  };
}

const initialState: CustomerFeedbackState = {
  open: false,
  submitted: false,
};

export const useCustomerFeedbackStore = create<CustomerFeedbackStore>()(
  devtools(
    (set) => ({
      ...initialState,
      actions: {
        reset: () => set(() => initialState),
        setCustomerFeedbackOpen: (open) => set(() => ({ open })),
        submitCustomerFeedback: async (feedbackData) => {
          try {
            await submitCustomerFeedback(feedbackData);
            set(() => ({ error: undefined, open: false, submitted: true }));
          } catch (error) {
            logErrorResponse('Customer Feedback Store', error, `Error submitting customer feedback ${feedbackData}`);
            set(() => ({ error: 'Error submitting feedback', submitted: false }));
          }
        },
      },
    }),
    { name: 'customerFeedbackStore' },
  ),
);

export const useCustomerFeedbackError = () => useCustomerFeedbackStore((state) => state.error);
export const useCustomerFeedbackOpen = () => useCustomerFeedbackStore((state) => state.open);
export const useCustomerFeedbackSubmitted = () => useCustomerFeedbackStore((state) => state.submitted);
export const useCustomerFeedbackActions = () => useCustomerFeedbackStore((state) => state.actions);
