import { Url } from 'components/Link/Link';
import { getRouteInfoFromPath } from './localizedRouteUtil';

/**
 * Function that returns the product code from a url
 *
 * @param {string} slug - part of an url that contains the product code
 * @returns {Array|*} returns the product code
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProductCodeFromUrl = (slug: string): Array<any> | any => slug.split('-', 1).toString();

/**
 * Function that returns the category code from a url.
 *
 * @param {string} categoryCodes - Part of an url that contains the category code.
 * @param {number} level - The level of category that needs to be retrieves.
 * @returns {string|null} Returns the category code.
 */
export const getCategoryCodeFromUrl = (categoryCodes: string, level = 3): string | null => {
  if (categoryCodes && categoryCodes.length > 0) {
    const splitCodes = categoryCodes.split('-', level);

    if (splitCodes.length === level) {
      if (level >= 3) {
        return categoryCodes
          .split('-')
          .slice(level - 1)
          .join('-');
      }

      return splitCodes[splitCodes.length - 1];
    }
  }
  return null;
};

/**
 * Function which returns the page name for a given path
 * @param {string} pathName - the current path name
 * @param {boolean} capitalized - the capitalized flag
 * @returns {string|undefined} - the current pageName
 */
export const getPageNameFormUrl = (pathName: string, capitalized = false): string | undefined => {
  let pageName;

  if (pathName) {
    const pathInfo = getRouteInfoFromPath(pathName);
    const name = pathInfo.params?.slug ? pathInfo.params.slug : pathInfo.name;
    const formattedName = name?.toString().split('#')[0].replace('-', ' ');
    if (formattedName && capitalized) {
      pageName = formattedName.charAt(0).toUpperCase() + formattedName.slice(1).toLowerCase();
    } else {
      pageName = formattedName;
    }
  }

  return pageName;
};

export const isPDF = (pathName?: string): boolean => {
  if (pathName) {
    const extension = pathName.split('.').pop();
    return extension === 'pdf';
  }
  return false;
};

export const downloadFile = (url: string, fileName: string, newTab?: boolean) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.target = newTab ? '_blank' : '_self';
  link.dispatchEvent(new MouseEvent('click'));
};

/**
 * Function which returns the encoded url param
 * @param {string} param - the param string
 * @returns {string|undefined} the encoded url param
 */
export const encodeUrlParam = (param: string): string | undefined =>
  encodeURIComponent(param).replace(new RegExp('&', 'g'), '%26');

/**
 * Function which decodes the url param
 * @param {string} param - the param string
 * @returns {string|undefined} the decoded url param
 */
export const decodeUrlParam = (param: string): string | undefined =>
  param ? decodeURIComponent(param.replace(new RegExp('%26', 'g'), '&')) : undefined;

/**
 * Function which returns the qualifier without language
 * @param {string} qualifier - the qualifier string
 * @return {string} the qualifier without language
 */
export const getFormattedQualifier = (qualifier: string): string => {
  if (qualifier && qualifier.endsWith('-nl')) {
    return qualifier.substring(0, qualifier.lastIndexOf('-nl'));
  }
  if (qualifier && qualifier.endsWith('-fr')) {
    return qualifier.substring(0, qualifier.lastIndexOf('-fr'));
  }
  return qualifier;
};

export const isExternalUrl = (url: Url) => {
  if (!url || typeof url === 'undefined' || typeof url === 'object') return false;

  return url?.startsWith('https') || url?.startsWith('http') || url?.startsWith('www') || false;
};

export const trimSlashes = (url: string) =>
  url
    ?.split('/')
    ?.filter((v: string) => v !== '')
    ?.join('/');

export const is400Code = (statusCode: number) => {
  const regex = /^4\d{2}$/;
  return regex.test(statusCode.toString());
};

export const is500Code = (statusCode: number) => {
  const regex = /^5\d{2}$/;
  return regex.test(statusCode.toString());
};

export const extractProductCodeFromUrl = (url: string, locale: string) => {
  if (isExternalUrl(url)) {
    const match = url.split(`/${locale}/`);
    if (match?.length > 1) {
      return `/${locale}/${match[1]}`;
    }
  }

  return url;
};

export const prefixLeadingSlash = (url: string) => {
  if (!url || url?.startsWith('/')) {
    return url;
  }

  return `/${url}`;
};

export const handleTrailingSlash = (url: string) => url.replace(/\/$/, '');

export const prefixWithLocale = (url: string, locale: string) => {
  const preSlashedUrl = prefixLeadingSlash(url);
  const urlParts = preSlashedUrl?.split('/');
  const expectedLocale = urlParts?.[1];
  const containsLocale = expectedLocale === locale;

  return containsLocale ? prefixLeadingSlash(url) : prefixLeadingSlash(`${locale}${prefixLeadingSlash(url)}`);
};

/**
 *
 * @param {string} pathname The pathname retrieved from the router
 * @returns {boolean} true or false depending if the pathname passes regex
 */
export const isDynamicRoute = (pathname: string) => /\/\[[^/]+?\](?=\/|$)/.test(pathname);

export const getPathSegments = (pathname: string) => pathname.split('/').filter((x) => x);

export const getRouterParam = (params?: string | string[]) => (Array.isArray(params) ? params[0] : (params ?? ''));

export const getDynamicRouteParams = (pathname: string) => {
  const segments = getPathSegments(pathname);
  const dynamicSegments = segments.filter((segment) => segment.startsWith('[') && segment.endsWith(']'));
  return dynamicSegments.map((segment) => segment.replace(/[[\].]/g, ''));
};
