import { css } from 'styled-components';
import { brandSelect, color } from '../../functions';

const defaultValues = {};

const krefel = {
  'recent-products-clear': css`
    ${color('primary-400')};
  `,
};

const hifi = {
  'recent-products-clear': css`
    ${color('secondary-400')};
  `,
};

const tones = {
  'recent-products-clear': css`
    ${color('primary-300')};
  `,
};

const brandSpecifics = {
  krefel,
  hifi,
  tones,
};

const buttonSpecifics = {
  ...defaultValues,
  ...brandSelect(brandSpecifics),
};

export default buttonSpecifics;
