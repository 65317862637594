import { apiClient } from 'helpers/ApiClient';
import config from '../../config';

const { platform } = config;

export const RESET_PASSWORD = `${platform}/myAccount/RESET_PASSWORD`;
export const RESET_PASSWORD_SUCCESS = `${platform}/myAccount/RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_FAIL = `${platform}/myAccount/RESET_PASSWORD_FAIL`;
export const RESET_EMAIL_ADDRESS = `${platform}/myAccount/RESET_EMAIL_ADDRESS`;
export const RESET_EMAIL_ADDRESS_SUCCESS = `${platform}/myAccount/RESET_EMAIL_ADDRESS_SUCCESS`;
export const RESET_EMAIL_ADDRESS_FAIL = `${platform}/myAccount/RESET_EMAIL_ADDRESS_FAIL`;
export const SUBMIT_PERSONAL_DATA = `${platform}/myAccount/SUBMIT_PERSONAL_DATA`;
export const SUBMIT_PERSONAL_DATA_SUCCESS = `${platform}/myAccount/SUBMIT_PERSONAL_DATA_SUCCESS`;
export const SUBMIT_PERSONAL_DATA_FAIL = `${platform}/myAccount/SUBMIT_PERSONAL_DATA_FAIL`;
export const GET_SAFERPAY_ALIASES = `${platform}/myaccount/GET_SAFERPAY_ALIASES`;
export const GET_SAFERPAY_ALIASES_SUCCESS = `${platform}/myaccount/GET_SAFERPAY_ALIASES_SUCCESS`;
export const GET_SAFERPAY_ALIASES_FAIL = `${platform}/myaccount/GET_SAFERPAY_ALIASES_FAIL`;
export const GET_SAFERPAY_REDIRECT_URL = `${platform}/myaccount/GET_SAFERPAY_REDIRECT_URL`;
export const GET_SAFERPAY_REDIRECT_URL_SUCCESS = `${platform}/myaccount/GET_SAFERPAY_REDIRECT_URL_SUCCESS`;
export const GET_SAFERPAY_REDIRECT_URL_FAIL = `${platform}/myaccount/GET_SAFERPAY_REDIRECT_URL_FAIL`;
export const REMOVE_SAFERPAY_ALIAS = `${platform}/myaccount/REMOVE_SAFERPAY_ALIAS`;
export const REMOVE_SAFERPAY_ALIAS_SUCCESS = `${platform}/myaccount/REMOVE_SAFERPAY_ALIAS_SUCCESS`;
export const REMOVE_SAFERPAY_ALIAS_FAIL = `${platform}/myaccount/REMOVE_SAFERPAY_ALIAS_FAIL`;
export const GET_ALIAS_PAYMENT_MODES = `${platform}/myaccount/GET_ALIAS_PAYMENT_MODES`;
export const GET_ALIAS_PAYMENT_MODES_SUCCESS = `${platform}/myaccount/GET_ALIAS_PAYMENT_MODES_SUCCESS`;
export const GET_ALIAS_PAYMENT_MODES_FAIL = `${platform}/myaccount/GET_ALIAS_PAYMENT_MODES_FAIL`;
export const CANCEL_NEW_ALIAS_FORM = `${platform}/myaccount/CANCEL_NEW_ALIAS_FORM`;
export const SET_SAFERPAY_ALIAS_MESSAGE = `${platform}/myaccount/SET_SAFERPAY_ALIAS_MESSAGE`;
export const GET_SAFERPAY_ALIAS = `${platform}/myaccount/GET_SAFERPAY_ALIAS`;
export const GET_INVOICE = `${platform}/myaccount/GET_INVOICE`;
export const GET_INVOICE_SUCCESS = `${platform}/myaccount/GET_INVOICE_SUCCESS`;
export const GET_INVOICE_FAIL = `${platform}/myaccount/GET_INVOICE_FAIL`;

/**
 * Actions
 */

/**
 * Function which returns the customer's invoice
 * @param {string} invoiceId - the id of the invoice
 * @returns {{types: [*,*,*], promise: (function(*): (Request|*))}} the resetPassword action
 */
export function getInvoice(invoiceId) {
  return {
    promise: () =>
      apiClient({
        params: {
          fields: 'FULL',
        },
        url: `/orders/document/${invoiceId}/download`,
      }),
    types: [GET_INVOICE, GET_INVOICE_SUCCESS, GET_INVOICE_FAIL],
  };
}

/**
 * Function which resets the customer's password
 * @param {string} oldPassword - the old password
 * @param {string} newPassword - the new password
 * @param {string} userId - the current user id
 * @returns {{types: [*,*,*], promise: (function(*): (Request|*))}} the resetPassword action
 */
export function resetPassword(oldPassword, newPassword, userId) {
  return {
    promise: () =>
      apiClient({
        data: {
          newPassword,
          oldPassword,
        },
        method: "PUT",
        params: {
          fields: 'FULL',
        },
        url: `/users/${userId}/password`,
      }),
    types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL],
  };
}

/**
 * Fuction which resets the users email address
 * @param {string} newEmail - the new email address
 * @param {string} password - the users password
 * @returns {{types: [*,*,*], promise: (function(*): (Request|*))}} the reset email action
 */
export function resetEmailAddress(newEmail, password) {
  return {
    promise: () =>
      apiClient(
        {
          data: {
            newLogin: newEmail,
            password,
          },
          method: "PUT",
          params: {
            fields: 'FULL',
          },
          url: `/users/current/login`,
        }
      ),
    types: [
      RESET_EMAIL_ADDRESS,
      RESET_EMAIL_ADDRESS_SUCCESS,
      RESET_EMAIL_ADDRESS_FAIL,
    ],
  };
}

/**
 * Function which edits the personal date of the current user
 * @param {string} firstName - the user's first name
 * @param {string} lastName - the user's last name
 * @param {string} dob - the user's date of birth
 * @param {string} userId - the current user's id
 * @param {string} phone - the user's phone number
 * @returns {{types: [*,*,*], promise: (function(*): Request)}} the submit personal data action
 */
export function submitPersonalData(firstName, lastName, dob, userId, phone) {
  return {
    promise: () =>
      apiClient({
        method: "PUT", params: {
          dateOfBirth: dob,
          fields: 'FULL',
          firstName,
          lastName,
          phone,
        },
        url: `/users/${userId}`,
      }),
    types: [
      SUBMIT_PERSONAL_DATA,
      SUBMIT_PERSONAL_DATA_SUCCESS,
      SUBMIT_PERSONAL_DATA_FAIL,
    ],
  };
}

/**
 * Function which gets the saferpay aliases
 * @param {boolean} anonymous - the anonymous flag
 * @param {string=} paymentMode - the saferpay payment mode
 * @return {{types: string[], promise: (function(*): *)}} the get saferpay aliases action
 */
export function getSaferpayAliases(anonymous = false, paymentMode) {
  const userId = anonymous ? 'anonymous' : 'current';
  return {
    promise: () =>
      apiClient(
        {
          params: {
            fields: 'FULL',
            paymentMode,
          },
          url: `/saferpay/${platform}/users/${userId}/alias`,
          useBaseUrl: true,
        }
      ),
    types: [
      GET_SAFERPAY_ALIASES,
      GET_SAFERPAY_ALIASES_SUCCESS,
      GET_SAFERPAY_ALIASES_FAIL,
    ],
  };
}

/**
 * Function which gets the saferpay alias after adding a new cart
 * @return {{types: string[], promise: (function(*): *)}} the get saferpay aliases action
 * @param {string} aliasId - id of the new alias
 * @param {string} locale - the current locale
 */
export function getSaferpayAlias(aliasId, locale) {
  return {
    promise: () =>
      apiClient(
        {
          url: `/saferpay/${platform}/alias/accept/${locale}/${aliasId}`,
          useBaseUrl: true
        }
      ),
    types: [GET_SAFERPAY_ALIAS],
  };
}

/**
 * Function which gets the saferpay redirect url
 * @param {string} paymentMode - the payment mode
 * @param {boolean} anonymous - the anonymous flag
 * @param {string} locale - the current locale
 * @return {{types: string[], promise: (function(*): *)}} the get saferpay redirect url action
 */
export function getSaferpayRedirectUrl(paymentMode, anonymous = false, locale) {
  const userId = anonymous ? 'anonymous' : 'current';
  return {
    promise: () =>
      apiClient(
        {
          params: {
            fields: 'FULL',
            paymentMode,
          },
          url: `/saferpay/${platform}/users/${userId}/alias/insert/${locale}`,
          useBaseUrl: true,
        }
      ),
    types: [
      GET_SAFERPAY_REDIRECT_URL,
      GET_SAFERPAY_REDIRECT_URL_SUCCESS,
      GET_SAFERPAY_REDIRECT_URL_FAIL,
    ],
  };
}

/**
 * Function which removes a saferpay alias
 * @param {string} aliasId - the alias id
 * @param {boolean} anonymous - the anonymous flag
 * @return {{types: Array, promise: (function(*): *)}} the remove saferpay alias action
 */
export function removeSaferpayAlias(aliasId, anonymous) {
  const userId = anonymous ? 'anonymous' : 'current';
  return {
    promise: () =>
      apiClient(
        {
          method: "POST",
          url: `/saferpay/${platform}/users/${userId}/alias/delete/${aliasId}`,
          useBaseUrl: true
        }
      ),
    types: [
      REMOVE_SAFERPAY_ALIAS,
      REMOVE_SAFERPAY_ALIAS_SUCCESS,
      REMOVE_SAFERPAY_ALIAS_FAIL,
    ],
  };
}

/**
 * Function which retrieves a list of alias payment modes
 * @returns {{types: string[], promise: (function(*): *)}} the get alias payment modes action
 */
export function getAliasPaymentModes() {
  return {
    promise: () =>
      apiClient({
        params: {
          alias: true,
          fields: 'FULL',
        },
        url: `/paymentmethods`,
      }),
    types: [
      GET_ALIAS_PAYMENT_MODES,
      GET_ALIAS_PAYMENT_MODES_SUCCESS,
      GET_ALIAS_PAYMENT_MODES_FAIL,
    ],
  };
}

/**
 * Function which cancels the new alias form
 * @returns {{canceled: boolean, type: string}} the cancel new alias form action
 */
export function cancelNewAliasForm() {
  return {
    canceled: true,
    type: CANCEL_NEW_ALIAS_FORM,
  };
}

/**
 * Function which sets the saferpay alias message
 * @param {string|null} messageKey - the alias message key
 * @returns {{messageKey: *, type: string}} the set saferpay alias message action
 */
export function setSaferpayAliasMessage(messageKey) {
  return {
    messageKey,
    type: SET_SAFERPAY_ALIAS_MESSAGE,
  };
}

/**
 * Reducer
 */
const initialState = {
  addressBook: {
    addresses: [],
  },
  aliasPaymentModes: [],
  aliases: null,
  emailResetError: false,
  flyOverVisible: false,
  isLoadingAliasUrl: false,
  isLoadingAliases: false,
  newAliasUrl: null,
  order: null,
  ordersResult: null,
  passwordResetError: false,
  personalDataError: false,
};

/**
 * The myAccount reducer.
 *
 * @param {Object} state The default or current state.
 * @param {Object} action The dispatched action.
 * @returns {Object} The updated state.
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetError: false,
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        passwordResetError: true,
      };
    case SUBMIT_PERSONAL_DATA_SUCCESS:
      return {
        ...state,
        personalDataError: false,
      };
    case SUBMIT_PERSONAL_DATA_FAIL:
      return {
        ...state,
        personalDataError: true,
      };
    case GET_SAFERPAY_ALIASES:
    case GET_SAFERPAY_ALIAS:
    case REMOVE_SAFERPAY_ALIAS: {
      return {
        ...state,
        aliases: [],
        isLoadingAliases: true,
      };
    }
    case GET_SAFERPAY_ALIASES_SUCCESS:
    case REMOVE_SAFERPAY_ALIAS_SUCCESS: {
      return {
        ...state,
        aliases: action?.result?.data,
        isLoadingAliases: false,
      };
    }
    case GET_SAFERPAY_ALIASES_FAIL:
    case REMOVE_SAFERPAY_ALIAS_FAIL: {
      return {
        ...state,
        isLoadingAliases: false,
      };
    }
    case GET_ALIAS_PAYMENT_MODES:
    case GET_SAFERPAY_REDIRECT_URL: {
      return {
        ...state,
        isLoadingAliasUrl: true,
      };
    }
    case GET_SAFERPAY_REDIRECT_URL_SUCCESS: {
      return {
        ...state,
        isLoadingAliasUrl: false,
        newAliasUrl: action?.result?.data,
      };
    }
    case GET_SAFERPAY_REDIRECT_URL_FAIL: {
      return {
        ...state,
        isLoadingAliasUrl: false,
        newAliasUrl: null,
      };
    }
    case GET_ALIAS_PAYMENT_MODES_SUCCESS: {
      return {
        ...state,
        aliasPaymentModes: action?.result?.data?.paymentModes,
        isLoadingAliasUrl: false,
      };
    }
    case GET_ALIAS_PAYMENT_MODES_FAIL: {
      return {
        ...state,
        isLoadingAliasUrl: false,
      };
    }
    case CANCEL_NEW_ALIAS_FORM: {
      return {
        ...state,
        newAliasUrl: action?.canceled ? null : state.newAliasUrl,
      };
    }
    case SET_SAFERPAY_ALIAS_MESSAGE: {
      return {
        ...state,
        saferpayAliasMessage: action?.messageKey,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
