import createThemeMap from '../../../utils/themeUtil';

export const krefelColors = createThemeMap({
  primary: {
    100: '#cdd7e4',
    200: '#00c9fc',
    300: '#00a6fa',
    400: '#223263',

    transparent: {
      100: 'rgba(0, 166, 250, 0.1)',
      200: 'rgba(0, 201, 252, 0.1)',
      300: 'rgba(0, 166, 250, 0.4)',
    },
  },
  secondary: {
    200: '#fcc20d',
    250: '#ffa200',
    300: '#F94C00',
    400: '#DC2F02',

    transparent: {
      300: 'rgba(249, 76, 0, 0.4)',
      300.1: 'rgba(249, 76, 0, 0.1)',
    },
  },
});
