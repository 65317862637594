import { brandSelect, color, specific } from '../../functions';

const defaultValues = {
  'announcement-height': '56px',
  'announcement-height-mobile': '64px',
  'empty-banner': color('secondary-300'),
  'mobile-menu-close-icon-color': color('black'),
  'mobile-menu-header-background-color': color('white'),
  'mobile-menu-text-color': color('whisper-400'),
  'my-account-dropdown-background-color': color('white'),
  'normal-height': '152px',
  'normal-height-mobile': '128px',
  'simple-height': '72px',
  'simple-height-mobile': '64px',
  'smart-banner-height': '68px',
  'suggestions-bar-background-color': color('whisper-100'),
  'suggestions-bar-background-hover-color': color('whisper-200'),
  'suggestions-bar-color': color('black'),
};

const krefel = {
  'background-color': color('primary-300'),
  'empty-banner': color('primary-300'),
  'suggestions-bar-background-color': color('primary-300'),
  'suggestions-bar-background-hover-color': color('primary-400'),
  'suggestions-bar-color': color('white'),
  'welcome-text-color': color('primary-400'),
};

const hifi = {
  'background-color': color('secondary-300'),
  'mobile-menu-header-background-color': color('primary-300'),
  'welcome-text-color': color('white'),
};

const tones = {
  'background-color': color('whisper-200'),
  'empty-banner': color('whisper-200'),
  'mobile-menu-close-icon-color': color('secondary-300'),
  'mobile-menu-text-color': color('secondary-400'),
  'my-account-dropdown-background-color': specific(
    'mainbar',
    'background-color'
  ),
  'suggestions-bar-background-color': color('whisper-200'),
  'welcome-text-color': color('secondary-400'),
};

const brandSpecifics = {
  hifi,
  krefel,
  tones,
};

const buttonSpecifics = {
  ...defaultValues,
  ...brandSelect(brandSpecifics),
};

export default buttonSpecifics;
