import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface UIState {
  interactiveVisualOccurred: boolean;
  isInitialRender: boolean;
  loading: boolean;
  locale: string;
}

export interface UIStore extends UIState {
  actions: {
    setInteractiveVisualOccurred: (occurred: boolean) => void;
    setIsInitialRender: (isInitialRender: boolean) => void;
    setLoading: (show: boolean) => void;
    setLocale: (locale: string) => void;
  };
}

const initialState: UIState = {
  interactiveVisualOccurred: false,
  isInitialRender: true,
  loading: false,
  locale: '',
};

export const useUIStore = create<UIStore>()(
  devtools(
    (set) => ({
      ...initialState,
      actions: {
        setInteractiveVisualOccurred: (occurred) => set(() => ({ interactiveVisualOccurred: occurred })),
        setIsInitialRender: (isInitialRender) => set(() => ({ isInitialRender })),
        setLoading: (loading) => set(() => ({ loading })),
        setLocale: (locale) => set(() => ({ locale })),
      },
    }),
    { name: 'uiStore' }
  )
);

export const useInteractiveVisualOccurred = () => useUIStore((state) => state.interactiveVisualOccurred);
export const useLoading = () => useUIStore((state) => state.loading);
export const useIsInitialRender = () => useUIStore((state) => state.isInitialRender);
export const useUIActions = () => useUIStore((state) => state.actions);
