import { pathnames } from 'i18n/pathnames';
import { color } from 'theme';
import { NotificationType } from 'types/Notification';
import {
  SLIDE_IN_DOWN_ANIMATION,
  SLIDE_IN_DOWN_CENTER_ANIMATION,
  SLIDE_IN_UP_ANIMATION,
  SLIDE_IN_UP_CENTER_ANIMATION,
} from './animations';
import { ICON_NAMES, Icon } from './icon';

export const NOTIFICATION_MAPPER = {
  'alert-danger': {
    background: color('alert-danger-100'),
    color: color('alert-danger-200'),
  },
  'alert-warning': {
    background: color('alert-warning-100'),
    color: color('alert-warning-200'),
  },
  ocean: {
    background: color('ocean-400'),
    color: color('ocean-200'),
  },
  secondary: {
    background: color('whisper-200'),
    color: color('whisper-400'),
  },
} as const;

export const NotificationTypes = {
  ADDED_TO_WISHLIST: 'addedToWishlist',
  BASKET: 'basket',
  ERROR: 'error',
  GEO_LOCATION: 'geolocation',
  INFO: 'info',
  LOGGED_IN: 'loggedIn',
  LOGGED_IN_DEALER_DISCOUNT: 'loggedInDealerDiscount',
  QUESTION: 'question',
  REVIEW: 'review',
  SUCCESS: 'success',
  USER: 'user',
  WARNING: 'warning',
} as const;

export const NOTIFICATION_TEMPLATES = {
  GENERIC: 'GENERIC',
  SUBSCRIBE: 'SUBSCRIBE',
  SUBSCRIBE_TIMER: 'SUBSCRIBE_TIMER',
  TIMER: 'TIMER',
};

export const NOTIFICATION_TYPES = {
  ...NotificationTypes,
};

export const NOTIFICATION_ICONS: Partial<
  Record<NotificationType, { color: keyof typeof NOTIFICATION_MAPPER; name: Icon }>
> = {
  [NOTIFICATION_TYPES.INFO]: {
    color: 'secondary',
    name: ICON_NAMES.INFO_CIRCLE,
  },
  [NOTIFICATION_TYPES.QUESTION]: {
    color: 'secondary',
    name: 'square-question',
  },
  [NOTIFICATION_TYPES.BASKET]: { color: 'secondary', name: ICON_NAMES.CART },
  [NOTIFICATION_TYPES.USER]: {
    color: 'secondary',
    name: ICON_NAMES.USER_CIRCLE,
  },
  [NOTIFICATION_TYPES.SUCCESS]: {
    color: 'ocean',
    name: ICON_NAMES.CHECK_CIRCLE,
  },
  [NOTIFICATION_TYPES.WARNING]: {
    color: 'alert-warning',
    name: 'exclamation-circle',
  },
  [NOTIFICATION_TYPES.ERROR]: { color: 'alert-danger', name: 'bug' },
  [NOTIFICATION_TYPES.GEO_LOCATION]: {
    color: 'secondary',
    name: ICON_NAMES.MAP_MARKER_ALT,
  },
};

export const DEFAULT_NOTIFICATION_OBJECTS: Partial<
  Record<
    NotificationType,
    {
      actions?: Array<{ href: string; labelId: string }>;
      descriptionId?: string;
      titleId?: string;
      type: NotificationType;
    }
  >
> = {
  [NOTIFICATION_TYPES.BASKET]: {
    actions: [
      {
        href: pathnames.CART,
        labelId: 'notification_basket_go_to_cart',
      },
    ],
    descriptionId: 'notification_basket_description',
    titleId: 'notification_basket_title',
    type: NOTIFICATION_TYPES.BASKET,
  },
  [NOTIFICATION_TYPES.REVIEW]: {
    descriptionId: 'notification_review_success_description',
    titleId: 'notification_review_success_title',
    type: NOTIFICATION_TYPES.SUCCESS,
  },
  [NOTIFICATION_TYPES.ERROR]: {
    titleId: 'notification_error_title',
    type: NOTIFICATION_TYPES.ERROR,
  },
  [NOTIFICATION_TYPES.GEO_LOCATION]: {
    titleId: 'notification_geo_location_title',
    type: NOTIFICATION_TYPES.GEO_LOCATION,
  },
  [NOTIFICATION_TYPES.LOGGED_IN]: {
    actions: [
      {
        href: pathnames.MY_ACCOUNT,
        labelId: 'header_myaccount_profile',
      },
      {
        href: pathnames.CART,
        labelId: 'notification_basket_button',
      },
    ],
    descriptionId: 'notification_succesfully_logged_in_description',
    titleId: 'notification_succesfully_logged_in_title',
    type: NOTIFICATION_TYPES.SUCCESS,
  },

  [NOTIFICATION_TYPES.ADDED_TO_WISHLIST]: {
    actions: [
      {
        href: pathnames.WISHLIST,
        labelId: 'wish_list_overlay_wish_list_link',
      },
    ],
    descriptionId: 'notification_succesfully_wishlist_addded_description',
    titleId: 'notification_succesfully_wishlist_addded',
    type: NOTIFICATION_TYPES.SUCCESS,
  },
  [NOTIFICATION_TYPES.LOGGED_IN_DEALER_DISCOUNT]: {
    actions: [
      {
        href: pathnames.MY_ACCOUNT,
        labelId: 'header_myaccount_profile',
      },
      {
        href: pathnames.CART,
        labelId: 'notification_basket_button',
      },
    ],
    descriptionId: 'notification_succesfully_logged_in_dealer_discount_description',
    titleId: 'notification_succesfully_logged_in_title',
    type: NOTIFICATION_TYPES.SUCCESS,
  },
  [NOTIFICATION_TYPES.SUCCESS]: {
    type: NOTIFICATION_TYPES.SUCCESS,
  },
};

export const NOTIFICATION_CLOSE_DELAY = 4000;
export const DELAY_EVENT_TIMER = 1000;

export const SELECTED_ANIMATION = {
  BOTTOM_CENTER: SLIDE_IN_UP_CENTER_ANIMATION,
  BOTTOM_LEFT: SLIDE_IN_UP_ANIMATION,
  BOTTOM_RIGHT: SLIDE_IN_UP_ANIMATION,

  TOP_CENTER: SLIDE_IN_DOWN_CENTER_ANIMATION,
  TOP_LEFT: SLIDE_IN_DOWN_ANIMATION,
  TOP_RIGHT: SLIDE_IN_DOWN_ANIMATION,
};

const NOTIFICATION_POSITIONS = {
  BOTTOM_CENTER: 'BOTTOM_CENTER',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
  TOP_CENTER: 'TOP_CENTER',
  TOP_LEFT: 'TOP_LEFT',
  TOP_RIGHT: 'TOP_RIGHT',
} as const;

export type NotificationPosition = (typeof NOTIFICATION_POSITIONS)[keyof typeof NOTIFICATION_POSITIONS];

export const DEFAULT_NOTIFICATION_POSITION = NOTIFICATION_POSITIONS.BOTTOM_RIGHT;
