/**
 * The Redux partner module containing the related actions, action types and reducer.
 */

import { apiClient } from 'helpers/ApiClient';
import config from '../../config';
import { convertToIso } from '../../utils/dateUtil';

const { platform } = config;

/**
 * Action types
 */
export const SET_PARTNER_DELIVER_DATE = `${platform}/cart/SET_PARTNER_DELIVER_DATE`;
export const GET_PARTNER_DELIVERY_MOMENTS = `${platform}/cart/GET_PARTNER_DELIVERY_MOMENTS`;
export const GET_PARTNER_DELIVERY_MOMENTS_SUCCESS = `${platform}/cart/GET_PARTNER_DELIVERY_MOMENTS_SUCCESS`;
export const GET_PARTNER_DELIVERY_MOMENTS_FAIL = `${platform}/cart/GET_PARTNER_DELIVERY_MOMENTS_FAIL`;
export const SUBMIT_PARTNER_DELIVERY_DATE = `${platform}/cart/SUBMIT_PARTNER_DELIVERY_DATE`;
export const SUBMIT_PARTNER_DELIVERY_DATE_SUCCESS = `${platform}/cart/SUBMIT_PARTNER_DELIVERY_DATE_SUCCESS`;
export const SUBMIT_PARTNER_DELIVERY_DATE_FAIL = `${platform}/cart/SUBMIT_PARTNER_DELIVERY_DATE_FAIL`;
export const REMOVE_PARTNER_DELIVERY_DATE = `${platform}/cart/REMOVE_PARTNER_DELIVERY_DATE`;
export const GET_TRADEPLACE_DELIVERY_SLOTS = `${platform}/cart/GET_TRADEPLACE_DELIVERY_SLOTS`;
export const GET_TRADEPLACE_DELIVERY_SLOTS_SUCCESS = `${platform}/cart/GET_TRADEPLACE_DELIVERY_SLOTS_SUCCESS`;
export const GET_TRADEPLACE_DELIVERY_SLOTS_FAIL = `${platform}/cart/GET_TRADEPLACE_DELIVERY_SLOTS_FAIL`;
export const SUBMIT_TRADEPLACE_DELIVERY = `${platform}/cart/SUBMIT_TRADEPLACE_DELIVERY`;
export const SUBMIT_TRADEPLACE_DELIVERY_SUCCESS = `${platform}/cart/SUBMIT_TRADEPLACE_DELIVERY_SUCCESS`;
export const SUBMIT_TRADEPLACE_DELIVERY_FAIL = `${platform}/cart/SUBMIT_TRADEPLACE_DELIVERY_FAIL`;
export const ENABLE_TRADEPLACE_DELIVERY = `${platform}/cart/ENABLE_TRADEPLACE_DELIVERY`;
export const CHECK_TRADEPLACE_AVAILABILITY = `${platform}/cart/CHECK_TRADEPLACE_AVAILABILITY`;
export const CHECK_TRADEPLACE_AVAILABILITY_SUCCESS = `${platform}/cart/CHECK_TRADEPLACE_AVAILABILITY_SUCCESS`;
export const CHECK_TRADEPLACE_AVAILABILITY_FAIL = `${platform}/cart/CHECK_TRADEPLACE_AVAILABILITY_FAIL`;
export const SET_TRADEPLACE_DELIVERY_STATUS = `${platform}/cart/SET_TRADEPLACE_DELIVERY_STATUS`;

/**
 * Actions
 */
/**
 * Function which gets the partner delivery moments
 * @param {string} partnerCode - the partner code
 * @param {string} anonymousCartId - the anonymous cart id
 * @param {boolean} isAnonymous - the is anonymous flag
 * @returns {{types: [*,*,*], promise: (function(*))}} the get partner delivery moments action
 */
export const getPartnerDeliveryMoments = (
  partnerCode,
  anonymousCartId,
  isAnonymous = false
) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';
  return {
    partnerCode,
    promise: () =>
      apiClient(
        {
          params: {
            fields: 'FULL',
            partnerCode,
          },
          url: `/users/${userId}/carts/${cartId}/partnerdeliverydates`,
        }
      ),
    types: [
      GET_PARTNER_DELIVERY_MOMENTS,
      GET_PARTNER_DELIVERY_MOMENTS_SUCCESS,
      GET_PARTNER_DELIVERY_MOMENTS_FAIL,
    ],
  };
};

/**
 * Function which submits the partner deliveryDate
 * @param {string} partnerCode - the partner code
 * @param {string} deliveryDate - the partner delivery date
 * @param {string} anonymousCartId - the anonymous cart id
 * @param {boolean} isAnonymous - the is anonymous flag
 * @returns {{types: [*,*,*], promise: (function(*): (*|Request))}}
 * the submit partner delivery date action
 */
export const submitPartnerDeliveryDate = (
  partnerCode,
  deliveryDate,
  anonymousCartId,
  isAnonymous = false
) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  const formattedDate = new Date(deliveryDate);
  formattedDate.setHours(0, 0, 0, 0);

  return {
    promise: () =>
      apiClient(
        {
          method: "POST",
          params: {
            deliveryDate: convertToIso(formattedDate),
            fields: 'FULL',
            partnerCode,
          },
          url: `/users/${userId}/carts/${cartId}/partnerdeliverydates`,
        }
      ),
    types: [
      SUBMIT_PARTNER_DELIVERY_DATE,
      SUBMIT_PARTNER_DELIVERY_DATE_SUCCESS,
      SUBMIT_PARTNER_DELIVERY_DATE_FAIL,
    ],
  };
};

/**
 * Function which sets the partner delivery date
 * @param {string} date - the selected date
 * @param {string} partner - the partner object
 * @returns {{type: string, date: *, partner: *}} the set partner delivery date action
 */
export const setPartnerDeliveryDate = (date, partner) => ({
  date,
  partner,
  type: SET_PARTNER_DELIVER_DATE,
});

/**
 * Function which removes the selected partner delivery date
 * @param {string|undefined} partner - the partner name
 * @returns {{type: *, partner: *}} the remove partner delivery date action
 */
export const removePartnerDeliveryDate = (partner) => ({
  partner,
  type: REMOVE_PARTNER_DELIVERY_DATE,
});

/**
 * Function which retrieves the tradeplace delivery slots
 * @param {string} postalCode - the postal code
 * @param {string} partnerCode - the partner code
 * @param {string} guid - the cart id
 * @param {boolean=} liftSlotsOnly - the lift slots only flag
 * @param {string=} country - the country code
 * @returns {{types: string[], promise: (function(*): *)}} the get Tradeplace delivery slots action
 */
export const getTradeplaceDeliverySlots = (
  postalCode,
  partnerCode,
  guid,
  liftSlotsOnly = false,
  country = 'BE'
) => ({
  liftSlotsEnabled: liftSlotsOnly,
  promise: () =>
    apiClient(
      {
        params: {
          country,
          fields: 'FULL',
          liftSlotsOnly,
          partnerCode,
          postalCode,
        },
        url: `/tradeplace/${platform}/cart/${guid}/slots`,
        useBaseUrl: true,
      }
    ),
  types: [
    GET_TRADEPLACE_DELIVERY_SLOTS,
    GET_TRADEPLACE_DELIVERY_SLOTS_SUCCESS,
    GET_TRADEPLACE_DELIVERY_SLOTS_FAIL,
  ],
});

/**
 * Function which submits the tradeplace delivery options
 * @param {object} deliveryOptions - the delivery options object
 * @param {string} guid - the cart guid
 * @returns {{types: string[], promise: (function(*): *)}}
 * the sumbit tradeplace delivery options action
 */
export const submitTradeplaceDeliveryOptions = (deliveryOptions, guid) => ({
  promise: () =>
    apiClient(
      {
        method: "POST",
        params: {
          country: deliveryOptions && deliveryOptions.country,
          date: deliveryOptions && deliveryOptions.selectedDeliverySlot,
          elevator: deliveryOptions && deliveryOptions.withElevator,
          fields: 'FULL',
          floor: deliveryOptions && deliveryOptions.selectedFloor,
          partnerCode: deliveryOptions && deliveryOptions.partnerCode,
          postalCode: deliveryOptions && deliveryOptions.postalCode,
          timeFrame: deliveryOptions && deliveryOptions.moment,
        },
        url: `/tradeplace/${platform}/cart/${guid}/KREFEL_DELIVERY`,
        useBaseUrl: true,
      }
    ),
  types: [
    SUBMIT_TRADEPLACE_DELIVERY,
    SUBMIT_TRADEPLACE_DELIVERY_SUCCESS,
    SUBMIT_TRADEPLACE_DELIVERY_FAIL,
  ],
});

/**
 * Function which checks the tradeplace delivery availability
 * @param {string | undefined} guid - the cart guid
 * @param {boolean | undefined} anonymous - the anonymous flag
 * @returns {{types: string[], promise: (function(*): *)}} the check tradeplace availability action
 */
export const checkTradeplaceAvailability = (guid, anonymous) => {
  const cartId = anonymous ? guid : 'current';
  const userId = anonymous ? 'anonymous' : 'current';
  return {
    promise: () =>
      apiClient(
        {
          params: {
            fields: 'FULL',
          },
          url: `/tradeplace/${platform}/cart/${cartId}/${userId}/availability`,
          useBaseUrl: true,
        }
      ),
    types: [
      CHECK_TRADEPLACE_AVAILABILITY,
      CHECK_TRADEPLACE_AVAILABILITY_SUCCESS,
      CHECK_TRADEPLACE_AVAILABILITY_FAIL,
    ],
  };
};

/**
 * Function which enables the tradeplace delivery selection
 * @param {boolean} visible - the visible flag
 * @returns {{visible: boolean, type: string}} the enable tradeplace delivery flag
 */
export const enableTradeplaceDelivery = (visible = false) => ({
  type: ENABLE_TRADEPLACE_DELIVERY,
  visible,
});

/**
 * Function which sets the tradeplace delivery status
 * @param {boolean} deliveryOptionsOk - the delivery options ok flag
 * @returns {{type: string, deliveryOptionsOk: boolean}} the set tradeplace delivery status action
 */
export const setTradeplaceDeliveryStatus = (deliveryOptionsOk = false) => ({
  deliveryOptionsOk,
  type: SET_TRADEPLACE_DELIVERY_STATUS,
});

/**
 * Reducer
 */
const initialState = {
  isTradeplaceDeliveryOk: false,
  partnerDeliveryMoments: {},
  selectedPartnerDate: {},
  tradeplaceDeliveryEnabled: false,
  tradeplaceDeliverySlots: null,
  tradeplaceLiftEnabled: false,
  tradeplaceSlotsLoading: false,
};

/**
 * The partner reducer.
 *
 * @param {Object} state The default or current state.
 * @param {Object} action The dispatched action.
 * @returns {Object} The updated state.
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PARTNER_DELIVER_DATE: {
      const updatedPartnerDelivery = { ...state.selectedPartnerDate };
      if (action.date && action.partner) {
        if (updatedPartnerDelivery[action.partner]?.deliveryDate) {
          updatedPartnerDelivery[action.partner].deliveryDate = action.date;
        } else {
          updatedPartnerDelivery[action.partner] = {
            deliveryDate: action.date,
          };
        }
      }
      return {
        ...state,
        selectedPartnerDate: updatedPartnerDelivery,
      };
    }
    case GET_PARTNER_DELIVERY_MOMENTS_SUCCESS: {
      const updatedPartnerDeliveryMoments = { ...state.partnerDeliveryMoments };
      const deliveryMoments =
        action?.result?.data?.partnerDeliveryDates?.length > 0
          ? action.result.data.partnerDeliveryDates
          : undefined;
      if (deliveryMoments) {
        if (
          updatedPartnerDeliveryMoments?.[action.partnerCode]
        ) {
          updatedPartnerDeliveryMoments[action.partnerCode].deliveryDates =
            deliveryMoments;
          updatedPartnerDeliveryMoments[
            action.partnerCode
          ].deliveryError = false;
        } else {
          updatedPartnerDeliveryMoments[action.partnerCode] = {
            deliveryDates: deliveryMoments,
            deliveryError: false,
          };
        }
      } else if (
        updatedPartnerDeliveryMoments?.[action.partnerCode]
      ) {
        updatedPartnerDeliveryMoments[action.partnerCode].deliveryError = true;
      } else {
        updatedPartnerDeliveryMoments[action.partnerCode] = {
          deliveryError: true,
        };
      }
      return {
        ...state,
        partnerDeliveryMoments: updatedPartnerDeliveryMoments,
      };
    }
    case GET_PARTNER_DELIVERY_MOMENTS_FAIL: {
      const updatedPartnerDeliveryMoments = { ...state.partnerDeliveryMoments };
      if (
        updatedPartnerDeliveryMoments?.[action.partnerCode]
      ) {
        updatedPartnerDeliveryMoments[action.partnerCode].deliveryError = true;
      } else {
        updatedPartnerDeliveryMoments[action.partnerCode] = {
          deliveryError: true,
        };
      }
      return {
        ...state,
        partnerDeliveryMoments: updatedPartnerDeliveryMoments,
      };
    }
    case REMOVE_PARTNER_DELIVERY_DATE: {
      const updatedPartnerDeliveryMoments = { ...state.partnerDeliveryMoments };
      const updatedPartnerDate = { ...state.selectedPartnerDate };
      if (action.partner) {
        if (
          updatedPartnerDeliveryMoments?.[action.partner]
        ) {
          delete updatedPartnerDeliveryMoments[action.partner];
        }
        if (updatedPartnerDate && updatedPartnerDate[action.partner]) {
          delete updatedPartnerDate[action.partner];
        }
      }
      return {
        ...state,
        partnerDeliveryMoments: updatedPartnerDeliveryMoments,
        selectedPartnerDate: updatedPartnerDate,
      };
    }
    case GET_TRADEPLACE_DELIVERY_SLOTS: {
      return {
        ...state,
        liftSlotsEnabled: false,
        tradeplaceSlotsLoading: true,
      };
    }
    case GET_TRADEPLACE_DELIVERY_SLOTS_FAIL: {
      return {
        ...state,
        tradeplaceSlotsLoading: false,
      };
    }
    case GET_TRADEPLACE_DELIVERY_SLOTS_SUCCESS: {
      return {
        ...state,
        tradeplaceDeliverySlots: action?.result?.data?.slots,
        tradeplaceLiftEnabled: action?.liftSlotsEnabled,
        tradeplaceSlotsLoading: false,
      };
    }
    case ENABLE_TRADEPLACE_DELIVERY: {
      return {
        ...state,
        tradeplaceDeliveryEnabled: action?.visible,
      };
    }
    case SUBMIT_TRADEPLACE_DELIVERY_SUCCESS: {
      return {
        ...state,
        isTradeplaceDeliveryOk: true,
      };
    }
    case SET_TRADEPLACE_DELIVERY_STATUS: {
      return {
        ...state,
        isTradeplaceDeliveryOk: action?.deliveryOptionsOk,
      };
    }
    default:
      return state;
  }
}
