import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type ErrorState = {
  error?: string;
};

export type ErrorStore = ErrorState & {
  actions: {
    setError: (error?: string) => void;
  };
};

export const useErrorStore = create<ErrorStore>()(
  devtools(
    (set) => ({
      actions: {
        setError: (error) => set(() => ({ error })),
      },
    }),
    {
      name: 'errorStore',
    },
  ),
);

export const useError = () => useErrorStore((state) => state.error);
export const useErrorActions = () => useErrorStore((state) => state.actions);
