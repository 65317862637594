import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import useAuthResolver from 'hooks/useAuthResolver';
import useRecentProductsFunctions from 'hooks/useRecentProductsFunctions';
import useRouter from 'hooks/useRouter';
import { IProductSelector } from 'types/ContentfulTypes';
import { Product, ProductReference } from 'types/Product';
import { productKeys } from '.';
import { PRODUCT_STOCK_MESSAGE, REFERENCE_TYPES } from '../../constants/product';
import { pathnames } from '../../i18n/pathnames';
import { getProductCodeFromQuery } from '../../utils/productUtil';
import {
  fetchClassifications,
  fetchMonthlyInstalment,
  fetchProduct,
  fetchProductDeliveryModes,
  fetchProductReferences,
  fetchProductVariants,
  fetchProducts,
  fetchProductsByQuery,
  fetchRealtimeInfo,
  fetchRecentlyViewedProducts,
  fetchReviews,
  fetchUserCanWriteReview,
} from './connector';

interface UseProductParams {
  code?: string;
  enabled?: boolean;
}

const useProduct = ({ code, enabled: enabledProp = true }: UseProductParams = {}) => {
  const { locale, pathname, query } = useRouter();
  const isPdp = pathname === pathnames.PRODUCT;
  const productCodeParam = isPdp ? getProductCodeFromQuery(query) : '';
  const productCode = code ?? productCodeParam;

  return useQuery({
    enabled: enabledProp && !!productCode,
    queryFn: () => fetchProduct({ locale, productCode }),
    queryKey: productKeys.detail({ locale, productCode }),
  });
};

type UseProductsParams = {
  filterOnStock?: boolean;
  productCodes: string[];
  pushCnCToBack?: boolean;
};

const useProducts = ({ filterOnStock = true, productCodes, pushCnCToBack = false }: UseProductsParams) => {
  const { locale } = useRouter();
  return useQuery({
    enabled: !!productCodes?.length,
    queryFn: () =>
      fetchProducts({
        filterOnStock,
        locale,
        productCodes,
        pushCnCToBack,
      }),
    queryKey: productKeys.products({
      filterOnStock,
      locale,
      productCodes,
      pushCnCToBack,
    }),
  });
};

interface UseProductSelectorParams {
  selector?: IProductSelector;
}

const useProductSelector = ({ selector }: UseProductSelectorParams) => {
  const { products, pushCnCToBack, showOutOfStockProducts } = selector?.fields || {};
  const codes = products?.activeItems ?? [];
  return useProducts({
    filterOnStock: !showOutOfStockProducts,
    productCodes: codes.map(({ code }: { code: string }) => code),
    pushCnCToBack,
  });
};

const useRealtimeInfo = (codes: string[], enabled = true) => {
  const { locale } = useRouter();
  const queryClient = useQueryClient();
  return useQuery({
    enabled: enabled && !!codes?.length,
    queryFn: async () => {
      const realtimeInfo = await fetchRealtimeInfo({ locale, productCodes: codes });
      if (!realtimeInfo) return;
      const products = realtimeInfo.products;
      products.forEach((product) => {
        if (product.code)
          queryClient.setQueryData(productKeys.realtimeInfo({ locale, productCodes: codes }), {
            products: [product],
          });
      });
      return realtimeInfo;
    },
    queryKey: productKeys.realtimeInfo({ locale, productCodes: codes }),
  });
};

const useRealtimeInfoDetail = (productCode?: string) => {
  const { locale } = useRouter();
  const productCodes = productCode ? [productCode] : [];
  return useQuery({
    enabled: !!productCode,
    queryFn: () => fetchRealtimeInfo({ locale, productCodes }),
    queryKey: productKeys.realtimeInfo({ locale, productCodes }),
    select: (data) => data?.products?.find((product) => product.code === productCode),
  });
};

interface UseReviewsParams {
  currentPage?: number;
  pageSize?: number;
  productCode: string;
  sort?: string;
}

const useReviews = ({ currentPage = 0, pageSize = 4, productCode, sort = 'CREATIONTIME_DESC' }: UseReviewsParams) => {
  const { locale } = useRouter();
  return useQuery({
    enabled: !!productCode,
    placeholderData: keepPreviousData,
    queryFn: () => fetchReviews({ currentPage, locale, pageSize, productCode, sort }),
    queryKey: productKeys.reviews({ currentPage, locale, pageSize, productCode, sort }),
  });
};

type UseClassificationsParams = {
  code?: string;
  enabled?: boolean;
};

// This uses alot of backend resources, use the classifications on Product if possible
const useClassifications = ({ code, enabled = true }: UseClassificationsParams = {}) => {
  const { locale, query } = useRouter();
  const productCode = code || getProductCodeFromQuery(query);

  return useQuery({
    enabled: enabled && !!productCode,
    queryFn: () => fetchClassifications({ locale, productCode }),
    queryKey: productKeys.classifications({ locale, productCode }),
  });
};

const useProductVariants = () => {
  const { locale, query } = useRouter();
  const productCode = getProductCodeFromQuery(query);

  return useQuery({
    enabled: !!productCode,
    queryFn: () => fetchProductVariants({ locale, productCode }),
    queryKey: productKeys.variants({ locale, productCode }),
  });
};

const useUserCanWriteReview = () => {
  const { query } = useRouter();
  const productCode = getProductCodeFromQuery(query);
  const { anonymous } = useAuthResolver();

  return useQuery({
    enabled: !!productCode && !anonymous,
    queryFn: () => fetchUserCanWriteReview(productCode),
    queryKey: productKeys.userCanWriteReview(productCode, anonymous),
  });
};

const useProductAccessories = (enabled = true) => {
  const { locale, pathname, query } = useRouter();
  const productCode = getProductCodeFromQuery(query);

  const allowedPathnames: string[] = [pathnames.PRODUCT, pathnames.CATEGORY];

  const queryEnabled = allowedPathnames.includes(pathname) && !!productCode && enabled;

  return useQuery({
    enabled: queryEnabled,
    queryFn: () => fetchProductReferences({ locale, productCode, referenceType: REFERENCE_TYPES.ACCESSORIES }),
    queryKey: productKeys.references({ locale, productCode, referenceType: REFERENCE_TYPES.ACCESSORIES }),
    select: (data) =>
      data?.references.reduce((prev: Product[], reference: ProductReference) => {
        if (
          reference?.referenceType === REFERENCE_TYPES.ACCESSORIES &&
          reference?.target?.atp?.stockMessage === PRODUCT_STOCK_MESSAGE.STOCK
        ) {
          return [...prev, reference.target];
        }

        return prev;
      }, []),
  });
};

const useProductAlternatives = (enabled = true) => {
  const { locale, query } = useRouter();
  const productCode = getProductCodeFromQuery(query);

  const queryEnabled = !!productCode && enabled;

  return useQuery({
    enabled: queryEnabled,
    queryFn: () => fetchProductReferences({ locale, productCode, referenceType: REFERENCE_TYPES.SIMILAR }),
    queryKey: productKeys.references({ locale, productCode, referenceType: REFERENCE_TYPES.SIMILAR }),
    select: (data) =>
      data?.references
        .filter((reference) => reference?.referenceType === REFERENCE_TYPES.SIMILAR)
        .map((reference) => reference?.target),
  });
};

const useProductMonthlyInstalment = (price: number, numberOfMonths: number, enabled = true) =>
  useQuery({
    enabled: enabled && !!price,
    placeholderData: keepPreviousData,
    queryFn: () => fetchMonthlyInstalment(price, numberOfMonths),
    queryKey: productKeys.monthlyInstalment(price, numberOfMonths),
  });

const useRecentlyViewedProducts = (excludedProductCodes: string[]) => {
  const { locale } = useRouter();
  const { recentlyViewedProductsCookie } = useRecentProductsFunctions();

  const productCodes = recentlyViewedProductsCookie?.filter((code) => !excludedProductCodes?.includes(code));

  return useQuery({
    enabled: !!productCodes?.length,
    queryFn: () => fetchRecentlyViewedProducts({ locale, productCodes }),
    queryKey: productKeys.recentlyViewedProducts({ locale, productCodes }),
  });
};

interface UseProductQueryParams {
  currentPage?: number;
  pageSize?: number;
  pushCnCToBack?: boolean;
  query: string;
  showOutOfStock?: boolean;
}

const useProductQuery = ({
  currentPage = 0,
  pageSize = 5,
  pushCnCToBack = false,
  query,
  showOutOfStock = false,
}: UseProductQueryParams) => {
  const { locale } = useRouter();
  return useQuery({
    enabled: !!query && !!pageSize,
    placeholderData: keepPreviousData,
    queryFn: () =>
      fetchProductsByQuery({ currentPage, filterOnStock: !showOutOfStock, locale, pageSize, pushCnCToBack, query }),
    queryKey: productKeys.query({
      currentPage,
      filterOnStock: !showOutOfStock,
      locale,
      pageSize,
      pushCnCToBack,
      query,
    }),
  });
};

interface UseProductDeliveryModes {
  country?: string;
  location?: string;
  postalCode?: string;
  productCode: string;
}

const useProductDeliveryModes = ({ country, location, postalCode, productCode }: UseProductDeliveryModes) => {
  const { locale } = useRouter();
  return useQuery({
    enabled: !!productCode && !!location && !!postalCode,
    placeholderData: keepPreviousData,
    queryFn: () => fetchProductDeliveryModes({ country, locale, location, postalCode, productCode }),
    queryKey: productKeys.deliveryModes({ country, locale, location, postalCode, productCode }),
    select: (data) => data?.deliveryModes,
  });
};

export {
  useClassifications,
  useProduct,
  useProductAccessories,
  useProductAlternatives,
  useProductDeliveryModes,
  useProductMonthlyInstalment,
  useProductQuery,
  useProductSelector,
  useProductVariants,
  useProducts,
  useRealtimeInfo,
  useRealtimeInfoDetail,
  useRecentlyViewedProducts,
  useReviews,
  useUserCanWriteReview,
};
