import { css } from 'styled-components';
import { breakpoint } from './accessors';

export const px = (x) => `${x}px`;

export const rem = (x) => `${x / 16}rem`;

export const breakpointUp = (size, content) => css`
  @media all and (min-width: ${breakpoint(size)}) {
    ${content}
  }
`;

export const breakpointDown = (size, content) => css`
  @media all and (max-width: ${breakpoint(size)}) {
    ${content}
  }
`;

export const breakpointOnly = (minSize, maxSize, content) => css`
  @media only screen and (min-width: ${breakpoint(
      minSize
    )}) and (max-width: ${breakpoint(maxSize)}) {
    ${content}
  }
`;
