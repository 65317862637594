/* eslint-disable sort-keys-fix/sort-keys-fix */
import { brandSelect } from '../functions/accessors';
import { krefelColors, sharedColors, hifiColors, tonesColors } from './colors';
import spacers from './spacers';
import typography from './typography';
import specifics from './specifics';
import breakpoints from './breakpoints';
import variables from './variables';
import indices from './indices';
import { fonts } from './fonts';
import { Platform } from 'types/Platform';

const defaults = {
  ...spacers,
  ...typography,
  ...specifics,
  ...breakpoints,
  ...variables,
  ...indices,
  ...fonts,
};

const themes = {
  krefel: {
    ...defaults,
    ...sharedColors,
    ...krefelColors,
  },
  hifi: {
    ...defaults,
    ...sharedColors,
    ...hifiColors,
  },
  tones: {
    ...defaults,
    ...sharedColors,
    ...tonesColors,
  },
};

export const defaultTheme = brandSelect(themes);
export const getTheme = (brand: Platform) => themes[brand];
