import config from '../../config';
import { EXCEPTION_REASONS, EXCEPTION_TYPES } from '../../constants/exception';
import { log } from '../../utils/loggerUtil';
import { checkCart } from './cart';
import { apiClient } from 'helpers/ApiClient';
import { useAuthStore } from 'store/auth';

const { platform } = config;

export const ADD_CONFIGURATION_TO_CART = `${platform}/configurator/ADD_CONFIGURATION_TO_CART`;
export const ADD_CONFIGURATION_TO_CART_SUCCESS = `${platform}/configurator/ADD_CONFIGURATION_TO_CART_SUCCESS`;
export const ADD_CONFIGURATION_TO_CART_FAIL = `${platform}/configurator/ADD_CONFIGURATION_TO_CART_FAIL`;
export const REMOVE_CONFIGURATION = `${platform}/configurator/REMOVE_CONFIGURATION`;
export const REMOVE_CONFIGURATION_SUCCESS = `${platform}/configurator/REMOVE_CONFIGURATION_SUCCESS`;
export const REMOVE_CONFIGURATION_FAIL = `${platform}/configurator/REMOVE_CONFIGURATION_FAIL`;

/**
 * Function which adds a configuration to the cart
 * @param {boolean} isAnonymous - the is anonymous flag
 * @param {string} anonymousCartId - the anonymous cart id
 * @param {string} configurationCode - the selected configuration code
 * @returns {{types: string[], promise: (function(*): *)}} the add configuration to cart action
 * @private
 */
const addConfiguration = (isAnonymous, anonymousCartId, configurationCode) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  return {
    promise: () =>
      apiClient(
        {
          method: "POST",
          params: {
            configurationCode,
            fields: 'FULL',
          },
          url: `/users/${userId}/carts/${cartId}/configurator`,
        }
      ),
    types: [
      ADD_CONFIGURATION_TO_CART,
      ADD_CONFIGURATION_TO_CART_SUCCESS,
      ADD_CONFIGURATION_TO_CART_FAIL,
    ],
  };
};

/**
 * Checks the current cart before adding the product to it.
 *
 * @param {boolean} isAnonymous - Flag to indicate if the current user is anonymous.
 * @param {object} cart - The current cart object.
 * @param {string} configurationCode - the selected configuration code
 * @param {number=} retry - The retry count.
 * @returns {function(*=): Promise} The check cart and add product redux thunk action.
 * @private
 */
const checkCartAndAddConfiguration =
  (isAnonymous, cart, configurationCode, retry = 0) =>
    (dispatch, getState, cookies) =>
      new Promise((resolve, reject) => {
        const anonymousCartId = cookies.get(config.cart.key);

        // (is anonymous and has anonymous cart cookie) OR (logged in and has active cart)
        if (
          (isAnonymous && anonymousCartId) ||
          (cart && cart.guid && !isAnonymous)
        ) {
          dispatch(
            addConfiguration(isAnonymous, anonymousCartId, configurationCode)
          )
            .then((result) => resolve(result))
            .catch((response) => {
              const body = response?.data;
              const error = body?.errors[0];

              if (
                error?.type === EXCEPTION_TYPES.CartError &&
                error?.reason === EXCEPTION_REASONS.notFound &&
                retry <= 1
              ) {
                cookies.remove(config.cart.key, config.cookie);
                dispatch(
                  checkCartAndAddConfiguration(
                    isAnonymous,
                    cart,
                    configurationCode,
                    retry + 1
                  )
                )
                  .then((newCartResult) => {
                    resolve(newCartResult);
                  })
                  .catch((addProductError) => {
                    log(
                      'CART',
                      `Could not add configuration to cart ${(cart && cart.guid) || anonymousCartId
                      }`,
                      addProductError
                    );
                    reject(addProductError);
                  });
              } else {
                reject(error);
              }
            });
        } else {
          // is anonymous and has no cart cookie OR is logged in and has no cart in state
          dispatch(checkCart(isAnonymous, anonymousCartId))
            .then((cartResult) =>
              dispatch(
                addConfiguration(isAnonymous, cartResult.guid, configurationCode)
              )
                .then((newCartResult) => resolve(newCartResult))
                .catch((addProductError) => {
                  log(
                    'CART',
                    `Could not add configuration to cart ${(cart && cart.guid) || anonymousCartId
                    }`,
                    addProductError
                  );
                  reject(addProductError);
                })
            )
            .catch((checkCartError) => {
              log('CART', `Could not fetch cart to add configuration.`);
              reject(checkCartError);
            });
        }
      });

/**
 * Function which adds a configuration to the user's cart
 * @param {boolean} isAnonymous - the is anonymous flag
 * @param {object} cart - the cart object
 * @param {string} configurationCode - the selected configuration code
 * @returns {{types: string[], promise: (function(*): *)}} the add configuration to cart action
 */
export const addConfigurationToCart =
  (isAnonymous, cart, configurationCode) => (dispatch, getState, cookies) => {
    // Check auth
    const authCookie = cookies.get(config.oauth.key);

    if (!authCookie) {
      // fetch anonymous token
      return useAuthStore.getState().actions.authenticateAnonymous().then(() =>
        dispatch(
          checkCartAndAddConfiguration(isAnonymous, cart, configurationCode)
        )
      );
    }
    // has token --> perform add to cart
    return dispatch(
      checkCartAndAddConfiguration(isAnonymous, cart, configurationCode)
    );
  };

/**
 * Function which removes a configuration from the user's cart
 * @param {boolean} isAnonymous - the is anonymous flag
 * @param {string} anonymousCartId - the anonymous cart id
 * @param {string} configurationCode - the configuration code
 * @returns {{types: string[], promise: (function(*): *)}} the remove configuration from cart action
 */
export const removeConfigurationFromCart = (
  isAnonymous,
  anonymousCartId,
  configurationCode
) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  return {
    promise: () =>
      apiClient(
        {
          method: "DELETE",
          params: {
            fields: 'FULL',
          },
          url: `/users/${userId}/carts/${cartId}/configurator/${configurationCode}`,
        }
      ),
    types: [
      REMOVE_CONFIGURATION,
      REMOVE_CONFIGURATION_SUCCESS,
      REMOVE_CONFIGURATION_FAIL,
    ],
  };
};

/**
 * Reducer
 */
const initialState = {
  isLoadingCart: false,
};

/**
 * The configurator reducer.
 *
 * @param {Object} state The default or current state.
 * @param {Object} action The dispatched action.
 * @returns {Object} The updated state.
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_CONFIGURATION_TO_CART: {
      return {
        ...state,
        isLoadingCart: true,
      };
    }
    case ADD_CONFIGURATION_TO_CART_FAIL: {
      return {
        ...state,
        isLoadingCart: false,
      };
    }
    case ADD_CONFIGURATION_TO_CART_SUCCESS: {
      return {
        ...state,
        isLoadingCart: false,
      };
    }

    default:
      return state;
  }
}
