import { Cart } from 'types/Cart';
import Cookies from 'universal-cookie';
import config from '../config';
import { createSalesForceAddToCartObject } from './analyticsProductDataUtil';
import { splitEntries } from './orderUtil';

export const addSalesForceAddToCartEvent = (entries: unknown[] = []) => {
  if (entries?.length > 0) {
    const cartEntries = createSalesForceAddToCartObject(entries);
    if (cartEntries && window._etmc) {
      config.salesForce.orgId && window._etmc.push(['setOrgId', config.salesForce.orgId]);
      window._etmc.push(['trackCart', cartEntries]);
    }
  }
};

export const removeCartCookie = () => {
  new Cookies().remove(config.cart.key, config.cookie);
};

export const getCartDefaultEntriesLength = (cart?: Cart) => {
  if (!cart?.entries) return 0;

  const cartEntries = splitEntries(cart?.entries);
  const cartEntriesDefaultEntriesLength = cartEntries?.defaultEntries?.length;

  if (cartEntriesDefaultEntriesLength) {
    return cartEntriesDefaultEntriesLength;
  }

  if (cartEntries?.pcConfigurations) {
    return Object.keys(cartEntries.pcConfigurations)?.length;
  }

  return 0;
};
