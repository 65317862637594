const spacerDefault = 1;

const spacers = {
  spacer: {
    25: `${spacerDefault * 0.25}rem`,
    50: `${spacerDefault * 0.5}rem`,
    75: `${spacerDefault * 0.75}rem`,
    88: `${spacerDefault * 0.88}rem`,
    100: `${spacerDefault * 1}rem`,
    112: `${spacerDefault * 1.12}rem`,
    125: `${spacerDefault * 1.25}rem`,
    150: `${spacerDefault * 1.5}rem`,
    175: `${spacerDefault * 1.75}rem`,
    200: `${spacerDefault * 2}rem`,
    225: `${spacerDefault * 2.25}rem`,
    250: `${spacerDefault * 2.5}rem`,
    300: `${spacerDefault * 3}rem`,
    325: `${spacerDefault * 3.25}rem`,
    350: `${spacerDefault * 3.5}rem`,
    375: `${spacerDefault * 3.75}rem`,
    400: `${spacerDefault * 4}rem`,
    425: `${spacerDefault * 4.25}rem`,
    450: `${spacerDefault * 4.5}rem`,
    500: `${spacerDefault * 5}rem`,
    600: `${spacerDefault * 6}rem`,
    650: `${spacerDefault * 6.5}rem`,
    700: `${spacerDefault * 7}rem`,
    750: `${spacerDefault * 7.5}rem`,
    800: `${spacerDefault * 8}rem`,
    900: `${spacerDefault * 9}rem`,
    1000: `${spacerDefault * 10}rem`,
    1250: `${spacerDefault * 12.5}rem`,
    1500: `${spacerDefault * 15}rem`,
    1750: `${spacerDefault * 17.5}rem`,
    2000: `${spacerDefault * 20}rem`,
  },
};

export default spacers;
