import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface AddToCartState {
  showAddToCartSheet: boolean;
}

export interface AddToCartStore extends AddToCartState {
  actions: {
    reset: () => void;
    setShowAddToCartSheet: (show: boolean) => void;
  };
}

const initialState: AddToCartState = {
  showAddToCartSheet: false,
};

export const useAddToCartStore = create<AddToCartStore>()(
  devtools(
    (set) => ({
      ...initialState,
      actions: {
        reset: () => set(() => initialState),
        setShowAddToCartSheet: (show) => set(() => ({ showAddToCartSheet: show })),
      },
    }),
    { name: 'AddToCartStore' },
  ),
);

export const useShowAddToCartSheet = () => useAddToCartStore((state) => state.showAddToCartSheet);
export const useAddToCartStoreActions = () => useAddToCartStore((state) => state.actions);
