import { apiClient } from 'helpers/ApiClient';
import config from '../../config';
import { NUMBER_OF_PAGE_RESULTS } from '../../constants/search';
import { checkPageSize } from '../../utils/pageUtil';
import {
  ENABLE_CATEGORY_FACET_SEARCH,
  SEARCH_BY_CATEGORY_SUCCESS,
} from './category';

const { platform } = config;

/**
 * The Redux search module containing the search related action, action types and reducer.
 */

/**
 * Action types
 */
export const GET_SUGGESTIONS = `${platform}/search/GET_SUGGESTIONS`;
export const GET_SUGGESTIONS_SUCCESS = `${platform}/search/GET_SUGGESTIONS_SUCCESS`;
export const GET_SUGGESTIONS_FAIL = `${platform}/search/GET_SUGGESTIONS_FAIL`;
export const CLEAR_SUGGESTIONS = `${platform}/search/CLEAR_SUGGESTIONS`;
export const SEARCH = `${platform}/search/SEARCH`;
export const SEARCH_SUCCESS = `${platform}/search/SEARCH_SUCCESS`;
export const SEARCH_FAIL = `${platform}/search/SEARCH_FAIL`;
export const SET_LAYOUT_TYPE = `${platform}/search/SET_LAYOUT_TYPE`;
export const QUERY_SEARCH = `${platform}/search/QUERY_SEARCH`;
export const QUERY_SEARCH_SUCCESS = `${platform}/search/QUERY_SEARCH_SUCCESS`;
export const QUERY_SEARCH_FAIL = `${platform}/search/QUERY_SEARCH_FAIL`;
export const ENABLE_FACET_SEARCH = `${platform}/search/ENABLE_FACET_SEARCH`;
export const TOGGLE_ACTIVE_SEARCH_DROPDOWN = `${platform}/search/TOGGLE_ACTIVE_SEARCH_DROPDOWN`;
export const TOGGLE_SEARCH_DROPDOWN = `${platform}/search/TOGGLE_SEARCH_DROPDOWN`;

/**
 * Actions
 */

/**
 * Retrieves the search suggestions
 *
 * @param {string=} query - The search query.
 * @param {number} maxProducts - The max number of suggested products.
 * @param {number} maxSuggestions - The max number of suggested suggestions.
 * @param {number} maxCategories - The max number of categories.
 * @returns {{types: [*,*,*], promise: (function(*))}} Get suggestions action.
 */
export const getSuggestions = (
  query,
  maxProducts = 0,
  maxSuggestions = 0,
  maxCategories = 0
) => ({
  promise: () =>
    apiClient(
      {
        params: {
          fields: 'FULL',
          max: 5,
          maxCategories,
          maxProducts,
          maxSuggestions,
          term: query,
        },
        url: `/products/suggestions`,
      },
    ),
  query,
  types: [GET_SUGGESTIONS, GET_SUGGESTIONS_SUCCESS, GET_SUGGESTIONS_FAIL],
});

export const toggleActiveSearchDropdown = (show) => ({
  show,
  type: TOGGLE_ACTIVE_SEARCH_DROPDOWN,
});

/**
 * Toggle search dropdown.
 *
 * @returns {{type: string}} The clear suggestions action.
 */
export const toggleSearchDropdown = (show) => ({
  show,
  type: TOGGLE_SEARCH_DROPDOWN,
});

/**
 * Clears the suggestions.
 *
 * @returns {{type: string}} The clear suggestions action.
 */
export const clearSuggestions = () => ({
  type: CLEAR_SUGGESTIONS,
});

/**
 * Retrieves the search suggestions
 *
 * @param {string|string[]|undefined} query - The search query.
 * @param {number|string} page - The search page
 * @param {number|string|string[]|undefined} pageSize - The search page size.
 * @param {string|string[]|undefined} sort - The sort product filter
 * @param {string|string[]|undefined} initialQuery - The initial search query
 * @param {number|string|string[]|undefined} facetLimit - The maximum amount of facets that need to be returned.
 * @param {string|undefined} pageQualifier - The search cms page qualifier.
 *
 * @returns {{types: [*,*,*], promise: (function(*))}} Get suggestions action.
 */
export const searchProducts = (
  query = undefined,
  page = 0,
  pageSize = NUMBER_OF_PAGE_RESULTS.TOTAL_RESULTS_1,
  sort = null,
  initialQuery = undefined,
  facetLimit = undefined,
  pageQualifier = undefined
) => {
  const checkedPageSize = checkPageSize(Number(pageSize));

  return {
    promise: () =>
      apiClient(
        {
          params: {
            currentPage: page,
            facetLimit,
            fields: 'FULL',
            initialQuery,
            pageQualifier,
            pageSize: checkedPageSize,
            query,
            sort,
          },
          url: `/products/search`,
        }
      ),
    query,
    types: [SEARCH, SEARCH_SUCCESS, SEARCH_FAIL],
  };
};

/**
 * Function which enables the facet search
 * @param {boolean} enabled - the enabled flag
 * @returns {{type: string, enabled: boolean}} the enabled facet search flag
 */
export const enableFacetSearch = (enabled = false) => ({
  enabled,
  type: ENABLE_FACET_SEARCH,
});

/**
 * Reducer
 */
const initialState = {
  activeSearchDropdown: false,
  isLoadingSuggestions: false,
  isSearching: false,
  isSearchingQuery: false,
  pageSize: null,
  querySearchResults: null,
  searchPageSize: null,
  searchQuery: null,
  searchResults: null,
  selectedFilter: null,
  showSearchDropdown: false,
  sort: null,
  suggestions: null,
  suggestionsQuery: null,
};

/**
 * The search reducer.
 *
 * @param {Object} state The default or current state.
 * @param {Object} action The dispatched action.
 * @returns {Object} The updated state.
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SUGGESTIONS:
      return {
        ...state,
        isLoadingSuggestions: true,
        suggestionsQuery: action.query,
      };
    case GET_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        isLoadingSuggestions: false,
        suggestions:
          Object.keys(action.result.data).length > 0 &&
            action.result.data.constructor === Object
            ? action.result.data
            : null,
      };
    case GET_SUGGESTIONS_FAIL: {
      return {
        ...state,
        isLoadingSuggestions: false,
      };
    }
    case CLEAR_SUGGESTIONS:
      return {
        ...state,
        suggestions: null,
      };
    case SEARCH: {
      return {
        ...state,
        isSearching: true,
      };
    }
    case SEARCH_BY_CATEGORY_SUCCESS:
    case SEARCH_SUCCESS: {
      const pageSize = action?.result?.data?.pagination?.pageSize;
      const sort = action?.result?.data?.sorts?.find(
        (sortType) => sortType.selected
      );

      return {
        ...state,
        isSearching: false,
        pageSize,
        searchResults:
          Object.keys(action.result.data).length > 0 &&
            action.result.data.constructor === Object
            ? action.result.data
            : null,
        sort: sort?.code || null,
      };
    }
    case SEARCH_FAIL: {
      return {
        ...state,
        isSearching: false,
      };
    }
    case QUERY_SEARCH: {
      return {
        ...state,
        isSearchingQuery: true,
      };
    }
    case QUERY_SEARCH_SUCCESS: {
      const updatedQuerySearchResults = { ...state.querySearchResults };
      const querySearchResults = action?.result?.data
        ? action.result.data
        : null;
      if (action.searchName && querySearchResults) {
        updatedQuerySearchResults[action.searchName] = querySearchResults;
      }
      return {
        ...state,
        isSearchingQuery: false,
        querySearchResults: updatedQuerySearchResults,
      };
    }
    case QUERY_SEARCH_FAIL: {
      return {
        ...state,
        isSearchingQuery: false,
      };
    }
    case ENABLE_CATEGORY_FACET_SEARCH:
    case ENABLE_FACET_SEARCH: {
      return {
        ...state,
        isSearching: action && action.enabled,
      };
    }
    case TOGGLE_ACTIVE_SEARCH_DROPDOWN: {
      return {
        ...state,
        activeSearchDropdown: action.show,
      };
    }
    case TOGGLE_SEARCH_DROPDOWN: {
      return {
        ...state,
        showSearchDropdown: action.show,
      };
    }
    default:
      return state;
  }
}
