export const TENSES = { FUTURE: 'future', PAST: 'past' } as const;
export type Tense = (typeof TENSES)[keyof typeof TENSES];
export const LONG_DAY_NAMES = [
  'general_days_monday',
  'general_days_tuesday',
  'general_days_wednesday',
  'general_days_thursday',
  'general_days_friday',
  'general_days_saturday',
  'general_days_sunday',
];

export const SHORT_DAY_NAMES = [
  'general_days_sunday_short',
  'general_days_monday_short',
  'general_days_tuesday_short',
  'general_days_wednesday_short',
  'general_days_thursday_short',
  'general_days_friday_short',
  'general_days_saturday_short',
];

export const MONTH_NAMES = [
  'general_months_january',
  'general_months_february',
  'general_months_march',
  'general_months_april',
  'general_months_may',
  'general_months_june',
  'general_months_july',
  'general_months_august',
  'general_months_september',
  'general_months_october',
  'general_months_november',
  'general_months_december',
];

export const ARROWS = {
  NEXT: 'next',
  PREV: 'prev',
} as const;
export type ArrowsType = (typeof ARROWS)[keyof typeof ARROWS];

export const MILLISECONDS_IN_MINUTE = 60000;
export const MILLISECONDS_IN_HOUR = 3600000;
export const MILLISECONDS_IN_SECOND = 1000;
