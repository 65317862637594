import { useUIStore } from "store/ui";

const hydrateServerClientState = (clientState, serverState) => {
  const nextState = { ...clientState, ...serverState };
  const isInitialRender = useUIStore.getState().isInitialRender;
  const footerQualifier = clientState?.cms?.footerQualifier;
  const keepFooter = !!footerQualifier;

  const selectedCmsQualifier = serverState?.cms?.qualifier || clientState?.cms?.qualifier;
  const selectedCmsQualifierPage =
    nextState.cms.pages[selectedCmsQualifier] || clientState.cms.pages[selectedCmsQualifier];

  if (clientState && !isInitialRender) {
    nextState.cart = clientState.cart;
    nextState.compare.showCompareMenu = clientState.compare.showCompareMenu;
    nextState.search.isSearching = clientState.search.isSearching;
    nextState.search = clientState.search;

    nextState.cms.qualifier = selectedCmsQualifier;

    nextState.cms.pages = keepFooter
      ? {
        ...nextState.cms.pages,
        [footerQualifier]: clientState.cms.pages[footerQualifier],
        [selectedCmsQualifier]: selectedCmsQualifierPage,
      }
      : { ...nextState.cms.pages };
    nextState.cms.footerQualifier = keepFooter ? clientState.cms.footerQualifier : nextState.cms.footerQualifier;

    nextState.myAccount.isFetchingOrders = clientState.myAccount.isFetchingOrders;

    nextState.checkout.informationForm = clientState.checkout.informationForm || nextState.checkout.informationForm;

    nextState.checkout.userInfoAddress = clientState.checkout.userInfoAddress || nextState.checkout.userInfoAddress;
  }

  useUIStore.getState().actions.setIsInitialRender(false);

  return nextState;
};

export default hydrateServerClientState;
