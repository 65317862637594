import { NextFontWithVariable } from 'next/dist/compiled/@next/font';
import * as hifiFonts from './hifi';
import * as krefelFonts from './krefel';
import * as tonesFonts from './tones';

const brand = process.env.NEXT_PUBLIC_PLATFORM ?? process.env.PLATFORM ?? 'krefel';

const fontsMap: Record<string, { primary: NextFontWithVariable; secondary?: NextFontWithVariable }> = {
  hifi: hifiFonts,
  krefel: krefelFonts,
  tones: tonesFonts,
};

export default fontsMap[brand];
