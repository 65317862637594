import { brandSelect } from '../../functions';

const defaultValues = {};

const krefel = {};

const hifi = {};

const tones = {};

const brandSpecifics = {
  krefel,
  hifi,
  tones,
};

const buttonSpecifics = {
  ...defaultValues,
  ...brandSelect(brandSpecifics),
};

export default buttonSpecifics;
