import { WindowKeys } from 'types/globals';

export const detectValidWindow = () => typeof window !== 'undefined';

export const detectWindowObject = (key: WindowKeys) => {
  if (typeof window === 'object') {
    if (window[key] && window[key] !== 'undefined') return window[key];
  }

  return null;
};

export const detectAuth = () => detectWindowObject('__AUTHENTICATION');
export const detectCart = () => detectWindowObject('__CART_CODE');
