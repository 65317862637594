/**
 * Mobile utility file.
 *
 * @author janssco
 * @since 1.0
 */

import config from '../config';

/**
 * Retrieves the current screen dimensions.
 * Not intended for SSR!
 *
 * @returns {{w: (Number|number), h: (Number|number)}} The current screen dimensions.
 */
export function getCurrentScreenWidth() {
  if (typeof window !== 'undefined') {
    const w = window;
    const d = document;
    const e = d.documentElement;
    const g = d.getElementsByTagName('body')[0];
    const x = w.innerWidth || e.clientWidth || g.clientWidth;
    const y = w.innerHeight || e.clientHeight || g.clientHeight;

    return {
      h: y,
      w: x,
    };
  }

  return { h: 0, w: 0 };
}

/**
 * Checks whether the current screen size is that of a mobile.
 * Not intended for SSR!
 *
 * @returns {boolean} True if the current screen is a tablet, false if not.
 */
export function isMobile() {
  const dimensions = getCurrentScreenWidth();

  return !!(dimensions && dimensions.w <= config.breakpoints.mobile);
}

/**
 * Function which checks if the website is visited from a mobile device
 * @returns {boolean} the mobile device flag
 */
export function isMobileDevice() {
  return !!(
    (window.navigator && 'standalone' in window.navigator && window.navigator.standalone) ||
    window.navigator.userAgent.match('CriOS') ||
    window.navigator.userAgent.match(/mobile/i)
  );
}

/**
 * Function which checks if the website is visited from a phone
 * @returns {boolean} the phone flag
 */
export function isPhone() {
  return !!(
    (window.navigator && 'standalone' in window.navigator && window.navigator.standalone) ||
    window.navigator.userAgent.match(/iphone/i) ||
    (window.navigator.userAgent.match(/mobile/i) && window.navigator.userAgent.match(/android/i))
  );
}

/**
 * Function which checks if the website is visited from a tablet
 * @returns {boolean} the tablet flag
 */
export function isTabletByUserAgent() {
  return !!(
    (window.navigator && 'standalone' in window.navigator && window.navigator.standalone) ||
    window.navigator.userAgent.match(/ipad/i) ||
    (!window.navigator.userAgent.match(/mobile/i) && window.navigator.userAgent.match(/android/i))
  );
}

/**
 * Function which checks if the website is visited from edge or ie
 * @param {boolean} checkEdge - the check edge flag.
 * @returns {boolean} the isIEorEdge flag.
 */
export function isIEOrEdge(checkEdge = true) {
  let isIE = false;

  if (typeof window !== 'undefined') {
    const { userAgent } = window.navigator;
    const indexIE = userAgent.indexOf('MSIE');
    const indexEdge = userAgent.indexOf('Edge/');

    if (indexIE > 0) {
      isIE = true;
    } else if (navigator.userAgent.match(/Trident\/7\./)) {
      isIE = true;
    } else if (indexEdge > 0 && checkEdge) {
      isIE = true;
    }
  }

  return isIE;
}

/**
 * Function which checks if the current devices os is IOS.
 * @returns {boolean} True if IOS, false if not.
 */
export function isIOS() {
  let isIOSDevice = false;

  if (/iphone|ipad|ipod|XBLWP7|safari/i.test(navigator.userAgent.toLowerCase())) {
    isIOSDevice = true;
  }

  return isIOSDevice;
}

/**
 * Function which checks if the current devices os is Android.
 * @returns {boolean} True if Android, false if not.
 */
export function isAndroid() {
  let isAndroidDevice = false;

  if (/android|XBLWP7/i.test(navigator.userAgent.toLowerCase())) {
    isAndroidDevice = true;
  }

  return isAndroidDevice;
}

/**
 * Function which checks if the site is visited by a mobile safari browser.
 * @returns {boolean} True if it is mobile safari browser. False if not.
 */
export function isMobileSafari() {
  const userAgent = window.navigator && window.navigator.userAgent;
  return !!(userAgent.match(/iPad/i) || userAgent.match(/iPhone/i));
}

export const getDevice = () => {
  return (isPhone() && 'mobile') || (isTabletByUserAgent() && 'tablet') || 'desktop';
};
