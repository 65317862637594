export const pathnames = {
  ABOUT_US: '/about-us',
  ACTIVATE: '/activate',
  ALMAPAY: '/almapay/[...index]',
  BRAND_CATEGORY: '/brands/[brand]/[slug]',
  BRAND_CATEGORY_ALL: '/brands/[brand]/all-products',
  BRAND_DETAIL: '/brands/[brand]',
  BRAND_INDEX: '/brands',
  BRANDS: '/brands',
  BUDGETCARD: '/budget-card',
  CART: '/cart',
  CASHBACK: '/cashback',
  CASHBACKS: '/cashbacks',
  CATEGORY: '/c/[...slug]',
  // Checkout
  CHECKOUT_CONFIRMATION: '/checkout/confirmation',
  CHECKOUT_DELIVERY: '/checkout/deliverymethod',
  CHECKOUT_LOGIN: '/checkout/login',
  CHECKOUT_OVERVIEW: '/checkout/overview',
  CHECKOUT_PAYMENT: '/checkout/payment',
  CHECKOUT_PAYMENT_REMAINING: '/checkout/payment/remaining',
  CHECKOUT_REGISTER: '/checkout/register',
  // Fake pathname to match /register?isCheckout=true
  CHECKOUT_SHIPPING: '/checkout/shippinginfo',
  CMS_SEARCH_PAGE: '/[slug]',
  CMSPAGE: '/[slug]',
  COMPARE: '/compare',
  CONTACT: '/contact',
  CONTACT_TONES: '/contact-tones',
  CONTENTFUL_PREVIEW: '/contentful-preview/[id]',
  DELIVERIES: '/deliveries',
  DELIVERIES_ONLINE: '/deliveries/online',
  DELIVERIES_SHOPS: '/deliveries/stores',
  DEMODAYS: '/demodays',
  ERROR404: '/404',
  ERROR500: '/500',
  FAQ: '/faq',
  GIFTCARD: '/giftcard',
  INDEX: '/',
  INFOFICHES: '/infofiches',
  LOGIN: '/login',
  MANUALS: '/manuals',
  MULTISAFEPAY: '/multisafepay/[...index]',
  // My Account
  MY_ACCOUNT: '/myaccount',
  MY_ACCOUNT_ADDRESSES: '/myaccount/addresses',
  MY_ACCOUNT_CARDS: '/myaccount/cards',
  MY_ACCOUNT_CONFIGURATIONS: '/myaccount/configurations',
  MY_ACCOUNT_INFORMATION: '/myaccount/information',
  MY_ACCOUNT_ORDERS: '/myaccount/orders',
  MY_ACCOUNT_ORDERS_DETAIL: '/myaccount/orders/[...slug]',
  MY_ACCOUNT_PREFERENCES: '/myaccount/preferences',
  ON_ORDER: '/onorder/[code]',
  OUR_ASSORTMENT: '/our-assortment',
  PASSWORD_FORGOTTEN: '/password-forgotten/[...id]',
  PASSWORD_FORGOTTEN_REQUEST: '/password-forgotten-request',
  // Pc configurator
  PC_CONFIGURATOR: '/pc-configurator',
  PC_CONFIGURATOR_DETAIL: '/pc-configurator/[id]',
  PC_CONFIGURATOR_PART_DETAIL: '/pc-configurator/[id]/[part]',
  PRE_ORDER: '/preorder/[code]',
  PRICE_GUARANTEE: '/price-guarantee',
  PRODUCT: '/p/[id]',
  REGISTER: '/register',
  REPAIR_INDEX: '/repair-index',
  REPAIRS: '/repairs',
  REPAIRS_FOLLOW: '/repairs/follow',
  REPAIRS_REQUEST: '/repairs/request',
  REPAIRS_REQUEST_CONFIRMATION: '/repairs/request/confirmation',

  SANTANDER: '/santander',
  SD_WORKS_CONFIRM: '/sdWorks/confirm/[id]',
  SEARCH: '/search',
  SERVICE: '/service',
  SPARE_PARTS: '/spare-parts',

  // Store finder
  STOREFINDER: '/stores',
  STOREFINDER_DETAIL: '/stores/[id]',

  WARRANTY: '/warranty',
  WISHLIST: '/lists',
  WISHLIST_ANONYMOUS: '/lists/anonymous',
  WISHLIST_ID: '/lists/[...id]',
  WORLDLINE: '/worldline/[...index]',
} as const;
export type PathnameType = (typeof pathnames)[keyof typeof pathnames];

const PATHNAMES = { ...pathnames } as const;

export type Pathname = (typeof PATHNAMES)[keyof typeof PATHNAMES];

export type LocalizedSlugs = Record<string, string>;

export const localizedPathnames: Record<string, LocalizedSlugs> = {
  [pathnames.CART]: {
    de: '/einkaufskorb',
    en: pathnames.CART,
    fr: '/panier',
    nl: '/winkelmandje',
  },
  [pathnames.DELIVERIES]: {
    de: '/lieferungen',
    en: pathnames.DELIVERIES,
    fr: '/livraisons',
    nl: '/leveringen',
  },
  [pathnames.DELIVERIES_ONLINE]: {
    de: '/lieferungen/online',
    en: pathnames.DELIVERIES_ONLINE,
    fr: '/livraisons/online',
    nl: '/leveringen/online',
  },
  [pathnames.DELIVERIES_SHOPS]: {
    de: '/lieferungen/shops',
    en: pathnames.DELIVERIES_SHOPS,
    fr: '/livraisons/magazins',
    nl: '/leveringen/winkels',
  },
  [pathnames.INDEX]: {
    de: '/',
    en: pathnames.INDEX,
    fr: '/',
    nl: '/',
  },
  [pathnames.LOGIN]: {
    de: '/anmeldung',
    en: pathnames.LOGIN,
    fr: '/login',
    nl: '/login',
  },
  [pathnames.REGISTER]: {
    de: '/registrieren',
    en: pathnames.REGISTER,
    fr: '/enregistrez',
    nl: '/registreer',
  },
  [pathnames.BRANDS]: {
    de: '/marken',
    en: pathnames.BRANDS,
    fr: '/marques',
    nl: '/merken',
  },
  [pathnames.BRAND_DETAIL]: {
    de: '/marken/[brand]',
    en: pathnames.BRAND_DETAIL,
    fr: '/marques/[brand]',
    nl: '/merken/[brand]',
  },
  [pathnames.BRAND_CATEGORY]: {
    de: '/marken/[brand]/[slug]',
    en: pathnames.BRAND_CATEGORY,
    fr: '/marques/[brand]/[slug]',
    nl: '/merken/[brand]/[slug]',
  },
  [pathnames.BRAND_CATEGORY_ALL]: {
    de: '/marken/[brand]/alle-produkte',
    en: pathnames.BRAND_CATEGORY_ALL,
    fr: '/marques/[brand]/tous-produits',
    nl: '/merken/[brand]/alle-producten',
  },
  [pathnames.COMPARE]: {
    de: '/vergleichen',
    en: pathnames.COMPARE,
    fr: '/comparer',
    nl: '/vergelijken',
  },
  [pathnames.CMSPAGE]: {
    de: '/[slug]',
    en: pathnames.CMSPAGE,
    fr: '/[slug]',
    nl: '/[slug]',
  },
  [pathnames.SEARCH]: {
    de: '/suche',
    en: pathnames.SEARCH,
    fr: '/chercher',
    nl: '/zoeken',
  },
  [pathnames.FAQ]: {
    de: '/faq',
    en: pathnames.FAQ,
    fr: '/faq',
    nl: '/faq',
  },
  [pathnames.SPARE_PARTS]: {
    de: '/ersatzteile',
    en: pathnames.SPARE_PARTS,
    fr: '/pieces-de-detachees',
    nl: '/wisselstukken',
  },
  [pathnames.GIFTCARD]: {
    de: '/giftcard',
    en: pathnames.GIFTCARD,
    fr: '/giftcard',
    nl: '/giftcard',
  },
  [pathnames.ERROR404]: {
    de: '/404',
    en: pathnames.ERROR404,
    fr: '/404',
    nl: '/404',
  },

  // Store finder
  [pathnames.STOREFINDER]: {
    de: '/shops',
    en: pathnames.STOREFINDER,
    fr: '/magasins',
    nl: '/winkels',
  },
  [pathnames.STOREFINDER_DETAIL]: {
    de: '/shops/[id]',
    en: pathnames.STOREFINDER_DETAIL,
    fr: '/magasins/[id]',
    nl: '/winkels/[id]',
  },

  // My Account
  [pathnames.MY_ACCOUNT]: {
    de: '/myaccount',
    en: pathnames.MY_ACCOUNT,
    fr: '/myaccount',
    nl: '/myaccount',
  },
  [pathnames.MY_ACCOUNT_INFORMATION]: {
    de: '/myaccount/information',
    en: pathnames.MY_ACCOUNT_INFORMATION,
    fr: '/myaccount/information',
    nl: '/myaccount/information',
  },
  [pathnames.MY_ACCOUNT_ORDERS]: {
    de: '/myaccount/orders',
    en: pathnames.MY_ACCOUNT_ORDERS,
    fr: '/myaccount/orders',
    nl: '/myaccount/orders',
  },
  [pathnames.MY_ACCOUNT_ORDERS_DETAIL]: {
    de: '/myaccount/orders/[slug]',
    en: pathnames.MY_ACCOUNT_ORDERS_DETAIL,
    fr: '/myaccount/orders/[slug]',
    nl: '/myaccount/orders/[slug]',
  },
  [pathnames.MY_ACCOUNT_ADDRESSES]: {
    de: '/myaccount/addresses',
    en: pathnames.MY_ACCOUNT_ADDRESSES,
    fr: '/myaccount/addresses',
    nl: '/myaccount/addresses',
  },
  [pathnames.MY_ACCOUNT_PREFERENCES]: {
    de: '/myaccount/preferences',
    en: pathnames.MY_ACCOUNT_PREFERENCES,
    fr: '/myaccount/preferences',
    nl: '/myaccount/preferences',
  },
  [pathnames.MY_ACCOUNT_CARDS]: {
    de: '/myaccount/cards',
    en: pathnames.MY_ACCOUNT_CARDS,
    fr: '/myaccount/cards',
    nl: '/myaccount/cards',
  },
  [pathnames.WISHLIST]: {
    de: '/listen',
    en: pathnames.WISHLIST,
    fr: '/listes',
    nl: '/lijstjes',
  },
  [pathnames.WISHLIST_ID]: {
    de: '/listen/[...id]',
    en: pathnames.WISHLIST_ID,
    fr: '/listes/[...id]',
    nl: '/lijstjes/[...id]',
  },
  [pathnames.WISHLIST_ANONYMOUS]: {
    de: '/listen/anonymous',
    en: pathnames.WISHLIST_ANONYMOUS,
    fr: '/listes/anonymous',
    nl: '/lijstjes/anonymous',
  },

  // Password
  [pathnames.PASSWORD_FORGOTTEN_REQUEST]: {
    de: '/passwort-vergessen-anfordern',
    en: pathnames.PASSWORD_FORGOTTEN_REQUEST,
    fr: '/demande-mot-de-passe-oublie',
    nl: '/wachtwoord-vergeten-verzoek',
  },
  [pathnames.PASSWORD_FORGOTTEN]: {
    de: '/passwort-vergessen',
    en: pathnames.PASSWORD_FORGOTTEN,
    fr: '/mot-de-passe-oublie',
    nl: '/wachtwoord-vergeten',
  },

  // Pc configurator
  [pathnames.PC_CONFIGURATOR]: {
    de: '/pc-konfigurator',
    en: pathnames.PC_CONFIGURATOR,
    fr: '/configurateur-pc',
    nl: '/pc-configurator',
  },
  [pathnames.PC_CONFIGURATOR_DETAIL]: {
    de: '/pc-konfigurator/[id]',
    en: pathnames.PC_CONFIGURATOR_DETAIL,
    fr: '/configurateur-pc/[id]',
    nl: '/pc-configurator/[id]',
  },
  [pathnames.PC_CONFIGURATOR_PART_DETAIL]: {
    de: '/pc-konfigurator/[id]/[part]',
    en: pathnames.PC_CONFIGURATOR_PART_DETAIL,
    fr: '/configurateur-pc/[id]/[part]',
    nl: '/pc-configurator/[id]/[part]',
  },

  // Repairs
  [pathnames.REPAIRS]: {
    de: '/instandsetzung',
    en: pathnames.REPAIRS,
    fr: '/reparations',
    nl: '/reparaties',
  },
  [pathnames.REPAIRS_FOLLOW]: {
    de: '/instandsetzung/folgen',
    en: pathnames.REPAIRS_FOLLOW,
    fr: '/reparations/suivre',
    nl: '/reparaties/volgen',
  },
  [pathnames.REPAIRS_REQUEST]: {
    de: '/instandsetzung/anfrage',
    en: pathnames.REPAIRS_REQUEST,
    fr: '/reparations/demande',
    nl: '/reparaties/aanvraag',
  },
  [pathnames.REPAIRS_REQUEST_CONFIRMATION]: {
    de: '/instandsetzung/anfrage/bestatigung',
    en: pathnames.REPAIRS_REQUEST_CONFIRMATION,
    fr: '/reparations/demande/confirmation',
    nl: '/reparaties/aanvraag/bevestiging',
  },

  // Category
  [pathnames.CATEGORY]: {
    de: '/c/[...slug]',
    en: pathnames.CATEGORY,
    fr: '/c/[...slug]',
    nl: '/c/[...slug]',
  },

  // Checkout
  [pathnames.CHECKOUT_DELIVERY]: {
    de: '/bestellen/versandart',
    en: pathnames.CHECKOUT_DELIVERY,
    fr: '/commander/methode-de-livraison',
    nl: '/bestellen/levermethode',
  },
  [pathnames.CHECKOUT_LOGIN]: {
    de: '/bestellen/anmeldung',
    en: pathnames.CHECKOUT_LOGIN,
    fr: '/commander/login',
    nl: '/bestellen/login',
  },
  [pathnames.CHECKOUT_SHIPPING]: {
    de: '/bestellen/lieferadresse',
    en: pathnames.CHECKOUT_SHIPPING,
    fr: '/commander/livraison',
    nl: '/bestellen/leveradres',
  },
  [pathnames.CHECKOUT_OVERVIEW]: {
    de: '/bestellen/uberblick',
    en: pathnames.CHECKOUT_OVERVIEW,
    fr: '/commander/apercu',
    nl: '/bestellen/overzicht',
  },
  [pathnames.CHECKOUT_PAYMENT]: {
    de: '/bestellen/zahlung',
    en: pathnames.CHECKOUT_PAYMENT,
    fr: '/commander/paiement',
    nl: '/bestellen/betaling',
  },
  [pathnames.CHECKOUT_CONFIRMATION]: {
    de: '/bestellen/befestiging',
    en: pathnames.CHECKOUT_CONFIRMATION,
    fr: '/commander/confirmation',
    nl: '/bestellen/bevestiging',
  },
  [pathnames.CHECKOUT_PAYMENT_REMAINING]: {
    de: '/bestellen/zahlung/verbleibend',
    en: pathnames.CHECKOUT_PAYMENT_REMAINING,
    fr: '/commander/paiement/restant',
    nl: '/bestellen/betaling/resterend',
  },
  [pathnames.ACTIVATE]: {
    de: '/aktivierung',
    en: pathnames.ACTIVATE,
    fr: '/activation',
    nl: '/activatie',
  },
  [pathnames.CONTACT]: {
    de: '/kontakt',
    en: pathnames.CONTACT,
    fr: '/contact',
    nl: '/contact',
  },
  [pathnames.SERVICE]: {
    de: '/dienstleistungen',
    en: pathnames.SERVICE,
    fr: '/service',
    nl: '/diensten',
  },
  [pathnames.PRICE_GUARANTEE]: {
    de: '/preisgarantie',
    en: pathnames.PRICE_GUARANTEE,
    fr: '/garantie-de-prix',
    nl: '/prijs-garantie',
  },
  [pathnames.CASHBACKS]: {
    de: '/cashbacks',
    en: pathnames.CASHBACKS,
    fr: '/cashbacks',
    nl: '/cashbacks',
  },
  [pathnames.WARRANTY]: {
    de: '/garantie',
    en: pathnames.WARRANTY,
    fr: '/garantie',
    nl: '/garantie',
  },
  [pathnames.ABOUT_US]: {
    de: '/uber-uns',
    en: pathnames.ABOUT_US,
    fr: '/notre-histoire',
    nl: '/over-ons',
  },
  [pathnames.DEMODAYS]: {
    de: '/demodays',
    en: pathnames.DEMODAYS,
    fr: '/demodays',
    nl: '/demodays',
  },
  [pathnames.OUR_ASSORTMENT]: {
    de: '/unser-sortiment',
    en: pathnames.OUR_ASSORTMENT,
    fr: '/notre-assortiment',
    nl: '/ons-assortiment',
  },
  [pathnames.PRODUCT]: {
    de: '/p/[id]',
    en: pathnames.PRODUCT,
    fr: '/p/[id]',
    nl: '/p/[id]',
  },
  [pathnames.MANUALS]: {
    de: '/anleitungen',
    en: pathnames.MANUALS,
    fr: '/manuels',
    nl: '/handleidingen',
  },
  [pathnames.REPAIR_INDEX]: {
    de: pathnames.REPAIR_INDEX,
    en: pathnames.REPAIR_INDEX,
    fr: '/indice-de-reparabilite',
    nl: '/herstelbaarheidsindex',
  },
};

// Reverse the mapping to find the original pathname
export const delocalizedPathnames = Object.keys(localizedPathnames).reduce(
  (acc: Record<string, { [key: string]: Pathname }>, pathname) => {
    const localizedSlugs = localizedPathnames[pathname];
    Object.entries(localizedSlugs).forEach(([locale, slug]) => {
      if (!acc[slug]) {
        acc[slug] = {};
      }
      acc[slug][locale] = pathname as Pathname; // Reverse the mapping by swapping pathname and slug
    });
    return acc;
  },
  {},
);

export const PAYMENT_PROVIDER_PAGES: string[] = [
  pathnames.MULTISAFEPAY,
  pathnames.SANTANDER,
  pathnames.WORLDLINE,
  pathnames.ALMAPAY,
];

export const NO_INDEX_PAGES = Object.keys(localizedPathnames).filter((pathname) => {
  const pathnameObject = Object.values(localizedPathnames[pathname]);
  const uniquePathnames = Array.from(new Set(pathnameObject));
  // Check if there are different values for the locales
  return uniquePathnames.length > 1;
});
