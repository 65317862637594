import { combineReducers } from 'redux';
import cart from './modules/cart';
import category from './modules/category';
import checkout from './modules/checkout';
import cms from './modules/cms';
import compare from './modules/compare';
import configurator from './modules/configurator';
import localization from './modules/localization';
import myAccount from './modules/myAccount';
import onOrder from './modules/onOrder';
import partner from './modules/partner';
import product from './modules/product';
import search from './modules/search';
import store from './modules/store';
import user from './modules/user';

const combinedReducer = combineReducers({
  cart,
  category,
  checkout,
  cms,
  compare,
  configurator,
  localization,
  myAccount,
  onOrder,
  partner,
  product,
  search,
  store,
  user,
});

export default combinedReducer;
