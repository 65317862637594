import { ContactPageRequestDTO, SalesforceForm, SalesforceProduct } from 'types/Salesforce';
import config from '../config';

export const registerUserEmail = (email: string) => {
  if (window._etmc && email) {
    if (config.salesForce.orgId) window._etmc.push(['setOrgId', config.salesForce.orgId]);
    window._etmc.push(['setUserInfo', { email }]);
    window._etmc.push(['trackPageView']);
  }
};

export const getProductIds = (products: SalesforceProduct[]) => products?.map((product) => product.id) || [];

export const createContactPageRequestDTO = (
  values: Record<string, string>,
  formId: string,
  formType: SalesforceForm['formType'],
): ContactPageRequestDTO => {
  const {
    articleNumber,
    bankAccount,
    email,
    firstName,
    lastName,
    line1,
    line2,
    newArticleNumber,
    orderNumber,
    phone,
    poBox,
    postalCode,
    remarks,
    ticketNumber,
    town,
  } = values;

  const hasAdddress = line1 || line2 || poBox || town || postalCode;

  return {
    emailAddressCustomer: email || '',
    firstName: firstName || '',
    invoiceID: orderNumber || '',
    lastName: lastName || '',
    message: remarks || '',
    phoneNumber: phone || '',
    sku: articleNumber || '',
    substitutionSku: newArticleNumber || '',
    ticketNumber: ticketNumber || '',
    ...(hasAdddress && {
      addressCity: town || '',
      addressNumber: line2 || '',
      addressPO: poBox || '',
      addressPostalCode: postalCode || '',
      addressStreet: line1 || '',
    }),
    ...(bankAccount && { bankAccount }),
    formId,
    formType,
  };
};
