/* eslint-disable sort-keys-fix/sort-keys-fix */
import createThemeMap from '../../../utils/themeUtil';

export const sharedColors = createThemeMap({
  love: {
    100: '#ffb3b3',
    200: '#ff8080',
    300: '#cc0000',
    400: '#800000',

    transparent: {
      100: 'rgba(255, 179, 179, 0.5)',
    },
  },
  ocean: {
    100: '#dbf0e7',
    200: '#b7e1ce',
    300: '#6fc39d',
    400: '#49af81',
    500: '#1e4835',

    transparent: {
      100: 'rgba(219, 240, 231, 0.5)',
    },
  },
  pearl: {
    200: '#c9c6d2',
    300: '#a09baf',
    400: '#605b71',
    500: '#302d39',
  },
  shadow: {
    100: '#0000000d',
    300: '#00000080',
  },
  whisper: {
    100: '#f8f8f8',
    200: '#ebebeb',
    300: '#999999',
    400: '#4f4f4f',
    500: '#323232',

    transparent: {
      100: 'rgba(248, 248, 248, 0.5)',
      200: 'rgba(235, 235, 235, 0.8)',
    },
  },
  'alert-warning': {
    100: '#ffdc9b',
    200: '#ffdc9b40',
  },
  'alert-danger': {
    100: '#f07e3a',
    200: '#ef7b3726',
  },
  'alert-success': {
    100: '#49af81',
    200: '#b7e1ce',
  },
  accents: {
    nature: '#4ab082',
  },
  black: '#000000',
  white: '#ffffff',
  ghost: {
    800: 'rgba(255, 255, 255, 0.8)',
  },
  gold: '#ffc74c',
  lightblue: {
    800: 'rgba(204, 237, 254, 0.8)',
  },
});
