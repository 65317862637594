import { css } from 'styled-components';
import { brandSelect, color } from '../../functions';

const defaultValues = {
  'dropdown-text-color': css`
    ${color('whisper-400')};
  `,
};

const krefel = {};

const hifi = {};

const tones = {
  'dropdown-text-color': css`
    ${color('secondary-400')};
  `,
};

const brandSpecifics = {
  krefel,
  hifi,
  tones,
};

const dropdownSpecifics = {
  ...defaultValues,
  ...brandSelect(brandSpecifics),
};

export default dropdownSpecifics;
