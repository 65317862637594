import { brandSelect, color } from '../../functions';

const defaultValues = {
  'compare-menu-count-background': color('secondary-300'),
  'compare-menu-count-foreground': color('white'),
};

const krefel = {};

const hifi = {};

const tones = {
  'compare-menu-count-background': color('primary-300'),
  'compare-menu-count-foreground': color('black'),
};

const brandSpecifics = {
  krefel,
  hifi,
  tones,
};

const buttonSpecifics = {
  ...defaultValues,
  ...brandSelect(brandSpecifics),
};

export default buttonSpecifics;
