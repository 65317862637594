/* eslint-disable sort-keys-fix/sort-keys-fix */

const LocationTypes = { HOME: 'home', PDP: 'pdp' } as const;

export const LOCATIONS = {
  ...LocationTypes,
};

export const SALESFORCE_SCRIPT_ID = 'salesForceScript';
export const SALESFORCE_SETUP_SCRIPT_ID = 'salesForceSetupScript';
