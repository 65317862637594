import { css } from 'styled-components';
import { brandSelect, color } from '../../functions';

const defaultValues = {
  'active-color': css`
    ${color('primary-300')};
  `,
};

const krefel = {
  'active-color': css`
    ${color('primary-400')};
  `,
};

const hifi = {};

const tones = {};

const brandSpecifics = {
  krefel,
  hifi,
  tones,
};

const facetSpecifics = {
  ...defaultValues,
  ...brandSelect(brandSpecifics),
};

export default facetSpecifics;
