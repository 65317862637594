import config from 'config';
import {
  ANALYTIC_CATEGORIES,
  ANALYTIC_CODES,
  ANALYTIC_CURRENCIES,
  ANALYTIC_EVENTS,
  AnalyticEvent,
  AnalyticsPageType,
} from 'constants/analytics';
import { ATP_MESSAGES } from 'constants/atp';
import { isWebview } from 'constants/environment';
import { PRODUCT_CARRIER } from 'constants/product';
import { CartEntry } from 'types/Cart';
import { Order, OrderEntry } from 'types/Order';
import { Product } from 'types/Product';
import { log } from './loggerUtil';
import { prefixWithLocale } from './urlUtil';

const { platform } = config;

type Entry = {
  id?: string;
  quantity?: number;
};

const mapCartEntryToCorrectQuantity = (entries: Entry[]) =>
  entries?.reduce((previous: Entry[], current) => {
    const alreadyInArray = previous.find((prev) => prev.id === current.id);

    if (alreadyInArray) {
      alreadyInArray.quantity = (alreadyInArray.quantity ?? 0) + (current.quantity ?? 0);
    } else {
      previous.push(current);
    }

    return previous;
  }, []);

const pushToDataLayer = (gtmData: {
  ecommerce?: object;
  event: AnalyticEvent;
  page?: string | { slug?: string; type?: string };
}) => {
  if (window?.dataLayer) {
    const detectedEmbeddedData = {
      ...gtmData,
      ecommerce: {
        ...gtmData.ecommerce,
        isAppEvent: isWebview,
      },
    };

    try {
      window?.dataLayer?.push(detectedEmbeddedData);
    } catch (e) {
      log('GTM', `${gtmData?.event} failed`, e);
    }
  }
};

const createAddWishlistEvent = (event: AnalyticEvent, products: unknown[] = []) => ({
  ecommerce: {
    addToWishlist: {
      products,
    },
    currencyCode: ANALYTIC_CURRENCIES.EUR,
  },
  event,
});

const createAddEvent = (event: AnalyticEvent, products: unknown[] = []) => ({
  ecommerce: {
    add: {
      products,
    },
    currencyCode: ANALYTIC_CURRENCIES.EUR,
  },
  event,
});

const createRemoveEvent = (event: AnalyticEvent, products: unknown[] = []) => ({
  ecommerce: {
    currencyCode: ANALYTIC_CURRENCIES.EUR,
    remove: {
      products,
    },
  },
  event,
});

const createGiftCardInfoObject = (id: string, price?: number) => ({
  brand: 'Giftcard',
  carrier: PRODUCT_CARRIER.T,
  category: ANALYTIC_CATEGORIES.GIFTCARD,
  id,
  name: `${platform} Gift Card`,
  price,
  quantity: 1,
  variant: ATP_MESSAGES.TEMP_NO_STOCK,
});

const createProductInfoObject = (product: Product, position?: number, list?: unknown) => ({
  brand: product?.brand?.name,
  carrier: product?.carrier,
  category: product?.datalayerCategoryPath,
  id: product?.code,
  list,
  name: product?.name,
  position,
  price: product?.price?.value,
  quantity: product?.quantity ?? 1,
  variant: product?.atp?.stockMessage,
});

const createBasketProductArrayEntries = (entries: CartEntry[] | OrderEntry[], productOnOrderCart?: boolean) =>
  entries?.map((entry) => {
    const { basePrice, product, quantity } = entry;

    if (product?.code?.includes(ANALYTIC_CODES.GIFTCARD)) {
      return createGiftCardInfoObject(product?.code, basePrice?.value);
    }

    return {
      brand: product?.brand?.name || undefined,
      carrier: product?.carrier || undefined,
      category: product?.datalayerCategoryPath,
      id: product?.code || undefined,
      name: product?.name || undefined,
      partner: product?.partners?.[0]?.code || undefined,
      price: basePrice?.value || undefined,
      productOnOrderCart,
      quantity: quantity || 1,
      variant: product?.atp?.stockMessage || undefined,
    };
  });

export const createBasketProductArrayWithQuantities = (
  entries: OrderEntry[] | CartEntry[] = [],
  productOnOrderCart = false,
) => {
  const entriesWithQuantity = mapCartEntryToCorrectQuantity(
    createBasketProductArrayEntries(entries, productOnOrderCart),
  );

  return entriesWithQuantity;
};

export const pushRemoveFromCartEvent = (event: AnalyticEvent, product: Product) => {
  const productInfoObject = createProductInfoObject(product);
  pushToDataLayer(createRemoveEvent(event, [productInfoObject]));
};

export const pushAddToCartEvent = (
  event: AnalyticEvent,
  product: Product,
  position?: number,
  categoryList?: unknown,
) => {
  const productInfoObject = createProductInfoObject(product, position, categoryList);

  pushToDataLayer(createAddEvent(event, [productInfoObject]));
};

export const pushAddToWishlistEvent = (event: AnalyticEvent, product: Product) => {
  const productInfoObject = createProductInfoObject(product);

  pushToDataLayer(createAddWishlistEvent(event, [productInfoObject]));
};

export const pushPurchaseEvent = (order: Order) => {
  const {
    appliedOrderPromotions,
    appliedProductPromotions,
    code,
    deliveryMode,
    entries,
    paymentMode,
    subTotal,
    totalTax,
  } = order;
  const hasOrderPromotionsApplied = !!appliedOrderPromotions?.length;
  const hasProductPromotionsApplied = !!appliedProductPromotions?.length;

  const products = createBasketProductArrayWithQuantities(entries ?? []);

  const orderPromotions =
    (hasOrderPromotionsApplied &&
      appliedOrderPromotions.map(({ promotion }) => ({
        description: promotion.description,
        name: promotion.name,
      }))) ||
    [];

  const productPromotions =
    (hasProductPromotionsApplied &&
      appliedProductPromotions.map(({ promotion }) => ({
        description: promotion.description,
        name: promotion.name,
      }))) ||
    [];

  const promotions = [...orderPromotions, ...productPromotions];

  const hasPromotions = promotions.length > 0;

  const purchase = {
    ecommerce: {
      purchase: {
        actionField: {
          affiliation: ANALYTIC_CATEGORIES.ONLINE_STORE,
          deliveryMethod: deliveryMode?.name || undefined,
          id: code || undefined,
          paymentMethod: paymentMode?.name || paymentMode?.code || undefined,
          promotions: (hasPromotions && promotions) || undefined,
          revenue: subTotal?.value || undefined,
          shipping: deliveryMode?.deliveryCost?.value || undefined,
          tax: totalTax?.value || undefined,
        },
        products,
      },
    },
    event: ANALYTIC_EVENTS.PURCHASE,
  };

  pushToDataLayer(purchase);

  return products;
};

type PushVirtualPageViewParams = {
  pageUrl: string;
  previousPageAsPath: string;
  previousPageLocale: string;
};

export const pushVirtualPageView = ({ pageUrl, previousPageAsPath, previousPageLocale }: PushVirtualPageViewParams) => {
  const event = {
    event: ANALYTIC_EVENTS.VIRTUAL_PAGE_VIEW,
    page: pageUrl,
    previousPage: prefixWithLocale(previousPageAsPath, previousPageLocale),
  };

  pushToDataLayer(event);
};

export const pushPageType = (pageType: AnalyticsPageType, pageSlug?: string) => {
  if (pageType) {
    const event = {
      event: ANALYTIC_EVENTS.PAGE_VIEW,
      page: {
        slug: pageSlug,
        type: pageType,
      },
    };
    pushToDataLayer(event);
  }
};
