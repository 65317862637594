import { API_PARAM_FIELDS, ApiParamFields } from 'constants/api';
import {
  Category,
  CategoryInfoResponse,
  CategoryPromotedBrandsResponse,
  CategorySiloResponse,
  CategorySimpleWithImage,
} from 'types/Category';
import { FacetSearchResult } from 'types/Facet';
import { CategoryInfoLinksResponse } from 'types/Links';
import { apiClient } from '../../helpers/ApiClient';
import { log, logErrorResponse } from '../../utils/loggerUtil';
import { checkPageSize } from '../../utils/pageUtil';

export type SearchCategoryParams = {
  categoryCode: string;
  currentPage?: string | number;
  fields?: ApiParamFields;
  locale: string;
  pageSize?: string | number;
  query?: string | string[];
  sort?: string | string[];
  withContentTiles?: boolean;
};

const searchCategory = async ({
  categoryCode,
  currentPage,
  fields,
  locale,
  pageSize,
  query,
  sort,
  withContentTiles,
}: SearchCategoryParams) => {
  const pageResults = checkPageSize(Number(pageSize), withContentTiles);

  const params = {
    currentPage: currentPage || '0',
    fields,
    lang: locale,
    pageSize: pageResults,
    query,
    sort,
  };

  try {
    const { data } = await apiClient<FacetSearchResult>({
      params,
      url: `/categories/${categoryCode}/products`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Category Connector', error, `Error searching category with code ${categoryCode}`);
    throw error;
  }
};

export type FetchCategoryParams = {
  categoryCode: string;
  fields?: ApiParamFields;
  locale: string;
};

const fetchCategory = async ({ categoryCode, fields, locale }: FetchCategoryParams) => {
  try {
    const { data } = await apiClient<Category>({
      params: {
        fields,
        lang: locale,
        sorted: true,
      },
      url: `/categories/${categoryCode}`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Category Connector', error, `Error getting category with code ${categoryCode}`);
    throw error;
  }
};

export type FetchCategorySubCategoriesParams = {
  categoryCode: string;
  locale: string;
};

const fetchCategorySubCategories = async ({ categoryCode, locale }: FetchCategorySubCategoriesParams) => {
  try {
    const { data } = await apiClient<CategorySimpleWithImage>({
      params: {
        fields: API_PARAM_FIELDS.L1,
        lang: locale,
        sorted: true,
        topLevel: true,
      },
      url: `/categories/${categoryCode}`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Category Connector', error, `Error getting category with code ${categoryCode}`);
    throw error;
  }
};

const getCategoryInfoUsingPath = async (categoryPath: string, locale: string) => {
  log('getCategoryInfoUsingPath', `Fetching category info for path: ${categoryPath} and locale: ${locale}`);
  try {
    const { data } = await apiClient<CategoryInfoResponse>({
      params: {
        categoryPath,
        fields: 'FULL',
        lang: locale,
      },
      url: `/categories/code`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Category Connector', error, `Error getting category with paths ${categoryPath}`);
    throw error;
  }
};

export type FetchCategorySiloParams = {
  categoryCode: string;
  locale: string;
};

const fetchCategorySilo = async ({ categoryCode, locale }: FetchCategorySiloParams) => {
  try {
    const { data } = await apiClient<CategorySiloResponse>({
      params: {
        lang: locale,
      },
      url: `/categories/${categoryCode}/silo`,
    });

    return data?.categories;
  } catch (error) {
    logErrorResponse('Category Connector', error, `Error getting  silo for category with code ${categoryCode}`);
    throw error;
  }
};

const fetchPopularCategories = async (pageSize = 500) => {
  try {
    const { data } = await apiClient({
      params: {
        pageSize,
      },
      url: `/categories/popular`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Category Connector', error, 'Error fetching popular categories');
    throw error;
  }
};

export type FetchCategoryInfoLinksParams = {
  categoryCode: string;
  locale: string;
};

const fetchCategoryInfoLinks = async ({ categoryCode, locale }: FetchCategoryInfoLinksParams) => {
  try {
    const { data } = await apiClient<CategoryInfoLinksResponse>({
      params: {
        code: categoryCode,
        fields: 'FULL',
        lang: locale,
      },
      url: `/links/search`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Category Connector', error, `Error fetching infoLinks for category with code: ${categoryCode}`);
    throw error;
  }
};

export type FetchCategoryPromotedBrandsParams = {
  categoryCode: string;
  locale: string;
};

const fetchCategoryPromotedBrands = async ({ categoryCode, locale }: FetchCategoryPromotedBrandsParams) => {
  try {
    const { data } = await apiClient<CategoryPromotedBrandsResponse>({
      params: {
        fields: 'FULL',
        lang: locale,
      },
      url: `/categories/${categoryCode}/promotedbrands`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Category Connector', error, `Error fetching promoted for category with code: ${categoryCode}`);
    throw error;
  }
};

export {
  fetchCategory,
  fetchCategoryInfoLinks,
  fetchCategoryPromotedBrands,
  fetchCategorySilo,
  fetchCategorySubCategories,
  fetchPopularCategories,
  getCategoryInfoUsingPath,
  searchCategory,
};
