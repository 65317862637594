/* eslint-disable sort-keys-fix/sort-keys-fix */
import { css } from 'styled-components';
import { spacer, color } from '../functions';

const variables = {
  variable: {
    'aspect-ratio-16-9-padding': '56.25%',
    border: css`
      // There is currently no other way of then this approach.
      border: 1px solid ${color('whisper-200')};
    `,
    'border-bottom': css`
      border-bottom: 1px solid ${color('whisper-200')};
    `,
    'border-color': color('whisper-200'),
    'border-darker': css`
      border: 1px solid ${color('whisper-300')};
    `,
    'border-dashed': css`
      border: 1px dashed ${color('whisper-200')};
    `,
    'border-left': css`
      border-left: 1px solid ${color('whisper-200')};
    `,
    'border-radius': spacer('25'),
    'border-radius-leaf': '11px 0px 11px 0px',
    'border-radius-long': spacer('50'),
    'border-radius-long-extra': spacer('75'),
    'border-right': css`
      border-right: 1px solid ${color('whisper-200')};
    `,
    'border-thick': css`
      border: 2px solid ${color('whisper-200')};
    `,
    'border-top': css`
      border-top: 1px solid ${color('whisper-200')};
    `,
    'border-transparent': '1px solid transparent',
    'border-width': '1px',
    'box-shadow': '0 0 4px rgba(0, 0, 0, 0.25)',
    'box-shadow-extra-long': '0px -5px 14px -5px rgba(10, 35, 101, 0.09)',
    'box-shadow-inset': 'inset 0 0 2px rgba(0, 0, 0, 0.25);',
    'box-shadow-soft': '0 0 4px rgba(0, 0, 0, 0.1)',
    'image-gradient': 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)',
    'long-box-shadow': '0px 0px 14px rgba(10, 35, 101, 0.09)',
    'remove-scrollbar': css`
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    `,
    transition: 'all 250ms ease-in-out',
    'transition.1': 'all 0.1s ease-in-out',
  },
};

export default variables;
