import { isWebview } from 'constants/environment';
import config from '../config';
import { ANALYTIC_EVENTS } from '../constants/analytics';
import { PAYMENT_METHODS } from '../constants/checkout';
import { PRODUCT_STOCK_MESSAGE } from '../constants/product';
import { createBasketProductArrayWithQuantities } from './analyticsUtil';
import { groupEntries } from './orderUtil';

/**
 * Function which returns the gift card product object
 * @param {string} id - the gift card id
 * @param {number} price - the gift card price
 * @param {boolean} withQuantity - the withQuantity flag
 * @returns {object} the gift card product object
 * @deprecated
 */
function createGiftCardProduct(id, price, withQuantity = true) {
  return {
    brand: 'Giftcard',
    carrier: 'T',
    category: 'Gift cards',
    id,
    name: 'krefel Gift Card',
    price,
    quantity: withQuantity ? 1 : undefined,
    variant: 'atp_message_nostock_temp',
  };
}

export const createProductInfoObject = (
  name,
  code,
  price,
  brand,
  dataLayerCategoryPath,
  atp,
  carrier,
  quantity,
  position,
  categoryList,
  asString = true
) => {
  const productInfoObject = {
    brand: brand?.name,
    carrier,
    category: dataLayerCategoryPath,
    id: code,
    list: categoryList,
    name,
    position,
    price: price?.value || price,
    quantity: quantity || 1,
    variant: atp?.stockMessage,
  };

  if (!asString) {
    return productInfoObject;
  }

  return JSON.stringify(productInfoObject);
};

export const pushProductAddToCartToDataLayer = (product, event, list, position) => {
  const productInfo = createProductInfoObject(product, position, list, false, true);

  const gtmData = {
    ecommerce: {
      currencyCode: 'EUR',
      product: {
        actionField: {
          isAppEvent: isWebview,
          product: productInfo,
        },
      },
    },
    event,
  };

  if (global?.dataLayer) {
    global?.dataLayer?.push(gtmData);
  }
};

export function createProductImpressionObject(product, position, categoryList, asString = true) {
  /* Search list */
  let list;
  if (categoryList) {
    list = categoryList;
  } else {
    list = product?.categories?.length > 0 ? product.categories[0].name : undefined;
  }

  const ProductImpressionObject = {
    brand: product?.brand?.name || product?.brand,
    carrier: product?.carrier ? product.carrier : undefined,
    category: product?.datalayerCategoryPath ? product.datalayerCategoryPath : undefined,
    id: product?.code ? product.code : undefined,
    list,
    name: product?.name ? product.name : undefined,
    position,
    price: product?.price?.value || product?.priceValue,
    variant: product?.atp?.stockMessage || product?.stockMessage,
  };

  if (!asString) {
    return ProductImpressionObject;
  }
  return JSON.stringify(ProductImpressionObject);
}

export const createHitImpressionObject = (product, position, categoryList, asString = true) => {
  /* Search list */
  let list;
  if (categoryList) {
    list = categoryList;
  } else {
    list = product.categories && product.categories.length > 0 ? product.categories[0].name : undefined;
  }

  const ProductImpressionObject = {
    brand: product.brand ? product.brand.name : undefined,
    carrier: product.carrier ? product.carrier : undefined,
    category: product.datalayerCategoryPath ? product.datalayerCategoryPath : undefined,
    id: product.code ? product.code : undefined,
    list,
    name: product.name ? product.name : undefined,
    position,
    price: product.price ? product.price.value : undefined,
    variant: product.atp ? product.atp.stockMessage : undefined,
  };

  if (!asString) {
    return ProductImpressionObject;
  }
  return JSON.stringify(ProductImpressionObject);
};

/**
 * Function which creates the CMS product impression object
 * @param {Array} products - the cms product list
 * @param {string} pageName - the current page name
 * @returns {object} the CMS product impression object
 */
export const createCMSProductImpression = (products = [], pageName) => {
  if (products?.length === 0) return null;

  const CMSProductList = products?.map((product, index) =>
    createProductImpressionObject(product, index, pageName, false)
  );

  if (CMSProductList?.length > 0) {
    return {
      ecommerce: {
        currencyCode: 'EUR',
        impressions: CMSProductList,
      },
      event: ANALYTIC_EVENTS.PRODUCT_IMPRESSION,
    };
  }
  return null;
};

/**
 * Function which creates the gift card event object
 * @param {object} giftCard - the gift card object
 * @param {object} giftCardDetails - the gift card details
 * @returns {object} the gtm gift card event object
 */
export function getGiftCardEventObject(giftCard, giftCardDetails, isEmbedded = false) {
  const product = createGiftCardProduct(giftCard.code, Number(giftCardDetails.amount));
  return {
    ecommerce: {
      add: {
        products: [product],
      },
      currencyCode: 'EUR',
      giftcard: {
        actionField: {
          isAppEvent: isEmbedded,
        },
      },
    },
    event: ANALYTIC_EVENTS.ADD_TO_CART,
  };
}

/**
 * Function which creates the criteo home page impression object.
 * @returns {object} the criteo home page impression object.
 */
export const createCriteoHomePageImpression = (userEmail) => ({
  crto: {
    email: userEmail || '',
  },
  event: 'crto_homepage',
});

/**
 * Function which sets the localized criteo account number to the datalayer
 * @param {string} locale - The current locale.
 * @returns {undefined}
 */
export const setCriteoAccountNumber = (locale) => {
  const gtmData = {
    crto: {
      account: config?.criteo?.account?.[locale],
    },
    event: 'setAccount',
  };

  global?.dataLayer?.push(gtmData);
};

/**
 * Function which returns the onOrder tracking object
 * @param {Array} products - the products array
 * @param {string} deliveryMethod - the delivery method
 * @returns {object} the onOrder tracking object
 */
export function createCartTrackingObject(products = [], deliveryMethod = "", isEmbedded = false) {
  if (products && products.length > 0 && deliveryMethod) {
    return {
      ecommerce: {
        checkout: {
          actionField: {
            deliveryMethod,
            isAppEvent: isEmbedded,
            step: '1',
          },
          products,
        },
      },
      event: ANALYTIC_EVENTS.CHECKOUT,
    };
  }
  return null;
}

/**
 * the create payment tracking object function
 * @param {object} cart - the cart object
 * @param {object} paymentMethod - the payment method object
 * @param {boolean} hasGiftCards - the has gift cards flag
 * @return {object} the payment tracking object
 */
export function createPaymentTrackingObject(cart, paymentMethod, hasGiftCards = false, isEmbedded = false) {
  if (cart && cart.code && cart.entries && paymentMethod && paymentMethod.name) {
    const products = createBasketProductArrayWithQuantities(cart.entries, cart.productOnOrderCart);

    let paymentMode;
    if (hasGiftCards && paymentMethod.code === PAYMENT_METHODS.GIFTCARD) {
      paymentMode = paymentMethod.name;
    } else if (hasGiftCards && paymentMethod.code !== PAYMENT_METHODS.GIFTCARD) {
      paymentMode = `${paymentMethod.name}, Gift Card`;
    } else {
      paymentMode = paymentMethod.name;
    }
    return {
      ecommerce: {
        checkout: {
          actionField: {
            action: 'checkout',
            id: cart && cart.code ? cart.code : undefined,
            isAppEvent: isEmbedded,
            paymentMethod: paymentMode,
            step: '3',
          },
          products,
        },
      },
      event: ANALYTIC_EVENTS.CHECKOUT,
    };
  }
  return null;
}

/**
 * Function which creates the sales force add to cart object
 * @param {Array} entries - the cart entries
 * @param {object=} order - the order object
 * @returns {object} the sales force add to cart object
 */
export function createSalesForceAddToCartObject(entries, order) {
  const groupedEntries = groupEntries(entries) || [];
  const cartItems = [];

  const orderNumber = order && order.code;
  const shipping = order && order.deliveryCost && order.deliveryCost.value;
  const discount = order && order.totalDiscounts && order.totalDiscounts.value;

  if (groupedEntries && groupedEntries.length > 0) {
    groupedEntries.forEach((entry) => {
      const item = entry && entry.product && entry.product.code;
      const quantity = entry && entry.quantity;
      const price = entry && entry.basePrice && entry.basePrice.value;
      cartItems.push({ item, price, quantity });
    });
  }

  return {
    cart: cartItems,
    discount,
    order_number: orderNumber,
    shipping,
  };
}

/**
 * Function which returns the localized value for a given locale
 * @param {object} localizedValues - the localized values object
 * @param {string=} locale - the current locale
 * @returns {string|undefined} the localized value
 */
export function getLocalizedValue(localizedValues, locale) {
  let localizedValue;

  if (localizedValues && localizedValues.entry && localizedValues.entry.length > 0 && locale) {
    const localizedValueObject = localizedValues.entry.find((value) => value.key === locale);
    localizedValue = localizedValueObject && localizedValueObject.value;
  }

  return localizedValue;
}

/**
 * Function which creates the sales force product object
 * @param {object} product - the product object
 * @param {string=} locale - the current locale
 * @param {boolean=} withDescription - the with description flag
 * @returns {object|null} the sales force product object
 */
export function createSalesForceProductObject(product, locale, withDescription = true) {
  let salesForceProduct = null;
  if (product) {
    let price = `${product.price && product.price.value}`;
    let salePrice = '';
    const canBeSold =
      product.canBeSold ||
      (product.atp && product.atp.stockMessage && product.atp.stockMessage === PRODUCT_STOCK_MESSAGE.STOCK);

    if (product.strikePrice && product.strikePrice.value) {
      salePrice = `${product.price && product.price.value}`;
      price = `${product.strikePrice.value}`;
    }

    salesForceProduct = {
      available: `${canBeSold}`,
      image_url: product.images && product.images.length > 0 && product.images[0] && product.images[0].url,
      item: product.code,
      item_type: 'product',
      name: product.name,
      price,
      sale_price: salePrice,
    };

    if (withDescription && product.description) {
      salesForceProduct.description = product.description;
    }

    if (locale) {
      const currentLocale = locale === 'nl' ? 'fr' : 'nl';
      const localizedName = getLocalizedValue(product.localizedNames, currentLocale);
      const localizedUrl = getLocalizedValue(product.localizedFullUrls, currentLocale);

      if (localizedName) {
        salesForceProduct[`locale_${currentLocale}_name`] = localizedName;
      }

      if (localizedUrl) {
        salesForceProduct[`locale_${currentLocale}_url`] = localizedUrl;
      }

      if (product.url) {
        salesForceProduct.url = `${config.env.httpOrigin}/${locale}${product.url}`;
      }
    }
  }
  return salesForceProduct;
}

/**
 * Function which creates the sales force content object
 * @param {object} cmsPage - the cms page object
 * @param {string} pageName - the page name
 * @param {string=} locale - the current locale
 * @returns {object|null} the sales force content object
 */
export function createSalesForceContentObject(cmsPage, pageName, locale) {
  let salesForceContent = null;
  if (cmsPage && cmsPage.qualifier && pageName) {
    salesForceContent = {
      item: cmsPage.qualifier,
      item_type: 'content',
      name: pageName,
    };

    if (locale) {
      const currentLocale = locale === 'nl' ? 'fr' : 'nl';
      const localizedUrl = getLocalizedValue(cmsPage.localizedFullUrls, currentLocale);

      if (localizedUrl) {
        salesForceContent[`locale_${currentLocale}_url`] = localizedUrl;
      }
    }
  }
  return salesForceContent;
}

/**
 * Function which creates the sales force product list
 * @param {Array} products - the product array
 * @param {string} locale - the current locale
 * @returns {Array} the sales force product list
 */
export function createSalesForceProductArray(products = [], locale) {
  const salesForceProducts = [];

  if (products && products.length > 0) {
    products.forEach((product) => {
      salesForceProducts.push(createSalesForceProductObject(product, locale, false));
    });
  }
  return salesForceProducts;
}
