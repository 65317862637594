import { Variants } from 'framer-motion';

export const DEFAULT_TRANSITION_TIMING = {
  duration: 0.4,
  ease: [0.15, 1, 0.3, 1],
};

export const DEFAULT_TRANSITION_TIMING_FAST = {
  duration: 0.1,
  ease: [0.15, 1, 0.5, 1],
};

export const DROPDOWN_ANIMATION: Variants = {
  animate: () => ({
    opacity: 1,
    pointerEvents: 'auto',
    y: 0,
  }),
  exit: () => ({
    opacity: 0,
    pointerEvents: 'none',
    y: -10,
  }),
  initial: () => ({
    opacity: 0,
    pointerEvents: 'none',
    y: -10,
  }),
};

export const EXPAND_ANIMATION: Variants = {
  collapsed: () => ({
    pointerEvents: 'none',
    y: '100%',
  }),
  expanded: () => ({
    pointerEvents: 'auto',
    y: '0',
  }),
};

export const SLIDE_IN_LEFT_ANIMATION = {
  enter: () => ({
    x: '0%',
  }),
  exit: () => ({
    x: '-100%',
  }),
};

export const SLIDE_IN_RIGHT_ANIMATION = {
  enter: () => ({
    x: '0%',
  }),
  exit: () => ({
    x: '100%',
  }),
};

export const SLIDE_IN_UP_ANIMATION = {
  enter: () => ({
    y: '0%',
  }),
  exit: () => ({
    y: '100%',
  }),
};

export const SLIDE_IN_UP_CENTER_ANIMATION = {
  enter: () => ({
    x: '-50%',
    y: '0%',
  }),
  exit: () => ({
    x: '-50%',
    y: '100%',
  }),
};

export const SLIDE_IN_DOWN_ANIMATION = {
  enter: () => ({
    y: '0%',
  }),
  exit: () => ({
    y: '-100%',
  }),
};

export const SLIDE_IN_DOWN_CENTER_ANIMATION = {
  enter: () => ({
    x: '-50%',
    y: '0%',
  }),
  exit: () => ({
    x: '-50%',
    y: '-100%',
  }),
};
