import { BRAND_SPECIFIC_DEFAULT_LOCALE, DEFAULT_LOCALE } from 'constants/i18n';
import { useRouter as useNextRouter } from 'next/router';

export const useRouter = (fallbackLocale = BRAND_SPECIFIC_DEFAULT_LOCALE) => {
  const { locale, locales: routerLocales, ...props } = useNextRouter();

  const locales = routerLocales?.filter((locale) => locale !== DEFAULT_LOCALE) || [];

  return { locale: locale || fallbackLocale, locales, ...props };
};

export type NextRouter = ReturnType<typeof useRouter>;

export default useRouter;
