import { devtools } from 'zustand/middleware';
import { create } from 'zustand';

interface ConfigurationState {
  hasReceivedEvent: boolean;
}

export interface ConfigurationStore extends ConfigurationState {
  actions: {
    setHasReceivedEvent: (value: boolean) => void;
  };
}

const initialState: ConfigurationState = {
  hasReceivedEvent: false,
};

export const useConfigurationStore = create<ConfigurationStore>()(
  devtools(
    (set) => ({
      ...initialState,
      actions: {
        setHasReceivedEvent: (hasReceivedEvent) => {
          set(() => ({
            hasReceivedEvent,
          }));
        },
      },
    }),
    { name: 'configurationStore' }
  )
);

export const useConfigurationStoreActions = () => useConfigurationStore((state) => state.actions);
export const useHasReceivedEvent = () => useConfigurationStore((state) => state.hasReceivedEvent);
