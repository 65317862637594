import { brandSelect, color, specific } from '../../functions';

const defaultValues = {
  'border-width': '1px',
  height: '116px',
  'hamburger-color': specific('mainbar', 'text-color'),
  'border-color': color('white'),
  'text-color': color('white'),
};

const krefel = {
  'background-color': color('white'),
  'text-color': color('primary-400'),
};

const hifi = { 'background-color': color('secondary-300') };

const tones = {
  'background-color': color('white'),
  'border-color': color('whisper-200'),
  'text-color': color('secondary-400'),
};

const brands = {
  krefel,
  hifi,
  tones,
};

const mainbarSpecifics = {
  ...defaultValues,
  ...brandSelect(brands),
};

export default mainbarSpecifics;
