export const GRANT_TYPES = {
  APPLE: process.env.NEXT_PUBLIC_APPLE,
  CLIENT_CREDENTIALS: process.env.NEXT_PUBLIC_CLIENT_CREDENTIALS,
  FACEBOOK: process.env.NEXT_PUBLIC_FACEBOOK,
  GOOGLE: process.env.NEXT_PUBLIC_GOOGLE,
  PASSWORD: process.env.NEXT_PUBLIC_PASSWORD,
  REFRESH_TOKEN: process.env.NEXT_PUBLIC_REFRESH_TOKEN,
};

export const SOCIAL_TYPES = {
  APPLE: 'apple',
  EMAIL: 'email',
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
} as const;

export type SocialLoginType = (typeof SOCIAL_TYPES)[keyof typeof SOCIAL_TYPES];

// Useful for future Auth refactor
// export type OAuthCredentials =
//   | OAuthAnonymousCredentials
//   | OAuthEmailCredentials
//   | OAuthGoogleCredentials
//   | OAuthAppleCredentials
//   | OAuthFacebookCredentials;

// type OAuthAnonymousCredentials = undefined;

// type OAuthEmailCredentials = {
//   email: string;
//   password: string;
// };

// type OAuthGoogleCredentials = {
//   accessToken: string;
//   email: string;
//   googleId: string;
// };

// type OAuthAppleCredentials = {
//   authorization: { id_token: string };
// };

// type OAuthFacebookCredentials = {
//   accessToken: string;
//   email: string;
//   id: string;
// };
