export const API_PARAM_FIELDS = {
  BASIC: 'BASIC',
  DEFAULT: 'DEFAULT',
  FULL: 'FULL',
  L1: 'L1',
  L2: 'L2',
  PDP: 'PDP',
} as const;

export type ApiParamFields = (typeof API_PARAM_FIELDS)[keyof typeof API_PARAM_FIELDS];
