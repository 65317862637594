/* eslint-disable sort-keys-fix/sort-keys-fix */
import { css } from 'styled-components';
import { brandSelect, color } from '../../functions';

const defaultValues = {
  // Primary
  'primary-color': css`
    ${color('primary-300')};
  `,
  'primary-color-hover': css`
    ${color('primary-400')};
  `,
  'primary-font-color': css`
    ${color('white')};
  `,
  'primary-ring-color': css`
    ${color('primary-transparent-300')}
  `,

  // Secondary
  'secondary-font-color': css`
    ${color('white')};
  `,
  'secondary-color': css`
    ${color('secondary-300')};
  `,
  'secondary-color-hover': css`
    ${color('secondary-400')};
  `,
  'secondary-ring-color': css`
    ${color('secondary-transparent-300')}
  `,

  // Primary alt
  'primary-color-alt': css`
    ${color('primary-300')};
  `,
  'primary-color-alt-hover': css`
    ${color('primary-200')};
  `,
  'primary-font-alt-color': css`
    ${color('white')};
  `,

  // Secondary alt
  'secondary-color-alt': css`
    ${color('secondary-400')};
  `,
  'secondary-color-alt-hover': css`
    ${color('secondary-400')};
  `,

  // Whisper
  'whisper-font-color': css`
    ${color('white')};
  `,
  'whisper-color': css`
    ${color('whisper-200')};
  `,
  'whisper-color-hover': css`
    ${color('whisper-300')};
  `,

  // Whisper alt
  'whisper-font-color-alt': css`
    ${color('black')};
  `,
  'whisper-color-alt': css`
    ${color('whisper-100')};
  `,
  'whisper-color-hover-alt': css`
    ${color('whisper-200')};
  `,
};

const krefel = {
  'primary-color': css`
    ${color('secondary-300')};
  `,
  'primary-color-hover': css`
    ${color('secondary-400')};
  `,
  'primary-ring-color': css`
    ${color('secondary-transparent-300')}
  `,

  'secondary-color': css`
    ${color('primary-400')};
  `,
  'secondary-color-hover': css`
    ${color('primary-400')};
  `,
  'secondary-ring-color': css`
    ${color('primary-transparent-300')}
  `,

  'secondary-color-alt': css`
    ${color('primary-300')};
  `,
  'secondary-color-alt-hover': css`
    ${color('primary-400')};
  `,
  'secondary-ring-alt-color': css`
    ${color('primary-transparent-300')}
  `,
};

const hifi = {
  'secondary-color-alt': css`
    ${color('secondary-400')};
  `,
};

const tones = {
  'primary-font-color': css`
    ${color('black')};
  `,
};

const brandSpecifics = {
  krefel,
  hifi,
  tones,
};

const buttonSpecifics = {
  ...defaultValues,
  ...brandSelect(brandSpecifics),
};

export default buttonSpecifics;
