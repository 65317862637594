export const createSolrQuery = (query: string, filterOnStock = false, pushCnCToBack = false) => {
  if (!query) return null;

  const prefixDelimiter = query.startsWith(':') ? '' : ':';
  const suffixDelimiter = query.endsWith(':') ? '' : ':';
  const queryWithFilters = `${pushCnCToBack ? `stockMessageScore${prefixDelimiter}` : ''}${query}${
    filterOnStock ? `${suffixDelimiter}stockMessage:atp_message_stock` : ''
  }`;

  return queryWithFilters;
};

export const parseSolrQuery = (query: string) => {
  const queryParts = query.split(':');
  const queryObj: Record<string, string | undefined> = {};

  for (let i = 0; i < queryParts.length; i += 2) {
    const key = queryParts[i] === '' ? 'sort' : queryParts[i];
    queryObj[key] = queryParts[i + 1];
  }

  return queryObj;
};

export const updateSolrQuery = (query: string, key: string, value: string | undefined) => {
  const parsedQuery = parseSolrQuery(query);
  if (value === undefined) {
    delete parsedQuery[key];
  } else {
    parsedQuery[key] = value;
  }
  return encodeSolrQuery({ ...parsedQuery });
};

export const encodeSolrQuery = (queryObject: Record<string, string | undefined>) => {
  const { sort, ...otherQueryParams } = queryObject;
  if (!sort && Object.keys(otherQueryParams).length === 0) return '';
  const mappedSort = `:${sort ?? 'relevance'}`;
  const otherEntries = Object.entries(otherQueryParams).map(([key, value]) => `${key}:${value}`);
  return [mappedSort, ...otherEntries].filter(Boolean).join(':');
};

// Solr price range format: [min TO max]
export const parseSolrPriceRange = (priceRange: string) => {
  const [min, max] = priceRange.replace(/\[|\]/g, '').split(' TO ');
  return { max: Number(max), min: Number(min) };
};
