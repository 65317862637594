import config from '../config';

const { platform } = config;

export const DELIVERY_METHODS = {
  BPOST_DELIVERY: `${platform.toUpperCase()}_BPOST_DELIVERY`,
  BPOST_DELIVERY_EVENING: `${platform.toUpperCase()}_BPOST_DELIVERY_EVENING`,
  BPOST_PICK_UP: `${platform.toUpperCase()}_BPOST_PICK_UP`,
  CARGOVELO_DELIVERY: `${platform.toUpperCase()}_CARGOVELO_DELIVERY`,
  DIRECT_DELIVERY: `${platform.toUpperCase()}_DIRECT_DELIVERY`,
  DPD_DELIVERY: `${platform.toUpperCase()}_DPD_DELIVERY`,
  DPD_DELIVERY_BE: `${platform.toUpperCase()}_DPD_DELIVERY_BE`,
  DPD_DELIVERY_LU: `${platform.toUpperCase()}_DPD_DELIVERY_LU`,
  DPD_PACK_UP: `${platform.toUpperCase()}_DPD_PACK_UP`,
  EXPRESS_DELIVERY: `${platform.toUpperCase()}_EXPRESS`,
  FEDEX_DELIVERY: `${platform.toUpperCase()}_FEDEX_DELIVERY`,
  FEDEX_DELIVERY_BE: `${platform.toUpperCase()}_FEDEX_DELIVERY_BE`,
  FEDEX_DELIVERY_LU: `${platform.toUpperCase()}_FEDEX_DELIVERY_LU`,
  FEDEX_PACK_UP: `${platform.toUpperCase()}_FEDEX_PACK_UP`,
  GLS_DELIVERY: `${platform.toUpperCase()}_GLS_DELIVERY`,
  HOME_DELIVERY: `${platform.toUpperCase()}_HOME_DELIVERY`,
  HQ_PICK_UP: `${platform.toUpperCase()}_HQ_PICK_UP`,
  LIFT_DELIVERY: `${platform.toUpperCase()}_LIFT_DELIVERY`,
  PICK_UP_IN_STORE: `${platform.toUpperCase()}_PICK_UP_IN_STORE`,
  POSTLU_DELIVERY: `${platform.toUpperCase()}_POSTLU_DELIVERY`,
  POSTLU_PACK_UP: `${platform.toUpperCase()}_POSTLU_PACK_UP`,
  SAMEDAY_DELIVERY: `${platform.toUpperCase()}_SAMEDAY_DELIVERY`,
  STORE_DELIVERY: `${platform.toUpperCase()}_DELIVERY`,
  TONES_DELIVERY: `${platform.toUpperCase()}_TONES_DELIVERY`,
} as const;
export type DeliveryMethodCode = (typeof DELIVERY_METHODS)[keyof typeof DELIVERY_METHODS];

export const DELIVERY_METHODS_PICKUP: DeliveryMethodCode[] = [
  DELIVERY_METHODS.BPOST_PICK_UP,
  DELIVERY_METHODS.POSTLU_PACK_UP,
  DELIVERY_METHODS.DPD_PACK_UP,
  DELIVERY_METHODS.FEDEX_PACK_UP,
  DELIVERY_METHODS.PICK_UP_IN_STORE,
];

export const DELIVERY_METHODS_HOME: DeliveryMethodCode[] = [
  DELIVERY_METHODS.STORE_DELIVERY,
  DELIVERY_METHODS.BPOST_DELIVERY,
  DELIVERY_METHODS.BPOST_DELIVERY_EVENING,
  DELIVERY_METHODS.CARGOVELO_DELIVERY,
  DELIVERY_METHODS.DPD_DELIVERY,
  DELIVERY_METHODS.DPD_DELIVERY_LU,
  DELIVERY_METHODS.DPD_DELIVERY_BE,
  DELIVERY_METHODS.FEDEX_DELIVERY,
  DELIVERY_METHODS.FEDEX_DELIVERY_LU,
  DELIVERY_METHODS.FEDEX_DELIVERY_BE,
  DELIVERY_METHODS.GLS_DELIVERY,
  DELIVERY_METHODS.HOME_DELIVERY,
];

export const DELIVERY_METHODS_WITHOUT_DATEPICKER: DeliveryMethodCode[] = [
  DELIVERY_METHODS.EXPRESS_DELIVERY,
  DELIVERY_METHODS.SAMEDAY_DELIVERY,
  DELIVERY_METHODS.LIFT_DELIVERY,
];

export const DELIVERY_OPTIONS: Record<string, string> = {
  EXTERNAL_LIFT: 'EXTERNAL_LIFT',
  EXTERNAL_LIFT_EXTRA: 'EXTERNAL_LIFT_EXTRA',
  LIFT_BIG_ENOUGH: 'LIFT_BIG_ENOUGH',
  PLACED_BY_CUSTOMER: 'PLACED_BY_CUSTOMER',
  STAIRWAY_BIG_ENOUGH: 'STAIRWAY_BIG_ENOUGH',
};

export const DELIVERY_TIMEFRAMES = {
  AFTERNOON: 'afternoon',
  ALL_DAY: 'allDay',
  DAY: 'day',
  EVENING: 'evening',
  MORNING: 'morning',
  OVERMORROW: 'overmorrow',
  TODAY: 'today',
  TOMORROW: 'tomorrow',
} as const;
export type DeliveryTimeFrame = (typeof DELIVERY_TIMEFRAMES)[keyof typeof DELIVERY_TIMEFRAMES];

export type DeliveryTimeFrameInfoOption = 'allDay' | 'morning' | 'afternoon';

export const PAYMENT_METHODS = {
  ALMAPAY: 'ALMAPAY',
  ALMAPAY_KREFEL: 'ALMAPAY-KREFEL',
  AMERICAN_EXPRESS: 'CREDITCARD-AMERICANEXPRESS',
  APPLE_PAY: 'APPLEPAY-SAFERPAY',
  BANCONTACT: 'CREDITCARD-BANCONTACT',
  BANK_TRANSFER: 'BANK',
  BUYWAY: 'BUYWAY',
  CASH_ON_DELIVERY: 'REMBOURS',
  DINERS_CLUB: 'CREDITCARD-DINERSCLUB',
  ECOCHEQUES: 'ECOCHEQUES',
  GIFTCARD: 'GIFTCARD',
  IDEAL: 'CREDITCARD-IDEAL',
  INVOICE: `${platform.toUpperCase()}_BANK`,
  KLARNA: 'KLARNA',
  MASTERCARD: 'CREDITCARD-MASTERCARD',
  MOLLIE: 'MOLLIE',
  PAYCONIQ: 'PAYCONIQ',
  PAYPAL: 'PAYPAL',
  PICKUP: 'INSTORE',
  PREPAID_INVOICE: `${platform.toUpperCase()}_BANK_VOV`,
  SANTANDER: 'SANTANDER',
  VISA: 'CREDITCARD-VISA',
};

export const BANK_PAYMENTS = [PAYMENT_METHODS.BANK_TRANSFER, PAYMENT_METHODS.INVOICE, PAYMENT_METHODS.PREPAID_INVOICE];

export const PAYMENT_STATUSES = {
  ACCEPTED: 'ACCEPTED',
  AWAITING_IDENTIFICATION: 'AWAITING_IDENTIFICATION',
  REDIRECT: 'REDIRECT',
  REJECTED: 'REJECTED',
  UNCERTAIN: 'UNCERTAIN',
};

export const ANALYTICS_OPTIONS = {
  DELIVERY_METHOD: 'DELIVERY_METHOD',
  PAYMENT_METHOD: 'PAYMENT_METHOD',
};

export const PAYCONIQ_PAYMENT_STATUSES = {
  AUTHORIZATION_FAILED: 'AUTHORIZATION_FAILED',
  CANCELLED: 'CANCELLED',
  EXPIRED: 'EXPIRED',
  FAILED: 'FAILED',
  PENDING: 'IDENTIFIED',
  SUCCEEDED: 'SUCCEEDED',
};

export const FORM_FIELDS = {
  ACTIVE_PREFILLED_COUNTRY: 'activePrefilledCountry',
  ADDRESS_TITLE: 'address_title',
  ATTENTION_OF: 'attentionOf',
  CLIENT_NUMBER: 'clientNumber',
  COMPANY_NAME: 'companyName',
  DELIVERY_LOCATION: 'delivery_location',
  DELIVERY_PHONE: 'delivery_phone',
  DELIVERY_POBOX: 'delivery_poBox',
  DELIVERY_ROUTE: 'delivery_route',
  DELIVERY_STREET_NUMBER: 'delivery_street_number',
  FIRSTNAME: 'firstName',
  HAS_VAT_NUMBER: 'hasVatNumber',
  INTRA_COMMUNITY: 'intra_community',
  INVOICE_LOCATION: 'invoice_location',
  INVOICE_PHONE: 'invoice_phone',
  INVOICE_POBOX: 'invoice_poBox',
  INVOICE_ROUTE: 'invoice_route',
  INVOICE_STREET_NUMBER: 'invoice_street_number',
  IS_ADDRESS_PREFILLED: 'isAddressPrefilled',
  IS_HIFI: 'isHifi',
  IS_NEW_ADDRESS: 'isNewAddress',
  LASTNAME: 'lastName',
  NEWSLETTER: 'newsletter',
  NEWSLETTER_SMS: 'newsletterSMS',
  PHONE_COUNTRY: 'phone_country',
  RECIPIENT_NAME: 'recipient_name',
  REMARKS: 'remarks',
  VALID_VAT_NUMBER: 'validVatNumber',
  VALID_VAT_NUMBER_ISOCODE: 'validVatIsoCode',
  VAT_NUMBER: 'vatNumber',
  WANTS_INVOICE: 'wantsInvoice',
  WANTS_SAME_ADDRESS: 'wantsSameAddress',
  WITH_SMS: 'withSms',
};

export const GOOGLE_PLACE_FORM_FIELDS_NAME = {
  ADMINISTRATIVE_AREA_LEVEL_1: 'administrative_area_level_1',
  COUNTRY: 'country',
  LOCALITY: 'locality',
  POSTAL_CODE: 'postal_code',
  ROUTE: 'route',
  STREET_NUMBER: 'street_number',
};

export const DELIVERY_MOMENT_TRANSLATIONS: Record<string, string> = {
  [DELIVERY_TIMEFRAMES.ALL_DAY]: 'basket_overview_Krefel_delivery_all_day_delivery',
  [DELIVERY_TIMEFRAMES.MORNING]: 'basket_overview_morning_delivery',
  [DELIVERY_TIMEFRAMES.AFTERNOON]: 'basket_overview_afternoon_delivery',
  [DELIVERY_TIMEFRAMES.EVENING]: 'basket_overview_bpost_delivery_evening_delivery',
};

export const LOGIN_FORM_ID = 'GuestLoginForm';
export const INFORMATION_FORM_ID = 'CheckoutInformationForm';
export const GIFTCARD_PAYMENT_FORM_ID = 'GiftcardPaymentDetailForm';
export const BUYWAY_FORM_ID = 'BuywayOptionsForm';
export const PAYMENT_TERMS_FORM_ID = 'PaymentTermsAndConditionsForm';
export const DELIVERY_PICKUP_FORM_ID = 'DeliveryPickupForm';
const DELIVERY_ELEVATOR_FORM_ID = 'DeliveryElevatorForm';
export const DELIVERY_PRODUCT_ON_ORDER_FORM_ID = 'DeliveryProductOnOrder';

const getFieldId = (formId: string, fieldName: string) => `${formId}_${fieldName}`;

export const LOGIN_FORM_FIELDS = {
  GUEST_EMAIL: {
    id: getFieldId(LOGIN_FORM_ID, 'guestEmail'),
    label: 'login_form_email',
    name: 'guestEmail',
  },
};

export const INFORMATION_FORM_FIELDS: Record<string, Record<string, string>> = {
  COMPANY_NAME: {
    id: getFieldId(INFORMATION_FORM_ID, 'companyName'),
    label: 'checkout_forms_company_name',
    name: 'companyName',
  },
  COMPANY_PO_NUMBER: {
    id: getFieldId(INFORMATION_FORM_ID, 'companyPoNumber'),
    label: 'checkout_forms_po_number',
    name: 'companyPoNumber',
  },
  COMPANY_VAT_NUMBER: {
    id: getFieldId(INFORMATION_FORM_ID, 'companyVatNumber'),
    label: 'checkout_forms_company_vat_number',
    name: 'companyVatNumber',
  },
  COMPANY_VAT_NUMBER_IS_VALID: {
    id: getFieldId(INFORMATION_FORM_ID, 'isCompanyVatNumberValid'),
    name: 'isCompanyVatNumberValid',
  },
  DELIVERY_ADDRESS_COUNTRY: {
    id: getFieldId(INFORMATION_FORM_ID, 'deliveryCountry'),
    name: 'deliveryCountry',
  },
  DELIVERY_ADDRESS_FORMATTED_LOCATION: {
    id: getFieldId(INFORMATION_FORM_ID, 'deliveryFormattedLocation'),
    label: 'checkout_forms_street_city',
    name: 'deliveryFormattedLocation',
  },
  DELIVERY_ADDRESS_NUMBER: {
    id: getFieldId(INFORMATION_FORM_ID, 'deliveryStreetNumber'),
    label: 'checkout_forms_street_number',
    name: 'deliveryStreetNumber',
  },
  DELIVERY_ADDRESS_PO_BOX: {
    id: getFieldId(INFORMATION_FORM_ID, 'deliveryPoBox'),
    label: 'checkout_forms_street_poBox',
    name: 'deliveryPoBox',
  },
  DELIVERY_ADDRESS_POSTAL_CODE: {
    id: getFieldId(INFORMATION_FORM_ID, 'deliveryPostalCode'),
    label: '',
    name: 'deliveryPostalCode',
  },
  DELIVERY_ADDRESS_STREET: {
    id: getFieldId(INFORMATION_FORM_ID, 'deliveryStreet'),
    label: 'checkout_forms_street',
    name: 'deliveryStreet',
  },
  DELIVERY_ADDRESS_TOWN: {
    id: getFieldId(INFORMATION_FORM_ID, 'deliveryTown'),
    label: 'checkout_forms_street_city',
    name: 'deliveryTown',
  },
  FIRST_NAME: {
    id: getFieldId(INFORMATION_FORM_ID, 'firstName'),
    label: 'checkout_forms_first_name',
    name: 'firstName',
  },
  HAS_DIFFERENT_INVOICE_ADDRESS: {
    id: getFieldId(INFORMATION_FORM_ID, 'hasDifferentInvoiceAddress'),
    label: 'checkout_forms_different_invoice_address',
    name: 'hasDifferentInvoiceAddress',
  },
  INTRA_COMMUNITY: {
    id: getFieldId(INFORMATION_FORM_ID, 'intraCommunity'),
    infoDescription: 'checkout_delivery_data_order_intra_community_pop_up_description',
    infoTitle: 'checkout_delivery_data_order_intra_community_pop_up_title',
    label: 'checkout_delivery_data_order_intra_community',
    name: 'intraCommunity',
    title: 'checkout_delivery_data_order_intra_community_title',
  },
  INVOICE_ADDRESS_CITY: {
    id: getFieldId(INFORMATION_FORM_ID, 'invoiceTown'),
    label: 'checkout_forms_street_city',
    name: 'invoiceTown',
  },
  INVOICE_ADDRESS_COUNTRY: {
    id: getFieldId(INFORMATION_FORM_ID, 'invoiceCountry'),
    name: 'invoiceCountry',
  },
  INVOICE_ADDRESS_FORMATTED_LOCATION: {
    id: getFieldId(INFORMATION_FORM_ID, 'invoiceFormattedLocation'),
    label: 'checkout_forms_street_city',
    name: 'invoiceFormattedLocation',
  },
  INVOICE_ADDRESS_NUMBER: {
    id: getFieldId(INFORMATION_FORM_ID, 'invoiceStreetNumber'),
    label: 'checkout_forms_street_number',
    name: 'invoiceStreetNumber',
  },
  INVOICE_ADDRESS_PO_BOX: {
    id: getFieldId(INFORMATION_FORM_ID, 'invoicePoBox'),
    label: 'checkout_forms_street_poBox',
    name: 'invoicePoBox',
  },
  INVOICE_ADDRESS_STREET: {
    id: getFieldId(INFORMATION_FORM_ID, 'invoiceStreet'),
    label: 'checkout_forms_street',
    name: 'invoiceStreet',
  },
  INVOICE_FIRST_NAME: {
    id: getFieldId(INFORMATION_FORM_ID, 'invoiceFirstName'),
    label: 'checkout_forms_first_name',
    name: 'invoiceFirstName',
  },
  INVOICE_LAST_NAME: {
    id: getFieldId(INFORMATION_FORM_ID, 'invoiceLastName'),
    label: 'checkout_forms_last_name',
    name: 'invoiceLastName',
  },
  IS_BUSINESS_USER: {
    id: getFieldId(INFORMATION_FORM_ID, 'isBusinessUser'),
    label: '',
    name: 'isBusinessUser',
  },
  IS_DELIVERY_ADDRESS: {
    id: getFieldId(INFORMATION_FORM_ID, 'billingAddress'),
    label: '',
    name: 'billingAddress',
  },
  IS_SHIPPING_ADDRESS: {
    id: getFieldId(INFORMATION_FORM_ID, 'shippingAddress'),
    label: '',
    name: 'shippingAddress',
  },
  LAST_NAME: {
    id: getFieldId(INFORMATION_FORM_ID, 'lastName'),
    label: 'checkout_forms_last_name',
    name: 'lastName',
  },
  PHONE_NUMBER: {
    id: getFieldId(INFORMATION_FORM_ID, 'phone'),
    label: 'checkout_forms_phone_number',
    name: 'phone',
  },
  REMARKS: {
    id: getFieldId(INFORMATION_FORM_ID, 'remarks'),
    label: 'checkout_forms_remarks',
    name: 'remarks',
  },
  WITH_INVOICE: {
    id: getFieldId(INFORMATION_FORM_ID, 'withInvoice'),
    label: 'checkout_forms_wants_invoice',
    name: 'withInvoice',
  },
  WITH_NEWSLETTER_SUBSCRIPTION: {
    id: getFieldId(INFORMATION_FORM_ID, 'withNewsletterSubscription'),
    label: 'checkout_forms_newsletter_subscription',
    name: 'withNewsletterSubscription',
  },
};

export const USER_TYPES = {
  PRIVATE: { id: 'private', label: 'checkout_forms_private_user' },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  BUSINESS: { id: 'business', label: 'checkout_forms_business_user' },
};

export type UserType = (typeof USER_TYPES)[keyof typeof USER_TYPES];

export const ADDRESS_FORM_TYPES = {
  DELIVERY: 'delivery_location',
  INVOICE: 'invoice_location',
};
export type AddressFormType = (typeof ADDRESS_FORM_TYPES)[keyof typeof ADDRESS_FORM_TYPES];

export const GIFTCARD_PAYMENT_FORM_FIELDS = {
  GIFTCARD_NUMBER: {
    id: getFieldId(GIFTCARD_PAYMENT_FORM_ID, 'giftcardNumber'),
    label: 'gift_card_payment_voucher_number',
    name: 'giftcardNumber',
  },
  GIFTCARD_PIN: {
    id: getFieldId(GIFTCARD_PAYMENT_FORM_ID, 'giftcardPin'),
    label: 'gift_card_payment_voucher_pin_code',
    name: 'giftcardPin',
  },
};

export const BUYWAY_PAYMENT_ACCOUNT_OPTIONS = {
  EXISTING: 'ON_CARRIER',
  NEW: 'OPENING',
};

export const BUYWAY_PAYMENT_FORM_FIELDS = {
  EXISTING_ACCOUNT: {
    id: getFieldId(BUYWAY_FORM_ID, 'existingAccount'),
    label: 'checkout_payment_buyway_new_account',
    name: 'buywayPaymentAccountOption',
    value: BUYWAY_PAYMENT_ACCOUNT_OPTIONS.EXISTING,
  },
  NEW_ACCOUNT: {
    id: getFieldId(BUYWAY_FORM_ID, 'newAccount'),
    label: 'checkout_payment_buyway_existing_account',
    name: 'buywayPaymentAccountOption',
    value: BUYWAY_PAYMENT_ACCOUNT_OPTIONS.NEW,
  },
};

export const PAYMENT_DETAILS_METHODS = [PAYMENT_METHODS.GIFTCARD, PAYMENT_METHODS.SANTANDER, PAYMENT_METHODS.BUYWAY];

export const PAYMENT_TERMS_FORM_FIELDS = {
  TERMS_AND_CONDITIONS_CHECKBOX: {
    id: getFieldId(PAYMENT_TERMS_FORM_ID, 'paymentTermsAndConditionsCheckbox'),
    label: '',
    name: 'paymentTermsAndConditionsCheckbox',
  },
};

export const DELIVERY_PICKUP_FORM_FIELDS = {
  CLIENT_NUMBER: {
    id: getFieldId(DELIVERY_PICKUP_FORM_ID, 'deliveryPickupClientNumber'),
    label: '',
    name: 'deliveryPickupClientNumber',
  },
  WITH_SMS: {
    id: getFieldId(DELIVERY_PICKUP_FORM_ID, 'withSMS'),
    label: 'checkout_delivery_data_withSmsOrEmail_check_label',
    name: 'withSMS',
    title: 'checkout_delivery_data_order_delivery_notification',
  },
};

export const DELIVERY_ELEVATOR_FORM_FIELDS = {
  ELEVATOR_STATUS: {
    id: getFieldId(DELIVERY_ELEVATOR_FORM_ID, 'elevatorStatus'),
    name: 'elevatorStatus',
  },
  FLOOR: {
    id: getFieldId(DELIVERY_ELEVATOR_FORM_ID, 'floor'),
    label: 'delivery_standard_floor',
    name: 'floor',
  },
  HAS_STAIRS: {
    id: getFieldId(DELIVERY_ELEVATOR_FORM_ID, 'hasStairs'),
    label: 'checkout_has_staircases',
    name: 'hasStairs',
  },
};

export const DELIVERY_ELEVATOR_STATUS_OPTIONS = {
  [DELIVERY_OPTIONS.STAIRWAY_BIG_ENOUGH]: {
    checkedIndex: -1,
    label: 'delivery_standard_hallway_ok',
    name: DELIVERY_OPTIONS.STAIRWAY_BIG_ENOUGH,
    withElevator: false,
  },
  [DELIVERY_OPTIONS.LIFT_BIG_ENOUGH]: {
    checkedIndex: 0,
    description: 'delivery_standard_elevator_ok_text',
    label: 'delivery_standard_elevator_ok',
    name: DELIVERY_OPTIONS.LIFT_BIG_ENOUGH,
    withElevator: false,
  },
  [DELIVERY_OPTIONS.EXTERNAL_LIFT]: {
    checkedIndex: 1,
    description: 'delivery_standard_elevator_required_ok',
    label: 'delivery_standard_elevator_required',
    name: DELIVERY_OPTIONS.EXTERNAL_LIFT,
    withElevator: true,
  },
  [DELIVERY_OPTIONS.EXTERNAL_LIFT_EXTRA]: {
    checkedIndex: 1,
    description: 'delivery_standard_elevator_extra_required',
    label: 'delivery_standard_elevator_required_extra_ok',
    name: DELIVERY_OPTIONS.EXTERNAL_LIFT_EXTRA,
    withElevator: true,
  },
  [DELIVERY_OPTIONS.PLACED_BY_CUSTOMER]: {
    checkedIndex: 2,
    label: 'delivery_standard_elevator_no',
    name: DELIVERY_OPTIONS.PLACED_BY_CUSTOMER,
    withElevator: false,
  },
};

export const DELIVERY_OPTIONS_WITH_REQUIRED_ELEVATOR = [
  DELIVERY_OPTIONS.EXTERNAL_LIFT,
  DELIVERY_OPTIONS.EXTERNAL_LIFT_EXTRA,
];

export const DELIVERY_PRODUCT_ON_ORDER_FORM_FIELDS = {
  CONTACT_CUSTOMER: {
    id: getFieldId(DELIVERY_PRODUCT_ON_ORDER_FORM_ID, 'contactCustomer'),
    label: 'onorder_delivery_contact_me',
    name: 'contactCustomer',
  },
};

const DELIVERY_PICKUP_LOCATION_FORM_ID = 'deliveryPickupLocationFormId';
export const DELIVERY_PICKUP_LOCATION_FORM_FIELDS = {
  DELIVERY_PICKUP_FORMATTED_LOCATION: {
    id: getFieldId(DELIVERY_PICKUP_LOCATION_FORM_ID, 'deliveryPickupFormattedLocation'),
    name: 'deliveryPickupFormattedLocation',
  },
};
