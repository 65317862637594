import useRouter from 'hooks/useRouter';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useAuthActions } from 'store/auth';
import { useConfigurationStoreActions, useHasReceivedEvent } from 'store/configuration';
import { AppEvent, AppEventParams } from 'types/AppEvent';
import { OAuthToken } from 'types/Auth';
import config from '../config';
import { APP_EVENT_MESSAGES } from '../constants/appEvents';
import { pathnames } from '../i18n/pathnames';
import { clearCart, getCart } from '../redux/modules/cart';
import { log } from '../utils/loggerUtil';
import { detectValidWindow } from '../utils/windowUtil';

const useAppEvents = () => {
  const [, setCookie] = useCookies();
  const dispatch = useDispatch();
  const router = useRouter();

  const { initAuth } = useAuthActions();
  const { setHasReceivedEvent } = useConfigurationStoreActions();
  const hasReceivedEvent = useHasReceivedEvent();

  const sendAppEvent = ({ message = '', method = 'sendEvent', params = {} }: AppEventParams) => {
    if (detectValidWindow()) {
      const eventObject = JSON.stringify({ message, method, params });

      log('useAppEvents', 'Webview sending event to App, waiting for response...', eventObject);

      if (window?.webkit?.messageHandlers?.Krefel) {
        window?.webkit?.messageHandlers?.Krefel?.postMessage(eventObject);
      }

      // Do not use optional chaining, android webview does not support it
      if (window && window.KrefelAndroid && window.KrefelAndroid.sendEvent) {
        window.KrefelAndroid.sendEvent(eventObject);
      }
    } else {
      log('useAppEvents', 'window object is not found...');
    }
  };

  const handleCart = async (anonymous = false, anonymousCartId?: string) => {
    try {
      await dispatch(getCart(anonymous, anonymousCartId, undefined));

      log('useAppEvents', `Successfully loaded webview cart...`);
      return true;
    } catch (err) {
      log('useAppEvents', `failed to load webview cart, inspect error for detailed information...`, err);
      sendAppEvent({ message: APP_EVENT_MESSAGES.REQUEST_TOKEN });
      return false;
    }
  };

  interface TokenEventParams {
    cartId?: string;
    token?: OAuthToken;
  }
  const handleTokenEvent = async ({ cartId: anonymousCartId = undefined, token }: TokenEventParams) => {
    if (!token) {
      sendAppEvent({ message: APP_EVENT_MESSAGES.REQUEST_MISSING_USER_TOKEN });
      return false;
    }

    if (anonymousCartId && !token?.refresh_token) {
      setCookie(config.cart.key, anonymousCartId, {
        ...config.cookie,
        maxAge: config.cart.expirationTime,
      });

      return handleCart(true, anonymousCartId);
    }

    if (token) {
      setCookie(config.oauth.key, token, {
        ...config.cookie,
        maxAge: config.oauth.expirationTime,
        secure: config.oauth.secure,
      });

      try {
        if (token?.refresh_token) {
          const { anonymous, token } = await initAuth();
          if (!token) {
            sendAppEvent({ message: APP_EVENT_MESSAGES.REQUEST_TOKEN });
          }

          await handleCart(anonymous, anonymousCartId);

          return true;
        }
      } catch (err) {
        log('useAppEvents', `failed to load user with given params ${JSON.stringify(token)}`, err);
        return false;
      }
    }

    return false;
  };

  const addWindowNotifyCallback = () => {
    if (detectValidWindow()) {
      window.notify = async (type: AppEvent, args = {}) => {
        switch (type) {
          case APP_EVENT_MESSAGES.START:
            log(
              'useAppEvents',
              'Webview received START event, loading the cart and/or user depending on given arguments...',
              args
            );

            await dispatch(clearCart());
            handleTokenEvent(args);
            setHasReceivedEvent(true);

            break;
          case APP_EVENT_MESSAGES.REFRESH_TOKEN:
            log(
              'useAppEvents',
              'Webview received Triggered REFRESH_TOKEN event, updating the cookies and triggering a new login attempt with the new credentials...',
              args
            );

            handleTokenEvent(args);
            setHasReceivedEvent(true);

            break;
          case APP_EVENT_MESSAGES.DELIVERY: {
            log(
              'useAppEvents',
              'Webview received Triggered DELIVERY event, Loading cart and forwarding to shipping info step...',
              args
            );

            const successful = await handleTokenEvent(args);

            if (successful) {
              await router.push(pathnames.CHECKOUT_SHIPPING);
              setHasReceivedEvent(true);
            }
            break;
          }

          default:
            log('useAppEvents', `Webview received incorrect event, Doing nothing...`);
            break;
        }
      };
    }
  };

  return { addWindowNotifyCallback, hasReceivedEvent, sendAppEvent };
};

export default useAppEvents;
