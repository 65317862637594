/* eslint-disable sort-keys-fix/sort-keys-fix */
// These are defined in commanders act platform
export const CONSENT_GROUPS = {
  NECESSARY: '11',
  PERFORMANCE: '21',
  FUNCTIONAL: '31',
  TARGETING: '41',
  TARGETING2: '42',
  TARGETING3: '43',
  TARGETING4: '44',
} as const;
