export const AGENTS = {
  ANDROID: { name: 'Android', type: 'android' },
  IOS: { meta: 'apple-itunes-app', name: 'iOS', type: 'ios' },
};

export const AGENT_BROWSER_NAMES = {
  MOBILE_SAFARI: 'Mobile Safari',
  SAFARI: 'Safari',
};

export const UNKNOWN_OS = 'UNKNOWN OS';
