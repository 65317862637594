import { Spacer } from 'constants/styling';
import { css } from 'styled-components';
import { spacer } from 'theme';

export interface PaddingProps {
  padding?: Spacer;
  paddingBottom?: Spacer;
  paddingLeft?: Spacer;
  paddingRight?: Spacer;
  paddingTop?: Spacer;
}

export const paddingMapper = css<PaddingProps>`
  padding: ${({ padding }) => spacer(padding)};
  padding-bottom: ${({ paddingBottom }) => spacer(paddingBottom)};
  padding-left: ${({ paddingLeft }) => spacer(paddingLeft)};
  padding-right: ${({ paddingRight }) => spacer(paddingRight)};
  padding-top: ${({ paddingTop }) => spacer(paddingTop)};
`;
