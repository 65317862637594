/* eslint-disable sort-keys-fix/sort-keys-fix */
import config from 'config';
import {
  AnalyticsPageType,
  PIANO_PAGE_NAMES,
  PIANO_PAGE_NAMES_FROM_PATHNAMES,
  PIANO_PAGE_TYPES,
  PIANO_PAGE_TYPES_FROM_PATHNAMES,
  PianoPageName,
} from 'constants/analytics';
import { isProductionEnv, isWebview } from 'constants/environment';
import { BRAND_SPECIFIC_DEFAULT_LOCALE } from 'constants/i18n';
import { TrackingEvents } from 'hooks/useTrackingEvents';
import { Pathname, pathnames } from 'i18n/pathnames';
import { AlgoliaProduct } from 'types/Algolia';
import { Cart, CartEntry } from 'types/Cart';
import { Category } from 'types/Category';
import { Order } from 'types/Order';
import {
  SelfPromotionClick,
  SelfPromotionClickData,
  SelfPromotionImpression,
  SelfPromotionImpressionData,
} from 'types/Piano';
import { Product } from 'types/Product';
import { Store } from 'types/Store';

import { getCategoriesArray } from 'utils/categoryUtil';
import { SHA256 } from 'utils/cryptoUtil';
import { log } from 'utils/loggerUtil';
import { getDiscountAmount } from 'utils/productUtil';
import { isMobile } from 'utils/screenUtils';
import { nonNullable } from 'utils/typeUtil';
import { prefixWithLocale } from 'utils/urlUtil';

interface PageInfoParams {
  category?: Category;
  pageName?: PianoPageName | AnalyticsPageType;
  pathname: string;
  product?: Product;
  slug?: string;
  store?: Store;
}

const AMPERSAND_LOCALIZED_SYNONYM: Record<string, string> = {
  nl: 'en',
  fr: 'et',
  en: 'and',
  de: 'und',
};

const replaceAmpersand = (value: string) => {
  const locale = document?.documentElement?.getAttribute('lang') ?? BRAND_SPECIFIC_DEFAULT_LOCALE;
  return value?.replaceAll('&', AMPERSAND_LOCALIZED_SYNONYM[locale] || 'and');
};

// Replaces all spaces with underscores and removes all diatrics for example: (à => a)
const normalize = (value?: string) => {
  if (!value) return;
  const ampersandReplacedValue = replaceAmpersand(value);
  return (
    ampersandReplacedValue
      ?.trim()
      ?.toLowerCase()
      ?.normalize('NFD')
      ?.replace(/[\u0300-\u036f]/g, '')
      ?.replace(/[^a-zA-Z0-9]/g, '_')
      ?.replace(/_+/g, '_') || ''
  );
};

type MappedEntry = {
  product_id?: string;
  product_quantity?: number | string;
};

const mergeMappedEntries = (mappedEntries: MappedEntry[]) => {
  const mergedEntries = mappedEntries?.reduce((newEntries: MappedEntry[], entry) => {
    const mergedEntry = newEntries.find((mergedEntry) => mergedEntry.product_id === entry.product_id);
    if (mergedEntry) {
      const quantity = mergedEntry.product_quantity ? parseInt(mergedEntry.product_quantity as string) : 0;
      mergedEntry.product_quantity = quantity + 1;
      return newEntries;
    }
    return [...newEntries, entry];
  }, []);
  return mergedEntries;
};

const getPageChaptersWithCategories = (category?: Category) => {
  if (!category) return {};
  const categoryNames = getCategoriesArray(category)
    ?.map((category) => category?.name)
    .filter(nonNullable);

  const pageChapterObject = categoryNames?.reduce((pageChapter: Record<string, string>, categoryName, index) => {
    pageChapter[`page_chapter${index + 1}`] = normalize(categoryName) ?? '';
    return pageChapter;
  }, {});

  return pageChapterObject;
};

const getPageParameter = (pageChapters: Record<string, string>) => {
  const categoryNames = Object.values(pageChapters);
  const lastCategoryName = categoryNames?.[categoryNames?.length - 1];

  return lastCategoryName;
};

const getPageInfo = (params: PageInfoParams) => {
  const { category, pageName, pathname, product, slug, store } = params;
  const basePageName: string =
    pageName || PIANO_PAGE_NAMES_FROM_PATHNAMES[pathname as Pathname] || pathname?.split('/')?.[1];

  switch (pathname) {
    case pathnames.CATEGORY: {
      // If L1 or L2 category
      if (basePageName === PIANO_PAGE_TYPES.MENU) {
        return {
          page: `${basePageName}-${normalize(category?.name)}`,
          ...getPageChaptersWithCategories(category),
        };
      }

      const pageChapters = getPageChaptersWithCategories(category);
      // Else L3 category

      const lastCategoryName = getPageParameter(pageChapters);

      return {
        page: `${basePageName}-${lastCategoryName || normalize(category?.superCategory?.superCategory?.name)}`,
        ...pageChapters,
      };
    }
    case pathnames.CMSPAGE: //THIS matches both content pages and cms search pages
      // If content page
      if (!pageName) {
        return {
          page: `${basePageName}-${normalize(slug)}`,
          page_chapter1: `${normalize(slug)}`,
          page_chapter2: '',
          page_chapter3: '',
          page_chapter4: '',
        };
      }
      // If CMSSearchPage
      if (category) {
        const pageChapters = getPageChaptersWithCategories(category);
        const lastCategoryName = getPageParameter(pageChapters);
        return {
          page: `${PIANO_PAGE_NAMES_FROM_PATHNAMES[pathnames.CATEGORY]}-${
            lastCategoryName || normalize(category?.superCategory?.superCategory?.name)
          }`,
          ...pageChapters,
        };
      }
      return {
        page: `${PIANO_PAGE_NAMES_FROM_PATHNAMES[pathnames.CATEGORY]}-${normalize(slug)}`,
        page_chapter1: normalize(slug),
        page_chapter2: '',
        page_chapter3: '',
      };

    case pathnames.PRODUCT: {
      const productCategory = product?.categories?.[0];

      return {
        page: `${basePageName}-${normalize(product?.name)}`,
        ...getPageChaptersWithCategories(productCategory),
      };
    }
    case pathnames.STOREFINDER_DETAIL:
      return {
        page: `${basePageName}-${normalize(store?.name)}`,
        page_chapter1: `${basePageName}-${normalize(store?.name)}`,
        page_chapter2: '',
        page_chapter3: '',
        page_chapter4: '',
      };
    case pathnames.CART:
    case pathnames.CHECKOUT_DELIVERY:
    case pathnames.CHECKOUT_SHIPPING:
    case pathnames.CHECKOUT_PAYMENT:
    case pathnames.CHECKOUT_PAYMENT_REMAINING:
    case pathnames.CHECKOUT_OVERVIEW:
    case pathnames.CHECKOUT_CONFIRMATION:
    case pathnames.CHECKOUT_LOGIN:
    case pathnames.CHECKOUT_REGISTER:
      return {
        page: basePageName,
        page_chapter1: PIANO_PAGE_NAMES.CHECKOUT,
        page_chapter2: '',
        page_chapter3: '',
      };
    default:
      return {
        page: basePageName,
        page_chapter1: basePageName,
        page_chapter2: '',
        page_chapter3: '',
        page_chapter4: '',
      };
  }
};

interface PathMapperParams {
  category?: Category;
  path: string;
  pathname: string;
}

const getMappedPath = ({ category, path, pathname }: PathMapperParams) => {
  switch (pathname) {
    case pathnames.CATEGORY: {
      // If short url we add the codes for analytics
      const splitPath = path.split('/');
      if (splitPath?.length === 3 && category) {
        const categoryCodes = [category.superCategory?.superCategory?.code, category.superCategory?.code, category.code]
          .filter(Boolean)
          .join('-');
        return splitPath.slice(0, 2)?.concat(categoryCodes)?.concat(splitPath.slice(2))?.join('/');
      }
      return path;
    }
    default:
      return path;
  }
};

const getProductCategoryInfo = (productCategory?: Category) => {
  const categoryValues = [
    normalize(productCategory?.superCategory?.superCategory?.name),
    normalize(productCategory?.superCategory?.name),
    normalize(productCategory?.name),
  ].filter(nonNullable);

  const productCategories: Record<string, string> = {
    product_category1: '',
    product_category2: '',
    product_category3: '',
  };

  categoryValues.map((value, index) => {
    productCategories[`product_category${index + 1}`] = value;
  });

  return productCategories;
};

const getProductInfo = (product?: Product, category?: Category) => {
  if (!product) return;
  const hasDiscount = !!getDiscountAmount(product);
  const productCategory = category || product.categories?.[0];

  const productCategoryInfo = getProductCategoryInfo(productCategory);

  return {
    product: normalize(product.name) || '',
    product_brand: normalize(product.brand?.name) || '',
    ...productCategoryInfo,
    product_discount_ati: hasDiscount,
    product_id: product.code || '',
    product_pricetaxfree: '', // price / VAT multiplier
    product_pricetaxincluded: product.price?.value || '',
  };
};

const getAlgoliaProductCategoryInfo = (hit: AlgoliaProduct) => {
  const categoryValues = hit.datalayerCategoryPathNames?.split(' > ').map((value) => normalize(value));

  const productCategories: Record<string, string> = new Array(3).fill('').reduce((productCategories, _, index) => {
    productCategories[`product_category${index + 1}`] = categoryValues?.[index] ?? '';
    return productCategories;
  }, {});

  return productCategories;
};

const getProductInfoFromHit = (hit?: AlgoliaProduct) => {
  if (!hit) return;
  const hasDiscount = !!hit.strikePrice;
  const productCategoryInfo = getAlgoliaProductCategoryInfo(hit);

  return {
    product: normalize(hit.name) || '',
    product_brand: normalize(hit.brandName) || '',
    ...productCategoryInfo,
    product_discount_ati: hasDiscount,
    product_id: hit.code || '',
    product_pricetaxfree: '', // price / VAT multiplier
    product_pricetaxincluded: hit.priceValue || '',
  };
};

const getProductCartEntryInfo = (entry: CartEntry) => ({
  product_quantity: entry?.quantity || '',
  product_seller: normalize(entry?.product?.partners?.[0]?.displayName) || '',
  product_variant: '',
});

const getProductDeliveryInfo = (cart: Cart) => ({
  // TODO: after ship from store check deliverymode per entry instead of whole cart
  order_products_shipping_method: normalize(cart.deliveryMode?.code) || '',
  order_products_shipping_delay: cart.deliveryDate || '',
  order_products_shipping_store: '', // When mode is pickup => store?
  order_products_shipping_provider: '', // Essentially same as method
});

const getCartInfo = (cart: Cart) => ({
  cart_currency: cart.openAmount?.currencyIso?.toLowerCase() || 'eur',
  cart_id: cart.code || '',
  cart_nbdistinctproduct: cart.totalItems || '',
  cart_turnovertaxfree: '', //How to calculate this?
  cart_turnovertaxincluded: cart.totalPriceWithTax?.value || '',
});

const getShippingInfo = (cart: Cart) => ({
  shipping_costtaxincluded: cart.deliveryCost?.value ?? '',
  shipping_costtaxfree: '',
  shipping_delivery: normalize(cart.deliveryMode?.code),
});

const getPaymentInfo = (cart: Cart) => ({
  payment_mode: normalize(cart.paymentMode?.code) || '',
});

const getTransactionInfo = async (order: Order) => {
  if (!order.guid) return;
  const encodedOrderId = await SHA256(order.guid);
  return {
    transaction_id: order.guid || '',
    transaction_id_sha_256: encodedOrderId,
    transaction_promocode: `[${order.appliedVouchers?.map((voucher) => `"${voucher.voucherCode}"`).toString()}]` || '',
  };
};

const getOrderInfo = async (order: Order) => {
  const uid = order.user?.uid || '';
  const saltedOrderId = `${uid}${order.guid}`;
  const encodedOrderId = await SHA256(saltedOrderId);
  return {
    order_id_sha256: encodedOrderId || '',
    order_discount_ati: !!order.orderDiscounts?.value,
    order_discount_tf: '', //How to calculate?
    order_tax: '', // No tax on order or calculate?
  };
};

const getCartEventInfo = (cart: Cart) => {
  const mappedEntries =
    cart?.entries?.map((entry) => ({
      ...getProductInfo(entry.product),
      ...getProductCartEntryInfo(entry),
    })) ?? [];
  const mergedEntries = mergeMappedEntries(mappedEntries);
  return {
    ...getCartInfo(cart),
    order_products: mergedEntries || '',
  };
};

const getCartDeliveryEventInfo = (cart: Cart) => {
  const mappedEntries =
    cart?.entries?.map((entry) => ({
      ...getProductInfo(entry.product),
      ...getProductCartEntryInfo(entry),
      ...getProductDeliveryInfo(cart),
    })) ?? [];
  const mergedEntries = mergeMappedEntries(mappedEntries);
  return {
    ...getCartInfo(cart),
    ...getShippingInfo(cart),
    order_products: mergedEntries || '',
  };
};

const getCartPaymentEventInfo = (cart: Cart) => ({
  ...getCartDeliveryEventInfo(cart),
  ...getPaymentInfo(cart),
});

const getOrderConfirmationEventInfo = async (order: Order) => ({
  ...getCartInfo(order),
  ...getShippingInfo(order),
  ...getPaymentInfo(order),
  ...(await getTransactionInfo(order)),
  ...(await getOrderInfo(order)),
});

const getPurchasedProductsEventInfo = async (order: Order) => {
  const mappedEntries = await Promise.all(
    order?.entries?.map(async (entry) => ({
      ...getProductInfo(entry.product),
      ...getProductCartEntryInfo(entry),
      ...getProductDeliveryInfo(order),
      ...getPaymentInfo(order),
      ...(await getTransactionInfo(order)),
      cart_id: order.code || '',
    })) ?? [],
  );
  const mergedEntries = mergeMappedEntries(mappedEntries);
  return {
    order_products: mergedEntries || '',
  };
};

const PianoTrackingHelper: TrackingEvents = {
  onCartView({ cart }) {
    const event = {
      event: 'cart.display',
      ...getCartEventInfo(cart),
    };
    log('Piano Tracking', 'onCartView', event);
    window?.dataLayer?.push(event);
  },
  onCartDeliveryView({ cart }) {
    const event = {
      event: 'cart.delivery',
      ...getCartDeliveryEventInfo(cart),
    };
    log('Piano Tracking', 'onCartDeliveryView', event);
    window?.dataLayer?.push(event);
  },
  onCartPaymentView({ cart }) {
    const event = {
      event: 'cart.payment',
      ...getCartPaymentEventInfo(cart),
    };
    log('Piano Tracking', 'onCartPaymentView', event);
    window?.dataLayer?.push(event);
  },
  onPDPView({ product }) {
    const event = {
      event: 'product.page_display',
      ...getProductInfo(product),
      product_ecopart: '{{Product ecopart amount}}', // If eco product => price else 0?
      product_image: product.image?.url || product.images?.[0]?.url || '',
    };
    log('Piano Tracking', 'onPDPView', event);
    window?.dataLayer?.push(event);
  },
  onPLPView({ category, hits, pageNumber, products }) {
    const mappedProducts = products?.map((product, index) => ({
      ...getProductInfo(product, category),
      product_position: index + 1,
    }));
    const mappedHits = hits?.map((hit, index) => ({
      ...getProductInfoFromHit(hit),
      product_position: index + 1,
    }));
    const event = {
      event: 'product.display',
      list_products: mappedProducts || mappedHits || '',
      page_number: pageNumber || '',
    };
    log('Piano Tracking', 'onPLPView', event);
    window?.dataLayer?.push(event);
  },
  async onPageView({
    analyticsPageName,
    analyticsPageType,
    category,
    locale,
    path,
    pathname,
    product,
    slug,
    store,
    user,
  }) {
    const brand = config.platform;
    const isApp = isWebview;
    const _isMobile = isMobile();
    const channel = isApp ? 'a' : _isMobile ? 'm' : 'd';
    const work = isProductionEnv ? 'production' : 'recette';
    const pageType =
      analyticsPageType || PIANO_PAGE_TYPES_FROM_PATHNAMES[pathname as Pathname] || pathname?.split('/')?.[1];
    const contactEmail = user?.contactEmail ? await SHA256(user.contactEmail) : null;

    const userData = {
      user_email: contactEmail || '',
      user_id: user?.customerId || '',
      user_login_state: !!user?.uid,
      ...(!!user?.favouriteStores?.length && {
        user_store: user?.favouriteStores?.map((store) => store?.displayName).join(', '),
      }),
    };

    const event = {
      event: 'page.display',
      pageGA: prefixWithLocale(getMappedPath({ path, pathname, category }), locale),
      ...getPageInfo({ pathname, product, category, slug, pageName: analyticsPageName, store }),
      brand,
      env_channel: channel,
      env_country: '', // We don't have this info so we leave it empty
      env_work: work,
      language: locale || '',
      location: '', // We don't have this info so we leave it empty
      page_type: pageType || '',
      site_id: process.env.NEXT_PUBLIC_PIANO_SITE_ID || '',
      ...userData,
    };
    log('Piano Tracking', 'onPageView', event);
    window?.dataLayer?.push(event);
  },
  onSearchResultView({ amountOfResults, keyword, pageNumber }) {
    const searchType = amountOfResults ? 'avec_resultat' : 'sans_resultat';
    const event = {
      event: 'internal_search_result.display',
      number_result: amountOfResults || '',
      page_number: pageNumber || '',
      search_keyword: keyword || '',
      search_type: searchType,
    };
    log('Piano Tracking', 'onSearchResultView', event);
    window?.dataLayer?.push(event);
  },
  async onOrderConfirmationView({ order }) {
    const transactionConfirmationEvent = {
      event: 'transaction.confirmation',
      ...(await getOrderConfirmationEventInfo(order)),
    };
    log('Piano Tracking', 'onOrderConfirmationView - transaction confirmation', transactionConfirmationEvent);
    window?.dataLayer?.push(transactionConfirmationEvent);
    const productsPurchasedEvent = {
      event: 'product.purchased',
      ...(await getPurchasedProductsEventInfo(order)),
    };
    log('Piano Tracking', 'onOrderConfirmationView - products purchased', productsPurchasedEvent);
    window?.dataLayer?.push(productsPurchasedEvent);
  },
  onAddToCart({ cart, product }) {
    const event = {
      event: 'product.add_to_cart',
      ...getProductInfo(product),
      product_ecopart: '{{Product ecopart amount}}', // If eco product => price else 0?
      product_quantity: 1,
      product_variant: '',
      cart_id: cart?.code || '',
    };
    log('Piano Tracking', 'onAddToCart', event);
    window?.dataLayer?.push(event);
  },
  onCartCreation({ cart }) {
    const product = cart.entries?.[0].product;
    const event = {
      event: 'product.add_to_cart',
      ...getProductInfo(product),
      product_ecopart: '{{Product ecopart amount}}', // If eco product => price else 0?
      product_quantity: 1,
      product_variant: '',
      product_cartcreation: true,
      cart_id: cart?.code || '',
    };
    log('Piano Tracking', 'onCartCreation', event);
    window?.dataLayer?.push(event);
  },
  onRemoveFromCart({ cart, product }) {
    const event = {
      event: 'product.remove_from_cart',
      ...getProductInfo(product),
      product_ecopart: '{{Product ecopart amount}}', // If eco product => price else 0?
      product_quantity: 1,
      product_variant: '',
      cart_id: cart?.code || '',
    };
    log('Piano Tracking', 'onRemoveFromCart', event);
    window?.dataLayer?.push(event);
  },
  onComponentView({ campaign, component, path }) {
    const event: SelfPromotionImpression = 'self_promotion.impression';
    const eventData: SelfPromotionImpressionData = {
      onsitead_type: 'Self Promotion',
      onsitead_general_placement: path,
      onsitead_campaign: campaign,
      onsitead_detailed_placement: component,
    };
    log('Piano Tracking', 'onComponentView', { event, ...eventData });
    window?.pa?.sendEvent(event, eventData);
  },
  onComponentClick({ campaign, component, path }) {
    const event: SelfPromotionClick = 'self_promotion.click';
    const eventData: SelfPromotionClickData = {
      onsitead_type: 'Self Promotion',
      onsitead_general_placement: path,
      onsitead_campaign: campaign,
      onsitead_detailed_placement: component,
    };
    log('Piano Tracking', 'onComponentClick', { event, ...eventData });
    window?.pa?.sendEvent(event, eventData);
  },
};

export default PianoTrackingHelper;
