import { css } from 'styled-components';
import { brandSelect, color } from '../../functions';

const defaultValues = {
  'card-active-background-color': css`
    ${color('primary-transparent-100')};
  `,
};

const krefel = {
  'body-color': css`
    ${color('whisper-400')};
  `,
  'colored-copy': css`
    ${color('primary-400')};
  `,
  'loader-bar-color': css`
    ${color('secondary-250')}
  `,
};

const hifi = {
  'body-color': css`
    ${color('black')};
  `,
  'card-active-background-color': css`
    ${color('primary-transparent-100.1')};
  `,
  'colored-copy': css`
    ${color('primary-400')};
  `,
  'loader-bar-color': css`
    ${color('secondary-transparent-300.2')}
  `,
};

const tones = {
  'body-color': css`
    ${color('secondary-400')};
  `,
  'colored-copy': css`
    ${color('secondary-400')};
  `,
  'loader-bar-color': css`
    ${color('primary-300')}
  `,
};

const brandSpecifics = {
  hifi,
  krefel,
  tones,
};

const genericSpecifics = {
  ...defaultValues,
  ...brandSelect(brandSpecifics),
};

export default genericSpecifics;
