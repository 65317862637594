import createThemeMap from '../../../utils/themeUtil';

export const hifiColors = createThemeMap({
  primary: {
    100: '#f4bdbd',
    200: '#ee9696',
    300: '#e14747',
    400: '#9a1919',

    transparent: {
      100: 'rgba(225, 71, 71, 0.3)',
      100.1: 'rgba(225, 71, 71, 0.1)',
      200: 'rgba(238, 150, 150, 0.1)',
      300: 'rgba(225, 71, 71, 0.5)',
    },
  },
  secondary: {
    200: '#9e9e9e',
    300: '#333333',
    400: '#0a4775',

    transparent: {
      300: 'rgba(0, 0, 0, 0.5)',
      300.1: 'rgba(0, 0, 0, 0.1)',
      300.2: 'rgba(0, 0, 0, 0.2)',
    },
  },
});
