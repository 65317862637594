/**
 * The Redux product module containing the product related action, action types and reducer.
 */

import { apiClient } from 'helpers/ApiClient';
import config from '../../config';

const { defaultCountry, platform } = config;

/**
 * Action types
 */
export const GET_PRODUCT_BY_CODE = `${platform}/products/GET_PRODUCT_BY_CODE`;
export const GET_PRODUCT_BY_CODE_SUCCESS = `${platform}/products/GET_PRODUCT_BY_CODE_SUCCESS`;
export const GET_PRODUCT_BY_CODE_FAIL = `${platform}/products/GET_PRODUCT_BY_CODE_FAIL`;
export const ADD_PRODUCT_REVIEW = `${platform}/products/ADD_PRODUCT_REVIEW`;
export const ADD_PRODUCT_REVIEW_SUCCESS = `${platform}/products/ADD_PRODUCT_REVIEW_SUCCESS`;
export const ADD_PRODUCT_REVIEW_FAIL = `${platform}/products/ADD_PRODUCT_REVIEW_FAIL`;
export const CLEAR_STORES_FOR_PICKUP = `${platform}/products/CLEAR_STORES_FOR_PICKUP`;
export const SUBSCRIBE_STOCK_UPDATE_NEWSLETTER = `${platform}/stockupdate/SUBSCRIBE_STOCK_UPDATE_NEWSLETTER`;
export const SUBSCRIBE_STOCK_UPDATE_NEWSLETTER_SUCCESS = `${platform}/stockupdate/SUBSCRIBE_STOCK_UPDATE_NEWSLETTER_SUCCESS`;
export const SUBSCRIBE_STOCK_UPDATE_NEWSLETTER_FAIL = `${platform}/stockupdate/SUBSCRIBE_STOCK_UPDATE_NEWSLETTER_FAIL`;
export const UNSUBSCRIBE_STOCK_UPDATE_NEWSLETTER = `${platform}/stockupdate/UNSUBSCRIBE_STOCK_UPDATE_NEWSLETTER`;
export const UNSUBSCRIBE_STOCK_UPDATE_NEWSLETTER_SUCCESS = `${platform}/stockupdate/UNSUBSCRIBE_STOCK_UPDATE_NEWSLETTER_SUCCESS`;
export const UNSUBSCRIBE_STOCK_UPDATE_NEWSLETTER_FAIL = `${platform}/stockupdate/UNSUBSCRIBE_STOCK_UPDATE_NEWSLETTER_FAIL`;
export const SET_SELECTED_WARRANTY = `${platform}/products/SET_SELECTED_WARRANTY`;
export const SET_REVIEW_SORT = `${platform}/products/SET_REVIEW_SORT`;
export const SET_PRODUCT_COMPARE_MENU_COLLAPSED = `${platform}/products/SET_PRODUCT_COMPARE_MENU`;
export const GET_SWOGO_PRODUCT_BY_CODE = `${platform}/products/GET_SWOGO_PRODUCT_BY_CODE`;
export const GET_SWOGO_PRODUCT_BY_CODE_SUCCESS = `${platform}/products/GET_SWOGO_PRODUCT_BY_CODE`;
export const GET_SWOGO_PRODUCT_BY_CODE_FAIL = `${platform}/products/GET_SWOGO_PRODUCT_BY_CODE`;

/**
 * Actions
 */

/**
 * Retrieves a product by it's id
 * @param {string} productCode - The requested product code
 * @returns {{types: [*,*,*], promise: (function(*))}} Get swogo product by code action
 */
export function getSwogoProductByCode(productCode) {
  return {
    promise: () =>
      apiClient(
        {
          url: `/products/${productCode}?fields=FULL`,
        },
      ),
    types: [
      GET_SWOGO_PRODUCT_BY_CODE,
      GET_SWOGO_PRODUCT_BY_CODE_SUCCESS,
      GET_SWOGO_PRODUCT_BY_CODE_FAIL,
    ],
  };
}

/**
 * Function which subscribes a customer to the stock update
 * @param {string} email - the user email address
 * @param {string} productCode - the product code
 * @param {string=} language - the current user's language
 * @returns {{types: [*,*,*], promise: (function(*): (*|Request))}}
 * the subscribe stock update action
 */
export function subscribeStockUpdate(email, productCode, language = defaultCountry) {
  return {
    promise: () =>
      apiClient(
        {
          method: "POST",
          params: {
            email,
            fields: 'FULL',
            language,
            productCode,
          },
          url: `/stocknotification/subscribe`,
        }
      ),
    types: [
      SUBSCRIBE_STOCK_UPDATE_NEWSLETTER,
      SUBSCRIBE_STOCK_UPDATE_NEWSLETTER_SUCCESS,
      SUBSCRIBE_STOCK_UPDATE_NEWSLETTER_FAIL,
    ],
  };
}

/**
 * Retrieves a product by it's id
 * @param {string} productCode - The requested product code
 * @param {boolean} endOfLife - The end of life flag
 * @returns {{types: [*,*,*], promise: (function(*))}} Get product by code action
 */
export function getProductByCode(productCode, endOfLife = false, updateProductState = true) {
  return {
    promise: () =>
      apiClient(
        { url: `/products/${productCode}?showEndOfLife=${endOfLife}&fields=FULL` }
      ),
    types: [
      GET_PRODUCT_BY_CODE,
      GET_PRODUCT_BY_CODE_SUCCESS,
      GET_PRODUCT_BY_CODE_FAIL,
    ],
    updateProductState,
  };
}

/**
 * Function which adds a new product review
 * @param {string} productCode - the requested product id
 * @param {object} review - the new product review
 * @returns {{types: [*,*,*], promise: (function(*): (*|Request))}} post product review action
 */
export function addProductReview(productCode, review) {
  return {
    promise: () =>
      apiClient(
        {
          data: review,
          method: "POST",
          url: `/products/${productCode}/reviews?fields=FULL`,
        }
      ),
    types: [
      ADD_PRODUCT_REVIEW,
      ADD_PRODUCT_REVIEW_SUCCESS,
      ADD_PRODUCT_REVIEW_FAIL,
    ],
  };
}

/**
 * Function which saves the current product compare object to the state
 * @param {array} warranty - the warranty boolean
 */
export function setSelectedWarranty(warranty) {
  return {
    type: SET_SELECTED_WARRANTY,
    warranty,
  };
}

/**
 * Function which saves the current product compare object to the state
 * @param {array} warranty - the warranty boolean
 */
export const setActiveReviewSort = (sort) => ({
  sort,
  type: SET_REVIEW_SORT,
});

export const setProductCompareMenuCollapsed = (state) => ({
  state,
  type: SET_PRODUCT_COMPARE_MENU_COLLAPSED,
});

/**
 * Reducer
 */
const initialState = {
  activeSort: 'DISPLAYEDCREATIONTIME_DESC',
  addReviewError: false,
  compareGroup: null,
  compareProducts: [],
  faqs: [],
  favoriteStoresAvailability: [],
  hasWarranty: false,
  loadingStockStores: false,
  mapCenter: null,
  pickupStores: [],
  product: null,
  productStockStatus: null,
  selectedServices: [],
  updateProductState: true,
};

/**
 * The product reducer.
 *
 * @param {Object} state The default or current state.
 * @param {Object} action The dispatched action.
 * @returns {Object} The updated state.
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PRODUCT_BY_CODE_SUCCESS:
      if (!action.updateProductState) {
        return state;
      }

      return {
        ...state,
        product:
          Object.keys(action.result.data).length > 0 &&
            action.result.data.constructor === Object
            ? action.result.data
            : null,
      };
    case ADD_PRODUCT_REVIEW_SUCCESS:
      return {
        ...state,
        addReviewError: false,
      };
    case ADD_PRODUCT_REVIEW_FAIL:
      return {
        ...state,
        addReviewError: true,
      };
    case CLEAR_STORES_FOR_PICKUP:
      return {
        ...state,
        mapCenter: null,
        pickupStores: [],
      };
    case SET_SELECTED_WARRANTY: {
      return {
        ...state,
        hasWarranty: action.warranty,
      };
    }
    case SET_REVIEW_SORT: {
      return {
        ...state,
        activeSort: action.sort,
      };
    }
    case SET_PRODUCT_COMPARE_MENU_COLLAPSED: {
      return {
        ...state,
        isProductCompareMenuCollapsed: action?.state,
      };
    }
    default:
      return state;
  }
}
