import { css } from 'styled-components';
import { brandSelect, color } from 'theme/functions';

const defaultValues = {
  'slick-slider-arrow-border-radius': '50%',
  'slick-slider-arrow-color': css`
    ${color('black')}
  `,
};

const krefel = {};

const hifi = {};

const tones = {};

const brandSpecifics = {
  hifi,
  krefel,
  tones,
};

const genericSpecifics = {
  ...defaultValues,
  ...brandSelect(brandSpecifics),
};

export default genericSpecifics;
