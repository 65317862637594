/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable typescript-sort-keys/interface */
/* eslint-disable sort-keys-fix/sort-keys-fix */
import { SocialLoginType } from 'constants/oauth';
import { apiClient } from 'helpers/ApiClient';
import { getNewAccessToken, loadAuthData, logout, refreshAccessToken, setOAuthCookie } from 'helpers/OAuth2Helper';
import { Credentials, OAuthToken } from 'types/Auth';
import { User } from 'types/User';
import { afterLoginActions, afterLogoutActions, prefixUserCredentials } from 'utils/authUtil';
import { log } from 'utils/loggerUtil';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useUserStore } from './user';

interface AuthState {
  anonymous: boolean;
  authFlyoverVisible: boolean;
  authenticated: boolean;
  isFetchingCurrentUser: boolean;
  authLoaded: boolean;
  refreshing: boolean;
  token?: OAuthToken;
}

export interface AuthStore extends AuthState {
  actions: {
    setAuthFlyoverVisible: (visible: boolean) => void;
    setAuthenticated: (authenticated: boolean) => void;
    setFetchingCurrentUser: (isFetchingCurrentUser: boolean) => void;
    setAuthLoaded: (loaded: boolean) => void;
    setRefreshing: (refreshing: boolean) => void;
    setToken: (token: OAuthToken) => void;
    authenticate: (
      credentials: Credentials,
      socialLoginType: SocialLoginType,
    ) => Promise<{ anonymous: boolean; token: OAuthToken }>;
    authenticateAnonymous: () => Promise<{ anonymous: boolean; token: OAuthToken }>;
    fetchAuthenticatedUser: (isUserMinimal?: boolean) => Promise<User>;
    logout: () => Promise<void>;
    initAuth: () => Promise<{ anonymous: boolean; token: OAuthToken }>;
    login: (credentials: Credentials, socialLoginType?: SocialLoginType, showNotification?: boolean) => Promise<void>;
    refreshAuth: () => Promise<{ anonymous: boolean; token: OAuthToken }>;
  };
}

const initialState: AuthState = {
  anonymous: true,
  authFlyoverVisible: false,
  authenticated: false,
  isFetchingCurrentUser: false,
  authLoaded: false,
  refreshing: false,
  token: undefined,
};

export const useAuthStore = create<AuthStore>()(
  devtools(
    (set, get) => ({
      ...initialState,
      actions: {
        setAuthFlyoverVisible: (visible) => set(() => ({ authFlyoverVisible: visible })),
        setAuthenticated: (authenticated) => set(() => ({ authenticated })),
        setFetchingCurrentUser: (isFetchingCurrentUser) => set(() => ({ isFetchingCurrentUser })),
        setAuthLoaded: (loaded) => set(() => ({ authLoaded: loaded })),
        setRefreshing: (refreshing) => set(() => ({ refreshing })),
        setToken: (token) => set(() => ({ token })),
        authenticate: async (credentials, socialLoginType) => {
          try {
            const { anonymous, token } = await getNewAccessToken({
              credentials: prefixUserCredentials(credentials),
              socialLoginType,
            });
            setOAuthCookie(token);
            set(() => ({ anonymous, token, authenticated: true }));
            return { anonymous, token };
          } catch (error) {
            log('authenticate', 'Failed to authenticate user', error);
            set(() => ({ anonymous: true, token: undefined, authenticated: false }));
            throw error;
          }
        },
        authenticateAnonymous: async () => {
          try {
            const { anonymous, token } = await getNewAccessToken();
            setOAuthCookie(token);
            set(() => ({ anonymous, token, authenticated: true, user: null, authLoaded: true }));
            return { anonymous, token };
          } catch (error) {
            log('authenticateAnonymous', 'Failed to authenticate anonymous user', error);
            set(() => ({ anonymous: true, token: undefined, authenticated: false }));
            throw error;
          }
        },
        fetchAuthenticatedUser: async (isUserMinimal = false) => {
          try {
            set(() => ({ isFetchingCurrentUser: true }));
            const { data: user } = await apiClient<User>({
              url: '/users/current',
              params: {
                fields: isUserMinimal ? 'MINIMAL' : 'FULL',
              },
            });
            useUserStore.getState().actions.setUser(user);
            return user;
          } catch (error) {
            log('fetchAuthenticatedUser', 'Failed to fetch authenticated user', error);
            throw error;
          } finally {
            set(() => ({ isFetchingCurrentUser: false }));
          }
        },
        logout: async () => {
          try {
            await logout();
            set(() => ({ anonymous: true, token: undefined, authenticated: false, user: null }));
            afterLogoutActions();
            await get().actions.authenticateAnonymous();
          } catch (error) {
            log('logout', 'Failed to logout user', error);
            throw error;
          }
        },
        initAuth: async () => {
          try {
            const { anonymous, token } = loadAuthData();
            set(() => ({ anonymous, token, authenticated: !anonymous, authLoaded: true }));

            if (!anonymous) {
              await get().actions.fetchAuthenticatedUser();
            }
            return { anonymous, token };
          } catch (error) {
            log('initAuth', 'Failed to init auth', error);
            set(() => ({ token: undefined, authLoaded: false }));
            return await get().actions.authenticateAnonymous();
          }
        },
        login: async (credentials, socialLoginType?, showNotification = true) => {
          const prefixedCredentials = prefixUserCredentials(credentials);
          try {
            const { anonymous, token } = await getNewAccessToken({ credentials: prefixedCredentials, socialLoginType });
            setOAuthCookie(token);
            set(() => ({ anonymous, token, authenticated: !anonymous }));
            if (!anonymous) {
              const user = await get().actions.fetchAuthenticatedUser();
              afterLoginActions(user, showNotification);
            }
          } catch (error) {
            log('login', 'Failed to login user', error);
            set(() => ({ anonymous: true, token: undefined, authenticated: false }));
            throw error;
          }
        },
        refreshAuth: async () => {
          try {
            set(() => ({ refreshing: true }));
            const { anonymous, token } = await refreshAccessToken();
            setOAuthCookie(token);
            if (!anonymous) {
              set(() => ({ anonymous, token, authenticated: true }));
              const user = await get().actions.fetchAuthenticatedUser();
              afterLoginActions(user, false);
            } else {
              set(() => ({ anonymous, token, authenticated: false, user: null }));
            }
            return { anonymous, token };
          } catch (error) {
            log('refreshAuth', 'Failed to refresh auth', error);
            set(() => ({ anonymous: true, token: undefined, authenticated: false, user: null }));
            const { anonymous, token } = await get().actions.authenticateAnonymous();
            return { anonymous, token };
          } finally {
            set(() => ({ refreshing: false }));
          }
        },
      },
    }),
    { name: 'authStore' },
  ),
);

export const useToken = () => useAuthStore((state) => state.token);
export const useAnonymous = () => useAuthStore((state) => state.anonymous);
export const useAuthenticated = () => useAuthStore((state) => state.authenticated);
export const useAuthFlyoverVisible = () => useAuthStore((state) => state.authFlyoverVisible);
export const useAuthLoaded = () => useAuthStore((state) => state.authLoaded);
export const useIsFetchingCurrentUser = () => useAuthStore((state) => state.isFetchingCurrentUser);
export const useRefreshing = () => useAuthStore((state) => state.refreshing);
export const useAuthActions = () => useAuthStore((state) => state.actions);
