// @ts-nocheck - Redux will be removed in the future
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import Cookies from 'universal-cookie';
import hydrateServerClientState from '../helpers/HydrateHelper';
import createMiddleware from './middleware/clientMiddleware';
import combinedReducer from './reducer';

const bindMiddleware = (middleware) => {
  if (process.env.NEXT_PUBLIC_NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    return hydrateServerClientState(state, action.payload);
  }
  return combinedReducer(state, action);
};

export const makeStore = () => {
  const cookies = new Cookies();

  const middleware = [createMiddleware(), thunkMiddleware.withExtraArgument(cookies)];

  return createStore(reducer, bindMiddleware(middleware));
};

const wrapper = createWrapper(makeStore);

export default wrapper;
