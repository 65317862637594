import { isProductionEnv } from 'constants/environment';
import { ErrorObject } from 'types/Api';

export const log = (currentLocation?: string, message?: string, error?: unknown) => {
  if (!isProductionEnv || typeof window === 'undefined') {
    console.info(`[${currentLocation}] - ${message}`, error ?? '');
  }
};

export const logErrorResponse = (
  currentLocation: string,
  error: unknown,
  errorMessage: string,
  errorDataMessage?: string,
) => {
  log(currentLocation, errorMessage, error);

  const errorData = (error as ErrorObject)?.response?.data?.errors;
  if (errorData) {
    log(currentLocation, errorDataMessage || 'Error response: ', errorData?.[0]);
  }
};
