import { css } from 'styled-components';

const fontUrl = 'https://media.hifi.lu/static/fonts';

export const hifiFontVariables = {
  fontFamily: {
    primary: '"PT Sans", sans-serif;',
    secondary: '"Radikal", serif;',
  },
};

export default css`
  // PT Sans
  @font-face {
    font-display: swap;
    font-family: 'PT Sans';
    src: url(${fontUrl}/PT_Sans_Bold_Italic.woff2) format('woff2'),
      url(${fontUrl}/PT_Sans_Bold_Italic.woff) format('woff');
    font-style: italic;
    font-weight: 700;
  }

  @font-face {
    font-display: swap;
    font-family: 'PT Sans';
    src: url(${fontUrl}/PT_Sans_Bold.woff2) format('woff2'), url(${fontUrl}/PT_Sans_Bold.woff) format('woff');
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-display: swap;
    font-family: 'PT Sans';
    src: url(${fontUrl}/PT_Sans_Italic.woff2) format('woff2'), url(${fontUrl}/PT_Sans_Italic.woff) format('woff');
    font-style: italic;
    font-weight: 400;
  }

  @font-face {
    font-display: swap;
    font-family: 'PT Sans';
    src: url(${fontUrl}/PT_Sans_Regular.woff2) format('woff2'), url(${fontUrl}/PT_Sans_Regular.woff) format('woff');
    font-style: normal;
    font-weight: 400;
  }

  // Radikal
  @font-face {
    font-display: swap;
    font-family: 'Radikal';
    src: url(${fontUrl}/radikal_bold-webfont.woff2) format('woff2'),
      url(${fontUrl}/radikal_bold-webfont.woff) format('woff');
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-display: swap;
    font-family: 'Radikal';
    src: url(${fontUrl}/radikal_medium-webfont.woff2) format('woff2'),
      url(${fontUrl}/radikal_medium-webfont.woff) format('woff');
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-display: swap;
    font-family: 'Radikal';
    src: url(${fontUrl}/radikal-webfont.woff2) format('woff2'), url(${fontUrl}/radikal-webfont.woff) format('woff');
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-display: swap;
    font-family: 'Radikal';
    src: url(${fontUrl}/radikal_light-webfont.woff2) format('woff2'),
      url(${fontUrl}/radikal_light-webfont.woff) format('woff');
    font-style: normal;
    font-weight: 300;
  }
`;
