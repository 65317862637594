import { AGENTS } from './userAgent';

export const DAYS_HIDDEN = 30; // To keep the smartbanner after clicking close hidden for 1 month

export const IOS_APP_DATA = {
  appID: process.env.NEXT_PUBLIC_IOS_APP_ID,
  appMeta: AGENTS.IOS.meta,
  storeLink: process.env.NEXT_PUBLIC_APP_STORE_LINK,
  type: AGENTS.IOS.type,
};

export const ANDROID_APP_DATA = {
  storeLink: process.env.NEXT_PUBLIC_PLAY_STORE_LINK,
  type: AGENTS.ANDROID.type,
};

export const SMART_BANNER_HIDDEN_COOKIE = 'smartbanner-hidden';

export const APP_CONTENT_ID = 'appContent';

export const APP_CONTENT_SELECTOR = `#${APP_CONTENT_ID}`;
