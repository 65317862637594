import { apiClient } from 'helpers/ApiClient';
import { Feedback } from 'types/CustomerFeedback';
import { logErrorResponse } from 'utils/loggerUtil';

const submitCustomerFeedback = async (feedbackData: Feedback) => {
  try {
    await apiClient({
      data: feedbackData,
      method: 'post',
      url: 'feedback/',
    });
  } catch (error) {
    logErrorResponse('Customer Feedback Connector', error, `Error submitting customer feedback ${feedbackData}`);
    throw error;
  }
};

export { submitCustomerFeedback };
