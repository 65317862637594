import { css } from 'styled-components';
import { brandSelect, color, specific } from '../../functions';

const defaultValues = {
  'review-slider-header-color': css`
    ${color('whisper-300')};
  `,
  'review-reward-color': css`
    ${color('secondary-400')};
  `,
  'date-calculation-color': css`
    ${color('whisper-300')}
  `,
  'review-title-color': css`
    ${specific('body-color')};
  `,
  'review-numbers-color': css`
    ${color('primary-300')};
  `,
  'text-fade-out-gradient':
    'linear-gradient(to top,rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 80%)',
  'repeat-box-shadow': 'inset 0px 0px 14px rgba(10, 35, 101, 0.09)',
  'info-background-color': css`
    ${color('whisper-100')}
  `,
  'info-price-color': css`
    ${color('black')}
  `,
  'info-border-color': css`
    ${color('whisper-200')}
  `,
  'review-background-color': css`
    ${specific('product', 'info-background-color')}
  `,
  'review-border-color': css`
    ${color('whisper-200')}
  `,
  'grid-results-wrapper-margin': '128px',
  'rating-stars-fill-color': css`
    ${color('gold')}
  `,
};

const krefel = {
  'brand-color': css`
    ${color('primary-400')};
  `,
};

const hifi = {
  'brand-color': css`
    ${color('primary-300')};
  `,
};

const tones = {
  'review-reward-color': css`
    ${color('primary-300')};
  `,
  'review-title-color': css`
    ${color('secondary-400')};
  `,
  'review-numbers-color': css`
    ${color('secondary-400')};
  `,
  'brand-color': css`
    ${color('secondary-400')};
  `,
};

const brandSpecifics = {
  krefel,
  hifi,
  tones,
};

const buttonSpecifics = {
  ...defaultValues,
  ...brandSelect(brandSpecifics),
};

export default buttonSpecifics;
