// @ts-nocheck - Redux will be removed in the future
/**
 * Redux client API middleware for async dispatches.
 * If the action container an array of types AND a promise, it is treated like an api action.
 */

import { AxiosError } from 'axios';
import { log } from '../../utils/loggerUtil';

/**
 * Returns a function for creating the Api Client Middleware.
 * This middleware uses the provided types for dispatching an action for each state of
 * the provided promise.
 *
 * TYPE = dispatched at launch action.
 * TYPE_SUCCESS = dispatched when the promise resolves.
 * TYPE_FAIL = dispatched when promise fails.
 *
 * @returns {*} The client middleware.
 */
const clientMiddleware = () => () => (next) => (action) => {
  // if the dispatch is a thunk, continue
  if (typeof action === 'function') {
    return next(action);
  }

  const { promise, types, ...rest } = action;
  // if action contains promise and types properties --> create custom dispatches for each state
  if (promise && types) {
    const [REQUEST, SUCCESS, FAILURE] = types;

    const actionPromise = new Promise((resolve, reject) => {
      promise()
        .then((result) => {
          resolve(result);
          return next({ ...rest, result, type: SUCCESS });
        })
        .catch((errorResult: AxiosError) => {
          const wrappedError = {
            data: errorResult?.response?.data,
            headers: errorResult?.response?.headers,
            response: errorResult?.response,
            status: errorResult?.response?.status,
            url: errorResult?.config?.url,
          };
          log('clientMiddleware.ts', `error request:`, wrappedError);
          reject(wrappedError);
          return next({ ...rest, error: wrappedError, type: FAILURE });
        });
    });
    next({ ...rest, promise: actionPromise, type: REQUEST });
    return actionPromise;
  }
  return next(action);
};

export default clientMiddleware;
